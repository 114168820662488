import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Teacher } from "__types__";
import {
  TeacherInterventionColumns,
  TeacherFollowupColumns,
  TeacherTeachingsColumns,
} from "./data";
import Paper from "ui/Paper";
import { Box, Button, Grid, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "../Settings/Levels";
import { a11yProps } from "utils";
import CardInfo, { useStyles } from "./CardInfo";
import EditTeacherIntervention from "./EditTeacherIntervention";
import { interventionLabel } from "./Family";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = (props) => {
  const classes = useStyles();

  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const handleValue = React.useCallback(
    (e: React.ChangeEvent<{}>, newValue: number) => {
      setValue((prevValue) => newValue);
    },
    [value, setValue]
  );

  React.useEffect(() => {
    axios("/api/v1/teachers/code/" + props["code"]).then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <>
      <PageHeader back title="Enseignants">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          Ajouter une intervention
        </Button>
      </PageHeader>

      <Paper>
        <Tabs
          onChange={handleValue}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Interventions" {...a11yProps(1)} />
          <Tab label="Suivis" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box p={2}>
                <Grid item xs={12}>
                  <div className={classes.titleInfo}>
                    Information
                    <hr className={classes.hr} />
                  </div>
                </Grid>
                <div className={classes.info}>
                  <span className={classes.nameField}>Code :</span>
                  <span className={classes.contentField}>{data?.code}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Civilité :</span>
                  <span className={classes.contentField}>{data?.civility}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Nom :</span>
                  <span className={classes.contentField}>
                    {data?.firstname}
                  </span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Prénom :</span>
                  <span className={classes.contentField}>{data?.lastname}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Email :</span>
                  <span className={classes.contentField}>{data?.email}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Tel :</span>
                  <span className={classes.contentField}>
                    {data?.phonenumber}
                  </span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Ville :</span>
                  <span className={classes.contentField}>{data?.city}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Quartier :</span>
                  <span className={classes.contentField}>{data?.district}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>
                    Types d'intervention souhaités :
                  </span>
                  <span className={classes.contentField}>
                    {data?.interventionTypes}
                    {data?.intervention_types
                      ?.split(",")
                      ?.map((item: any) => interventionLabel[item])
                      .join(", ") || "N/A"}
                  </span>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={2}>
                <Grid item xs={12}>
                  <div className={classes.titleInfo}>
                    Enseignement
                    <hr className={classes.hr} />
                  </div>
                </Grid>
                <Table
                  hasToolBar={false}
                  rowsIdentifier="id"
                  columns={TeacherTeachingsColumns}
                  data={data?.teachings || []}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={2}>
                <Grid item xs={12}>
                  <div className={classes.titleInfo}>
                    CV
                    <hr className={classes.hr} />
                  </div>
                </Grid>
                <a href={data?.resume} target="_blank">
                  Voir le CV
                </a>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Table
            rowsIdentifier="id"
            columns={TeacherInterventionColumns}
            data={data?.interventions || []}
            onRowClick={(row) => {
              navigate("/soutien-scolaire/interventions/" + row.id);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Table
            rowsIdentifier="id"
            columns={TeacherFollowupColumns}
            data={data?.followups || []}
          />
        </TabPanel>
      </Paper>

      <EditTeacherIntervention
        teacher={data?.id}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Home;
