import React from "react";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { TextField } from "ui/Form/TextField";
import "styled-components/macro";

export interface TableToolbarProps {
  actions?: any;
  filters?: any;
  searchInputRef: any;
  handleSearchTerm(event: React.ChangeEvent<HTMLInputElement>): void;
}

const TableToolbar: React.FC<TableToolbarProps> = ({
  actions,
  filters,
  searchInputRef,
  handleSearchTerm,
}) => {
  return (
    <>
      <div
        css={`
          display: flex;
          align-items: center;
          border-radius: 4px;
          padding: 1rem 0;
          justify-content: space-between;
        `}
      >
        <div>{filters}</div>
        <div
          css={`
            display: flex;
            align-items: center;
            gap: 14px;
          `}
        >
          <TextField
            type="search"
            inputRef={searchInputRef}
            label="Rechercher"
            name="searchTable"
            onChange={handleSearchTerm}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <div>{actions}</div>
        </div>
      </div>
    </>
  );
};

export default TableToolbar;
