import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import {
  InputLabel, Grid, Button, CircularProgress, Paper
} from "@material-ui/core";

import { TextField } from 'ui/Form/TextField';

import ImageUploader from 'react-images-upload';

import { useStyles } from 'constants/styles';

import { Radio, RadioGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { Sheet } from "__types__";
import { CustomSelect } from "ui/Custom/Select";
import "styled-components/macro";
import axios from "axios";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { InfoBarContext } from "contexts/InfoBarContext";
import Autocomplete from "ui/Autocomplete";
import Editor from "pages/Editor";
import { PageHeader } from "ui/Header";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

interface IP {
  name?: string;
  email?: string;
  phonenumber?: string;
  address?: string;
  city?: string;
  website_link?: string;
  facebook_link?: string;
  video_link?: string;
  school_type?: string;
  school_status?: string;
  education_levels?: any;
  education_sectors?: any;
  education_type: any;
  presentation?: string;
  contact_option?: string;
  on_top_one?: string;
}

const defaultValues: IP = {
  name: '',
  phonenumber: '',
  email: "",
  address: '',
  city: '',
  website_link: '',
  facebook_link: '',
  video_link: '',
  school_type: 'elementary_primary',
  school_status: 'private',
  education_type: null,
  presentation: '',
  contact_option: "true",
  on_top_one: "false",
  education_levels: [],
  education_sectors: [],
}

interface EditSchoolProps extends RouteComponentProps {
  current?: any;
}
export const EditSchool: React.FC<EditSchoolProps> = ({ ...props }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [cities, setCities] = React.useState<Sheet[]>([]);
  const [sectors, setSectors] = React.useState<Sheet[]>([]);
  const [isFormSubmited, setFormSubmited] = React.useState(false);

  const [picture, setPicture] = React.useState(null);

  const [currentEducationTypes, setCurrentEducationTypes] = React.useState([]);
  const [educationTypes, setEducationTypes] = React.useState([]);
  const [educationLevels, setEducationLevels] = React.useState([]);

  const { setInfo } = React.useContext(InfoBarContext);

  const [loading, setLoading] = React.useState(false);

  const [current, setCurrent] = React.useState(null);


  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    if (formValues.school_type === "secondary" || formValues.school_type === "high") {
      setCurrentEducationTypes(educationTypes.filter(type => type.level === formValues.school_type))
    }
  }, [formValues.school_type]);

  React.useEffect(() => {
    fetch("/api/v1/studies/cities")
      .then((res) => res.json())
      .then((data) => {
        setCities(data);
      })
      .catch(console.log);

    fetch('/api/v1/sheets/sectors')
      .then(res => res.json())
      .then((data) => {
        setSectors(data)
      })
      .catch(console.log);

    axios('/api/v1/settings/education-type')
      .then(res => {
        setEducationTypes(res.data)
      });

    axios('/api/v1/settings/school-level')
      .then(res => {
        setEducationLevels(res.data)
      });
  }, []);

  React.useEffect(() => {
    if (props.location?.state && props.location?.state["current"]) {
      const currentState = props.location?.state["current"];
      let l: any[] = [];
      let t: any[] = [];
      if (currentState.education_levels.length > 0) {
        const levels = currentState.education_levels.split(', ')
        l = educationLevels.filter(level => levels.includes(level.name))

      }

      if (currentState.education_sectors.length > 0) {
        const levels = currentState.education_sectors.split(', ')
        t = sectors.filter(level => levels.includes(level.name))
      }

      setFormValues({
        ...formValues,
        name: currentState.name,
        email: currentState.email,
        phonenumber: currentState.phonenumber,
        address: currentState.address,
        city: currentState.city,
        website_link: currentState.website_link,
        facebook_link: currentState.facebook_link,
        video_link: currentState.video_link,
        school_type: currentState.school_type,
        school_status: currentState.school_status,
        presentation: currentState.presentation,
        contact_option: currentState.contact_option.toString(),
        on_top_one: currentState.on_top_one.toString(),
        education_type: currentState.education_type,
        education_sectors: t,
        education_levels: l
      });

      setCurrent(currentState);
    }
  }, [props.location?.state]);

  const handleSave = () => {
    setFormSubmited(true);

    if (formValues.name.length === 0) return;

    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('email', formValues.email);
    formData.append('phonenumber', formValues.phonenumber);
    formData.append('address', formValues.address);
    formData.append('city', formValues.city);
    formData.append('website_link', formValues.website_link);
    formData.append('facebook_link', formValues.facebook_link);
    formData.append('video_link', formValues.video_link);
    formData.append('school_type', formValues.school_type);
    formData.append('school_status', formValues.school_status);
    formData.append('education_type', formValues.education_type?.name);
    formData.append('education_levels', JSON.stringify(formValues.education_levels));
    formData.append('education_sectors', JSON.stringify(formValues.education_sectors));
    formData.append('presentation', formValues.presentation);
    formData.append('logo_image', picture);
    formData.append('contact_option', formValues.contact_option);
    formData.append('on_top_one', formValues.on_top_one);


    if (current) {
      const requestOptions = {
        method: 'PUT',
        body: formData
      };

      fetch('/api/v1/studies/' + current.id, requestOptions)
        .then(res => res.json())
        .then((res) => {
          setFormSubmited(false);
          setInfo({
            type: "success",
            message: "Ecole modifier avec succès"
          })
          navigate("/etudes/ecoles", { replace: true });
        })
        .catch(error => {
          setInfo({
            type: "error",
            message: "Erreur"
          });
        });
    } else {

      const requestOptions = {
        method: 'POST',
        body: formData
      };

      fetch('/api/v1/studies', requestOptions)

        .then(res => res.json())
        .then((res) => {
          setFormSubmited(false);
          setInfo({
            type: "success",
            message: "Ecole ajouter avec succès!"
          })
          navigate("/etudes/ecoles", { replace: true });
        })
        .catch(error => {
          setInfo({
            type: "error",
            message: "Erreur"
          });
        });
    }
  };

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  return (
    <>
      <PageHeader back title={`${Boolean(current) ? "Modifier" : "Ajouter"}`}>
        <Button style={{ width: "168px" }} color="primary" variant="contained" onClick={handleSave} disabled={loading}>
          {loading ? <CircularProgress size={30} /> : (Boolean(current) ? "Modifier" : "Ajouter")}
        </Button>
      </PageHeader>

      <Paper style={{ padding: "30px" }}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <TextField
              label="Nom"
              name="name"
              value={formValues.name}
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              error={isFormSubmited && formValues.name.length === 0}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && formValues.name.length === 0
                  ? "Veuillez saisir le nom"
                  : ""
              }
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              name="email"
              value={formValues.email}
              margin="dense"
              type="text"
              fullWidth
              onChange={handleInputChange}
              error={isFormSubmited && formValues.email.length === 0}
              helperText={
                isFormSubmited && formValues.email.length === 0
                  ? "Veuillez saisir l'email"
                  : ""
              }
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Téléphone"
              name="phonenumber"
              value={formValues.phonenumber}
              margin="dense"
              type="text"
              fullWidth
              onChange={handleInputChange}
              error={isFormSubmited && formValues.phonenumber.length === 0}
              helperText={
                isFormSubmited && formValues.phonenumber.length === 0
                  ? "Veuillez saisir le téléphone"
                  : ""
              }
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Adresse"
              name="address"
              value={formValues.address}
              margin="dense"
              type="text"
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSelect
              css={`
                width: 100%;
              `}
              label="Ville"
              name="city"
              onChange={handleInputChange}
              required
              value={formValues.city}
            >
              {cities.map((s, k) => (
                <option key={k} value={s.name}>
                  {s.name}
                </option>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Lien site web"
              name="website_link"
              value={formValues.website_link}
              margin="dense"
              type="text"
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Lien facebook"
              name="facebook_link"
              value={formValues.facebook_link}
              margin="dense"
              type="text"
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Lien viedo de presentation"
              name="video_link"
              value={formValues.video_link}
              margin="dense"
              type="text"
              fullWidth
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel
              className={classes.label}
            >
              Type d'école
            </InputLabel>
            <RadioGroup defaultValue="elementary_primary" name="school_type" value={formValues.school_type} onChange={handleInputChange} row>
              <FormControlLabel value="elementary_primary" control={<Radio />} label="Elementaire" />
              <FormControlLabel value="secondary" control={<Radio />} label="Secondaire" />
              <FormControlLabel value="high" control={<Radio />} label="Supérieur" />
            </RadioGroup>
          </Grid>

          {
            (formValues.school_type === 'secondary' || formValues.school_type === 'elementary_primary') &&
            <Grid item xs={6}>
              <InputLabel
                className={classes.label}
              >
                Statut
              </InputLabel>
              <RadioGroup defaultValue="" name="school_status" value={formValues.school_status} onChange={handleInputChange} row>
                <FormControlLabel value="private" control={<Radio />} label="Privé" />
                <FormControlLabel value="public" control={<Radio />} label="Public" />
              </RadioGroup>
            </Grid>
          }

          {
            (formValues.school_type === 'secondary' || formValues.school_type === 'high') &&
            <Grid item xs={6}>
              <Autocomplete
                required
                label="Type d'enseignement"
                options={currentEducationTypes}
                getOptionLabel={(option: any) => option.name}
                onChange={(event: any, newValue: any) => {
                  setFormValues({
                    ...formValues,
                    education_type: newValue,
                  });
                }}
              // error={isFormSubmited && !Boolean(formValues.level)}
              // helperText={isFormSubmited && !Boolean(formValues.level) && "Veuillez selectionner le niveau"}
              // value={formValues.education_type}
              // getOptionSelected={(option: any, val: any) => option.id === val.id}
              />
            </Grid>
          }

          {formValues.school_type === 'high' &&
            <Grid item xs={12}>
              <MuiAutocomplete
                multiple
                options={educationLevels}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                onChange={(event: any, newValue: any) => {
                  console.log(newValue)
                  setFormValues({
                    ...formValues,
                    education_levels: newValue,
                  });
                }}
                value={formValues.education_levels}
                renderInput={(params) => (
                  <TextField {...params}
                    required
                    variant="outlined"
                    label="Niveau d'études"
                  // error={isFormSubmited && !Boolean(formValues.courses.length)}
                  // helperText={isFormSubmited && !Boolean(formValues.courses.length) && "Veuillez selectionner les matieres"}
                  />
                )}
              />
            </Grid>
          }


          {formValues.school_type === 'high' &&
            <Grid item xs={12}>
              <MuiAutocomplete
                multiple
                options={sectors}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                onChange={(event: any, newValue: any) => {
                  setFormValues({
                    ...formValues,
                    education_sectors: newValue,
                  });
                }}
                value={formValues.education_sectors}
                renderInput={(params) => (
                  <TextField {...params}
                    required
                    variant="outlined"
                    label="Filiere(s)"
                  // error={isFormSubmited && !Boolean(formValues.courses.length)}
                  // helperText={isFormSubmited && !Boolean(formValues.courses.length) && "Veuillez selectionner les matieres"}
                  />
                )}
              />
            </Grid>
          }

          <Grid item xs={12}>
            <InputLabel
              className={classes.label}
            >
              Présentation
            </InputLabel>
            <br />
            <Editor
              value={formValues.presentation}
              onChange={(content) => {
                setFormValues({
                  ...formValues,
                  presentation: content
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ImageUploader
              singleImage
              withIcon={true}
              withPreview={true}
              buttonText="Logo de l'école "
              onChange={onDrop}
              imgExtension={['.jpg', '.gif', '.png', '.gif']}
              maxFileSize={5242880}
            />
          </Grid>

          <Grid item xs={6}>
            <InputLabel
              className={classes.label}
            >
              Option contact
            </InputLabel>
            <RadioGroup defaultValue="" name="contact_option" value={formValues.contact_option} onChange={handleInputChange} row>
              <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
              <FormControlLabel value={"false"} control={<Radio />} label="Non" />
            </RadioGroup>
          </Grid>

          <Grid item xs={6}>
            <InputLabel
              className={classes.label}
            >
              Option Top 1
            </InputLabel>
            <RadioGroup defaultValue="" name="on_top_one" value={formValues.on_top_one} onChange={handleInputChange} row>
              <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
              <FormControlLabel value={"false"} control={<Radio />} label="Non" />
            </RadioGroup>
          </Grid>

        </Grid>
      </Paper>
    </>
  );
};
export default EditSchool;