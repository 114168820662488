import React from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  InputLabel, Paper, Radio, RadioGroup,
} from "@material-ui/core";
import { TextField, TextFieldGroup } from 'ui/Form/TextField';

import ImageUploader from 'react-images-upload';

import { useStyles } from 'constants/styles';
import { InfoBarContext } from "contexts/InfoBarContext";
import axios from "axios";
import { compressImage, formatDate } from "utils";
import { CustomSelect } from "ui/Custom";
import "styled-components/macro";
import Editor from "./Editor";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";

interface IForm {
  name?: string;
  description?: string;
  presentation?: string;
  expiration_date?: string;
  category_id?: string;
  ontop?: string;
  ontop_start_date?: string;
  ontop_end_date?: string;

  onhome_page?: string;
  onhome_start_date?: string;
  onhome_end_date?: string;

}

const defaultValues: IForm = {
  name: '',
  description: '',
  presentation: '',
  expiration_date: formatDate(new Date(), "yyyy-MM-dd"),
  onhome_page: "false",
  onhome_start_date: null,
  onhome_end_date: null,
  ontop: "false",
  ontop_start_date: null,
  ontop_end_date: null,
  category_id: null
}

interface EditArticleProps extends RouteComponentProps {
  open?: boolean;
  hasCategory?: boolean;
  onClose?: () => void;
  current?: any;
  section: any;
  noImage?: boolean;
  noDate?: boolean;


}
const EditArticle: React.FC<EditArticleProps> = ({ section, hasCategory = false, noImage = false, noDate = false, ...props }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [current, setCurrent] = React.useState(null);


  const [picture, setPicture] = React.useState(null);

  const [categories, setCategories] = React.useState([]);

  const { setInfo } = React.useContext(InfoBarContext);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    if (hasCategory) {
      fetch('/api/v1/articles/categories/orientation')
        .then(res => res.json())
        .then((data) => {
          setCategories(data)
        })
        .catch(console.log);
    }
  }, [])

  React.useEffect(() => {
    if (props.location?.state && props.location.state["current"]) {
      const _current = props.location.state["current"];
      setFormValues({
        name: _current.name,
        description: _current.description,
        presentation: _current.presentation,
        expiration_date: _current.expiration_date,
        onhome_page: _current.onhome_page.toString(),
        onhome_start_date: _current.onhome_start_date,
        onhome_end_date: _current.onhome_end_date,
        ontop: _current.ontop.toString(),
        ontop_start_date: _current.ontop_start_date,
        ontop_end_date: _current.ontop_end_date,
        category_id: _current?.category?.id
      });
      setCurrent(_current);
    } else {
      setFormValues(defaultValues);
    }
  }, [props.location?.state]);

  const handleSave = async () => {
    setFormSubmited(true);
    if (formValues.name.trim().length === 0) return;

    setLoading(true);

    const formData = new FormData();

    formData.append("data", JSON.stringify(formValues));

    if (picture) {
      formData.append('cover_image', await compressImage(picture));
    }

    if (current) {
      axios.put('/api/v1/articles/' + current.id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          setFormSubmited(false);
          setInfo({
            type: "success",
            message: "Élément modifier avec succès"
          });
          navigate(-1);
        });
    } else {
      axios.post('/api/v1/articles/' + section,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          setFormSubmited(false);
          setInfo({
            type: "success",
            message: "Élément ajouter avec succès"
          });
          navigate(-1);
        });
    }
  };

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  return (
    <>
      <PageHeader back title={`${Boolean(current) ? "Modifier" : "Ajouter"}`}>
        <Button style={{ width: "168px" }} color="primary" variant="contained" onClick={handleSave} disabled={loading}>
          {loading ? <CircularProgress size={30} /> : (Boolean(current) ? "Modifier" : "Ajouter")}
        </Button>
      </PageHeader>
      <Paper style={{padding:"30px"}}>
        <TextFieldGroup>
          <TextField
            label="Titre"
            name="name"
            value={formValues.name}
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            error={isFormSubmited && formValues.name.length === 0}
            onChange={handleInputChange}
            helperText={
              isFormSubmited && formValues.name.length === 0
                ? "Veuillez saisir le nom de l'article"
                : ""
            }
            required

          />
        </TextFieldGroup>

        {
          hasCategory &&
          <TextFieldGroup>
            <CustomSelect
              css={`
              width: 100%;
              `}
              label="Categorie"
              name="category_id"
              onChange={handleInputChange}
              required
              value={formValues.category_id}
            >
              {categories.map((s, k) => (<option key={k} value={s.id}>{s.name}</option>))}
            </CustomSelect>
          </TextFieldGroup>
        }


        <TextFieldGroup>
          <InputLabel
            className={classes.label}
          >
            Description
        </InputLabel>
          <br />
          <textarea name="description" value={formValues.description} style={{ width: "100%" }} cols={30} rows={5}
            onChange={handleInputChange}
          >
          </textarea>
        </TextFieldGroup>

        <InputLabel
          className={classes.label}
        >
          Présentation
      </InputLabel>
        <br />
        <Editor
          value={formValues.presentation}
          onChange={(content) => {
            setFormValues({
              ...formValues,
              presentation: content
            });
          }}
        />

        {
          !noImage &&
          <ImageUploader
            singleImage
            withIcon={true}
            withPreview={true}
            buttonText='Image de couverture'
            onChange={onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
          />
        }

        {
          !noDate &&
          <>
            <TextFieldGroup>
              <TextField
                label="Date d'expiration"
                name="expiration_date"
                value={formValues.expiration_date}
                margin="dense"
                type="date"
                fullWidth
                required
                onChange={handleInputChange}
              />
            </TextFieldGroup>

            <div>
              <InputLabel
                className={classes.label}
              >
                Mettre sur la page d'accueil
          </InputLabel>
              <RadioGroup defaultValue="" name="onhome_page" value={formValues.onhome_page} onChange={handleInputChange} row>

                <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
                <FormControlLabel value={"false"} control={<Radio />} label="Non" />
              </RadioGroup>

              {
                formValues.onhome_page === "true" &&
                <>
                  <TextFieldGroup>
                    <TextField
                      label="Date de debut"
                      name="onhome_start_date"
                      value={formValues.onhome_start_date}
                      margin="dense"
                      type="date"
                      fullWidth
                      error={isFormSubmited && !Boolean(formValues.onhome_start_date)}
                      onChange={handleInputChange}
                      helperText={
                        isFormSubmited && !Boolean(formValues.onhome_start_date)
                          ? "Veuillez saisir la date de debut"
                          : ""
                      }
                      required

                    />
                  </TextFieldGroup>

                  <TextFieldGroup>
                    <TextField
                      label="Date de fin"
                      name="onhome_end_date"
                      value={formValues.onhome_end_date}
                      margin="dense"
                      type="date"
                      fullWidth
                      error={isFormSubmited && !Boolean(formValues.onhome_end_date)}
                      onChange={handleInputChange}
                      helperText={
                        isFormSubmited && !Boolean(formValues.onhome_end_date)
                          ? "Veuillez saisir la date de fin"
                          : ""
                      }
                      required

                    />
                  </TextFieldGroup>
                </>
              }

            </div>

            <div>
              <InputLabel
                className={classes.label}
              >
                Mettre à la une
          </InputLabel>
              <RadioGroup defaultValue="" name="ontop" value={formValues.ontop} onChange={handleInputChange} row>

                <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
                <FormControlLabel value={"false"} control={<Radio />} label="Non" />
              </RadioGroup>

              {
                formValues.ontop === "true" &&
                <>
                  <TextFieldGroup>
                    <TextField
                      label="Date de debut"
                      name="ontop_start_date"
                      value={formValues.ontop_start_date}
                      margin="dense"
                      type="date"
                      fullWidth
                      error={isFormSubmited && !Boolean(formValues.ontop_start_date)}
                      onChange={handleInputChange}
                      helperText={
                        isFormSubmited && !Boolean(formValues.ontop_start_date)
                          ? "Veuillez saisir la date de debut"
                          : ""
                      }
                      required

                    />
                  </TextFieldGroup>

                  <TextFieldGroup>
                    <TextField
                      label="Date de fin"
                      name="ontop_end_date"
                      value={formValues.ontop_end_date}
                      margin="dense"
                      type="date"
                      fullWidth
                      error={isFormSubmited && !Boolean(formValues.ontop_end_date)}
                      onChange={handleInputChange}
                      helperText={
                        isFormSubmited && !Boolean(formValues.ontop_end_date)
                          ? "Veuillez saisir la date de fin"
                          : ""
                      }
                      required

                    />
                  </TextFieldGroup>
                </>
              }
            </div>
          </>
        }
      </Paper>
    </>
  );
};
export default EditArticle;