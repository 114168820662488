import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { MainLayout } from "ui/Layout/Main";

import { Studies } from "./Studies";

import Messages from "./Messages";
import Presentation from "./Presentation";
import Setting from "./Setting";
import School from "./School";
import News from "./News";
import EditArticle from "pages/EditArticle";
import EditSchool from "./Edit/EditSchool";
import Ratings from "./Ratings";

const StudiesPage: React.FC<RouteComponentProps> = () => {

  return (
    <MainLayout>
      <Router>
        <Studies path="/ecoles" noPageTitle />
        <EditSchool path="/ecoles/ajouter" />
        <EditSchool path="/ecoles/editer" />
        <School path="/ecoles/:id" noPageTitle />
        <Messages path="/informations" noPageTitle />
        <Ratings path="/avis" noPageTitle />
        <News path="/ne-pas-manquer" noPageTitle />
        <Presentation path="/presentation" noPageTitle />
        <Setting path="/parametrages" noPageTitle />
        <EditArticle path="/ne-pas-manquer/ajouter" section="studies_news" />
        <EditArticle path="/ne-pas-manquer/editer" section="studies_news" />
      </Router>
    </MainLayout>
  );
};

export default StudiesPage;