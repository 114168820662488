import styled from "styled-components";
import { ButtonLink } from "../Button/ButtonLink";

export const NavLinkNested = styled(ButtonLink)`
  justify-content: space-between;
  width: 100%;
  font-size: 0.9rem;
  padding-left: 16px;
  padding-right: 10px;
  letter-spacing: 0;
  height: 42px;

  > .MuiButton-label {
    > span:first-child {
      white-space: initial;
      margin-right: 0.2rem;
    }
  }
`;
