import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Level, Course } from '__types__';
import { LevelColumns, CourseColumns, ClasseColumns } from '../Tutoring/data';
import { Box, Button, Tab, Tabs } from "@material-ui/core";
import EditLevel from "./Edit/Level";
import EditClasse from "./Edit/Classe";
import EditCourse from "./Edit/Course";
import Paper from "ui/Paper";
import { a11yProps } from "utils";
import Can from "shared/@pbac/Can";
import Deletion from "pages/Deletion";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}



export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = () => {
  const [value, setValue] = React.useState(0);
  const [levels, setLevels] = React.useState<Level[]>([]);
  const [courses, setCourses] = React.useState<Course[]>([]);
  const [classes, setClasses] = React.useState<Course[]>([]);
  const [openLevel, setOpenLevel] = React.useState(false);
  const [openClasse, setOpenClasse] = React.useState(false);
  const [openCourse, setOpenCourse] = React.useState(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const [path, setPath] = React.useState("")


  const handleValue = React.useCallback(
    (e: React.ChangeEvent<{}>, newValue: number) => {
      setValue((prevValue) => newValue);
    },
    [value, setValue]
  );

  React.useEffect(() => {
    axios('/api/v1/tutoring/levels')
      .then(res => {
        setLevels(res.data)
      });

    axios('/api/v1/tutoring/courses')
      .then(res => {
        setCourses(res.data)
      })

    axios('/api/v1/tutoring/classes')
      .then(res => {
        setClasses(res.data)
      })
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setCurrent(null);
  };

  return (
    <>
      <PageHeader title="Niveau | Classe | Matière">
      </PageHeader>

      <Paper>
        <Tabs
          onChange={handleValue}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={`Niveau (${levels.length})`} {...a11yProps(0)} />
          <Tab label={`Classe (${classes.length})`} {...a11yProps(1)} />
          <Tab label={`Matière (${courses.length})`} {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Table
            actions={
              <Can abilities={["ADMIN", "MANAGER"]}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenLevel(true)
                  }}
                  style={{ marginLeft: 15 }}
                >
                  Ajouter
                </Button>
              </Can>
            }
            actionAbilities={["ADMIN", "MANAGER"]}
            rowsIdentifier="id"
            columns={LevelColumns}
            data={levels}
            onRowDelete={(row) => {
              setPath("/api/v1/tutoring/levels/");
              setCurrent(row)
              setOpenDelete(true);
            }}
            onRowEdit={(row) => {
              setCurrent(row);
              setOpenLevel(true);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Table
            actions={
              <Can abilities={["ADMIN", "MANAGER"]}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenClasse(true)
                  }}
                  style={{ marginLeft: 15 }}
                >
                  Ajouter
              </Button>
              </Can>

            }
            actionAbilities={["ADMIN", "MANAGER"]}
            rowsIdentifier="id"
            columns={ClasseColumns}
            data={classes}
            onRowDelete={(row) => {
              setPath("/api/v1/tutoring/classes/");
              setCurrent(row)
              setOpenDelete(true);
            }}
            onRowEdit={(row) => {
              setCurrent(row);
              setOpenClasse(true);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Table
            actions={
              <Can abilities={["ADMIN", "MANAGER"]}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenCourse(true)
                  }}
                  style={{ marginLeft: 15 }}
                >
                  Ajouter
              </Button>
              </Can>

            }
            actionAbilities={["ADMIN", "MANAGER"]}
            rowsIdentifier="id"
            columns={CourseColumns}
            data={courses}
            onRowDelete={(row) => {
              setPath("/api/v1/tutoring/courses/");
              setCurrent(row)
              setOpenDelete(true);
            }}
            onRowEdit={(row) => {
              setCurrent(row);
              setOpenCourse(true);
            }}
          />
        </TabPanel>
      </Paper>


      <EditLevel
        open={openLevel}
        onClose={() => {
          setOpenLevel(false);
          setCurrent(null);
        }}
        current={current}
      />
      <EditClasse
        open={openClasse}
        onClose={() => {
          setOpenClasse(false)
          setCurrent(null);
        }}
        current={current}
      />

      <EditCourse
        open={openCourse}
        onClose={() => {
          setOpenCourse(false);
          setCurrent(null);
        }
        }
        current={current}
      />

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path={path}
      />
    </>
  );
};

export default Home;
