import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Request } from "__types__";
import {
  FindTeacherColumns,
  RequestColumns,
  RequestItemColumns,
  ROLE_COLOR,
} from "./data";
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useStyles } from "constants/styles";
import Paper from "ui/Paper";
import CloseIcon from "@material-ui/icons/Close";
import StatusInfo from "ui/StatusInfo";
import { InfoBarContext } from "contexts/InfoBarContext";
import Can from "shared/@pbac/Can";
import Deletion from "pages/Deletion";
import { interventionLabel } from "./Family";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = () => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const [data, setData] = React.useState<Request[]>([]);
  const [teachers, setTeachers] = React.useState<[]>([]);
  const [search, setSearch] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const { setInfo } = React.useContext(InfoBarContext);
  const [openDelete, setOpenDelete] = React.useState(false);

  React.useEffect(() => {
    axios("/api/v1/tutoring/request").then((res) => {
      setData(res.data);
    });
  }, []);

  function closeDrawer() {
    setIsDrawerOpen(false);
    setSearch(false);
    setTeachers([]);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const validate = () => {
    axios
      .put("/api/v1/tutoring/request/validate", {
        id: current.id,
      })
      .then(async (res) => {
        setInfo({
          type: "success",
          message: "Demannde validé avec succès!",
        });
        window.location.reload();
      });
  };

  const findTeacher = () => {
    const intrvs = JSON.parse(current.interventions);
    let levels = [];
    let courses = [];
    if (intrvs) {
      for (let intrv of intrvs) {
        levels.push(intrv["level"]);
        courses.push(intrv["course"]);
      }
    }

    const params =
      "?levels=" +
      JSON.stringify(levels) +
      "&courses=" +
      JSON.stringify(courses) +
      "&city=" +
      current.city;
    axios
      .get("/api/v1/tutoring/request/find-teachers/" + params)
      .then(async (res) => {
        setSearch(true);
        setTeachers(res.data);
      });
  };

  return (
    <>
      <PageHeader title={`Demande d'intervention (${data.length})`} />
      <Paper>
        <Table
          rowsIdentifier="id"
          columns={RequestColumns}
          data={data}
          onRowClick={(row) => {
            setCurrent(row);
            setIsDrawerOpen(true);
          }}
          onRowDelete={(row) => {
            setCurrent(row);
            setOpenDelete(true);
          }}
        />
      </Paper>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/tutoring/request/"
      />

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        {current && (
          <List dense>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="h3">
                    <b style={{ marginRight: 10 }}>
                      {current?.requester_firstname}{" "}
                      {current?.requester_lastname}
                    </b>
                    <StatusInfo
                      width="90px"
                      background={ROLE_COLOR[current.status]["bg"]}
                      status={ROLE_COLOR[current.status]["text"]}
                    />
                    {/* <InlinedChip
                    label={current?.createdAt && formatDate(current?.createdAt, "LLL dd, yyyy · HH:mm")}
                    backgroundColor={COLORS['blueFocus']}
                    size="small"
                  /> */}
                  </Typography>
                }
              />

              {current.status === "TO_VALIDATE" && (
                <Can abilities={["ADMIN", "MANAGER"]}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={validate}
                  >
                    Validé
                  </Button>
                </Can>
              )}

              <Button color="primary" variant="contained" onClick={findTeacher}>
                Trouver un enseignant
              </Button>
            </ListItem>

            <br />
            <br />

            <ListItem>
              <Typography variant="h5">
                <b style={{ marginRight: 10 }}>Informations du demandeur</b>
              </Typography>
            </ListItem>

            <ListItem>
              <span>
                Civilité : <b>{current?.requester_civility}</b>
              </span>
            </ListItem>

            <ListItem>
              <span>
                Nom : <b>{current?.requester_firstname}</b>
              </span>
            </ListItem>

            <ListItem>
              <span>
                Prénom : <b>{current?.requester_lastname}</b>
              </span>
            </ListItem>

            <ListItem>
              <span>
                Email : <b>{current?.requester_email}</b>
              </span>
            </ListItem>

            <ListItem>
              <span>
                Télephone : <b>{current?.requester_phonenumber}</b>
              </span>
            </ListItem>

            <Divider />

            <br />

            {search && (
              <div style={{ backgroundColor: "rgba(128,128,128,0.1)" }}>
                <ListItem>
                  <h4>Enseignants trouver</h4>
                </ListItem>

                <ListItem>
                  <Table
                    hasToolBar={false}
                    rowsIdentifier="id"
                    columns={FindTeacherColumns}
                    data={teachers}
                    onRowClick={(row) => {
                      navigate("/soutien-scolaire/enseignants/" + row.code);
                    }}
                  />
                </ListItem>
                <br />

                {teachers.length === 0 && (
                  <h3 style={{ textAlign: "center" }}>
                    Aucun enseignant trouver pour cette demande
                  </h3>
                )}
                <br />
              </div>
            )}

            <ListItem>
              <Typography variant="h5">
                <b style={{ marginRight: 10 }}>Lieu de la demande</b>
              </Typography>
            </ListItem>

            <ListItem>
              <span>
                Ville : <b>{current?.city}</b>
              </span>
            </ListItem>
            <ListItem>
              <span>
                Quartier : <b>{current?.district}</b>
              </span>
            </ListItem>
            <ListItem>
              <span>
                Date d'intervention : <b>{current?.intervention_period}</b>
              </span>
            </ListItem>
            <ListItem>
              <span>
                Frequence d'intervention :{" "}
                <b>{current?.intervention_frequency}</b>
              </span>
            </ListItem>

            <ListItem>
              <span>
                Types d'intervention souhaités :{" "}
                <b>
                  {current?.intervention_types
                    ?.split(",")
                    ?.map((item: any) => interventionLabel[item])
                    .join(", ") || "N/A"}
                </b>
              </span>
            </ListItem>

            <br />
            <Divider />
            <br />

            <ListItem>
              <Typography variant="h5">
                <b style={{ marginRight: 10 }}>Détails de la Demande</b>
              </Typography>
            </ListItem>

            <ListItem>
              <Table
                hasToolBar={false}
                rowsIdentifier="id"
                columns={RequestItemColumns}
                data={JSON.parse(current.interventions)}
              />
            </ListItem>

            <Divider />
          </List>
        )}
      </Drawer>
    </>
  );
};

export default Home;
