import styled, { css } from "styled-components/macro";
import { IconButton, Menu, MenuItem, MenuItemProps } from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import { APP_COLORS } from "constants/styles";

export const HeaderWrapperStyled = styled("div")`
  flex-grow: 1;
`;

export const CustomIconButton = styled(IconButton)`
  margin-left: -10px;
  margin-right: 10px;
  font-family: inherit;

  > span {
    flex-direction: column;

    .helper-text {
      font-size: 12px;
      letter-spacing: 0.023em;
      text-transform: uppercase;
    }
  }
`;

export const CustomAccountCircle = styled(AccountCircle)`
  color: #fff;
`;

export const CustomHeaderMenu = styled(Menu)`
  margin-top: 4rem;
`;

export const NotificationMenu = styled(Menu)`
  margin-top: 4rem;
`;

interface NotificationItemProps extends MenuItemProps {
  $isUnreadReadByViewer?: boolean;
}

export const NotificationItem = styled(MenuItem) <NotificationItemProps>`
  border-bottom: 1px solid #dddfe2;
  flex-direction: column;
  align-items: start;

  ${(p) =>
    p.$isUnreadReadByViewer &&
    css`
      border-left: 3px solid ${APP_COLORS.primary};
      background-color: aliceblue;
    `}

  &:first-child {
    padding-top: 12px;
  }

  > a {
    text-decoration: none;
    width: 100%;
    color: inherit;
    font-weight: 400;
  }
`;

export const NotificationItemLink = styled(NotificationItem)`
  text-align: center;
  padding: 0;
  color: ${APP_COLORS.primary};

  a {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;
