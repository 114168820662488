import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import {
  List, ListItem, ListItemText, Divider,
  DialogContent, Typography, InputLabel,
  Drawer
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import { InlinedChip } from "ui/Custom";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from 'ui/Form/TextField';

import { Sheet } from '__types__';

import ImageUploader from 'react-images-upload';

import { compressImage, formatDate } from "utils";

import { COLORS, useStyles } from 'constants/styles';
import { SectorDataRowsColumns } from './data';
import Paper from "ui/Paper";
import "styled-components/macro";
import { InfoBarContext } from "contexts/InfoBarContext";
import Can from "shared/@pbac/Can";
import axios from "axios";
import ActionsGroup from "ui/ActionsGroup";
import Editor from "pages/Editor";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const defaultValues = {
  name: '',
  description: '',
  presentation: ''
}

const actions = [
  { key: "add", label: "Ajouter", abilities: [] },
  { key: "validate", label: "Validé", abilities: ["ADMIN", "MANAGER"] },
];

export const Sectors: React.FC<DefaultPageProps> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [sectors, setSectors] = React.useState<Sheet[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [picture, setPicture] = React.useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const { setInfo } = React.useContext(InfoBarContext);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    fetch('/api/v1/sheets/sectors')
      .then(res => res.json())
      .then((data) => {
        setSectors(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      })
  }, []);

  const handleClickOpen = () => {
    setCurrent(null)
    setFormValues(defaultValues)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSave = async () => {
    setFormSubmited(true);

    if (formValues.name.length === 0) return;

    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('description', formValues.description);
    formData.append('presentation', formValues.presentation);

    if (picture) {
      formData.append('cover_image', await compressImage(picture));
    }

    if (current) {
      const requestOptions = {
        method: 'PUT',
        body: formData
      };

      fetch('/api/v1/sheets/sectors/' + current.id, requestOptions)

        .then(res => res.json())
        .then((data) => {
          setSectors(prev => {
            return prev.map(item => {
              if (item.id === data.id) {
                item = data
              }
              return item;
            })
          });
          setFormSubmited(false);
          setFormValues(defaultValues);
          setOpen(false);
          setInfo({
            type: 'success',
            message: "Secteur modifié avec succès"
          })
          setCurrent(null);
          setPicture(null);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    } else {
      const requestOptions = {
        method: 'POST',
        body: formData
      };
      fetch('/api/v1/sheets/sectors', requestOptions)
        .then(res => res.json())
        .then((data) => {
          setFormSubmited(false);
          setFormValues(defaultValues);
          setOpen(false);
          setInfo({
            type: 'success',
            message: "Secteur ajouté avec succès"
          })
          setSectors([data, ...sectors]);
          setPicture(null);
        })
        .catch(error => {
          setInfo({
            type: 'error',
            message: "Erreur"
          });
        });
    }
  };

  const handleDelete = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    };
    fetch('/api/v1/sheets/sectors/' + current.id, requestOptions)
      .then((res) => {
        setSectors(prev => prev.filter((item) => item.id !== current.id))
        setOpenDelete(false);
        setInfo({
          type: 'success',
          message: "Secteur supprimé avec succès"
        })
        setCurrent(null);
      })
      .catch(error => {
        setInfo({
          type: 'error',
          message: "Erreur"
        });
      });
  }

  function closeDrawer() {
    setIsDrawerOpen(false)
  }

  function onDrop(picture: any) {
    setPicture(picture[0])
  }

  // const validate = () => {
  //   axios.put("/api/v1/sheets/validate/sector/" + current.id).then(async res => {
  //     setInfo({
  //       type: "success",
  //       message: "Secteur publié avec succès!"
  //     });
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1000);
  //   })
  // }

  const validate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments à valider"
      })

    axios.put('/api/v1/sheets/validate/sector/multiple', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élements validé avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "add":
        return handleClickOpen();
      case "validate":
        return validate();
    }
  };

  return (
    <>
      <PageHeader title={`Secteurs (${sectors.length})`}>
        <ActionsGroup onClick={handleAction} actions={actions} />
      </PageHeader>
      <Paper>
        <Table
          hasSelection
          isLoading={isLoading}
          actionAbilities={["ADMIN", "MANAGER"]}
          columnsToSearchIn={["name"]}
          rowsIdentifier='id'
          columns={SectorDataRowsColumns}
          data={sectors}
          onRowClick={(row) => {
            navigate("/fiches-metier/secteurs/" + row.id)
          }}
          onRowDelete={(row) => {
            setCurrent(row)
            setOpenDelete(true);
          }}
          onRowEdit={(row) => {
            setCurrent(row);
            setFormValues({
              name: row.name,
              description: row.description,
              presentation: row.presentation
            });
            setOpen(true);
          }}
          onRowChecked={setSelectedRows}
        />
      </Paper>

      <Dialog
        title={Boolean(current) ? "Modifier " : "Ajouter"}
        confirmButtonTitle={Boolean(current) ? "Modifier" : "Nouveau"}
        open={open}
        handleConfirm={handleSave}
        handleClose={handleClose}>
        <DialogContent>
          <TextFieldGroup>
            <TextField
              label="Nom"
              name="name"
              value={formValues.name}
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              error={isFormSubmited && formValues.name.length === 0}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && formValues.name.length === 0
                  ? "Veuillez remplir le nom du secteur"
                  : ""
              }
              required

            />
          </TextFieldGroup>
          <TextFieldGroup>
            <TextField
              label="Desciption"
              name="description"
              value={formValues.description}
              margin="dense"
              type="text"
              fullWidth
              onChange={handleInputChange}
            />
          </TextFieldGroup>
          <InputLabel
            className={classes.label}
          >
            Présentation
          </InputLabel>
          <br />
          <Editor
            value={formValues.presentation}
            onChange={(content) => {
              setFormValues({
                ...formValues,
                presentation: content
              });
            }}
          />

          <ImageUploader
            singleImage
            withIcon={true}
            withPreview={true}
            buttonText='Image de couverture'
            onChange={onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
          />
        </DialogContent>
      </Dialog>


      <Dialog
        title="Suppression"
        confirmButtonTitle="Supprimer"
        open={openDelete}
        handleConfirm={handleDelete}
        handleClose={handleCloseDelete}>
        <DialogContent>
          Veuillez confirmer la suppression
        </DialogContent>
      </Dialog>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h3">
                  <b style={{ marginRight: 10 }}>{current?.name}</b>
                  <InlinedChip
                    label={current?.createdAt && formatDate(current?.createdAt, "LLL dd, yyyy")}
                    backgroundColor={COLORS['blueFocus']}
                    size="small"
                  />
                </Typography>
              }
            />
            {/* {
              current && current.status === "TO_VALIDATE" &&
              <Can abilities={["ADMIN", "MANAGER"]}>
                <Button
                  variant="contained"
                  color="primary"
                  css={`
                  margin-right: 12px;
                `}
                  onClick={validate}
                >
                  Publier
                </Button>
              </Can>
            } */}
          </ListItem>

          <Divider />

          <ListItem>
            <img className={classes.coverImage} src={current?.cover_image || '/images/no-image.png'} alt="" />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <Typography>
                  {current?.description}
                </Typography>
              }
            />
          </ListItem>

          <Divider />

          <ListItem>
            <div dangerouslySetInnerHTML={{ __html: current?.presentation }}>
            </div>
          </ListItem>

        </List>
      </Drawer>
    </>
  );
};

export default Sectors;
