import { RouteComponentProps, Router } from "@reach/router";
import EditArticle from "pages/EditArticle";
import React from "react";
import { MainLayout } from "ui/Layout/Main";
import Applications from "./Applications";
import { Articles } from "./Articles";
import Candidat from "./Candidat";
import Candidats from "./Candidats";
import Entreprise from "./Entreprise";
import Entreprises from "./Entreprises";
import Offer from "./Offer";
import Offers from "./Offers";
import Presentation from "./Presentation";

const JobsPage: React.FC<RouteComponentProps> = () => {
  return (
    <MainLayout>
      <Router>
        <Offers path="/offres" />
        <Applications path="/candidatures" />
        <Offer path="/offres/:id" />
        <Entreprises path="/entreprises" />
        <Entreprise path="/entreprises/:id" />
        <Candidats path="/candidats" />
        <Candidat path="/candidats/:id" />
        <Presentation path="/presentation" noPageTitle />
        <Articles path="/articles" noPageTitle />
        <EditArticle path="/articles/ajouter" section="joboffers" />
        <EditArticle path="/articles/editer" section="joboffers" />
      </Router>
    </MainLayout>
  );
};

export default JobsPage;
