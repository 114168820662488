import React from "react";
import {
  List, ListItem, ListItemText, Divider,
  Typography,
  Drawer
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import Table from "ui/Table";

import { useStyles } from 'constants/styles';
import { StudentLifeArticle } from '__types__';
import { InfoBarContext } from "contexts/InfoBarContext";
import StatusInfo from "ui/StatusInfo";
import axios from "axios";
import "styled-components/macro";
import { ARTICLE_STATUS_COLOR, DataNewsRowsColumns } from "./Studies/data";
import { PageHeader } from "ui/Header";
import Paper from "ui/Paper";
import ActionsGroup from "ui/ActionsGroup";
import Deletion from "./Deletion";
import { navigate } from "@reach/router";
import { CatArticlesDataRowsColumns } from "./data";

export interface DefaultPageProps {

  title: string;
  section: string;
  hasCategory?: boolean;

}

const actions = [
  { key: "add", label: "Ajouter", abilities: [] },
  { key: "validate", label: "Validé", abilities: ["ADMIN", "MANAGER"] },
];
export const Articles: React.FC<DefaultPageProps> = ({ title, section, hasCategory = false }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [data, setData] = React.useState<StudentLifeArticle[]>([]);
  const [current, setCurrent] = React.useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const { setInfo } = React.useContext(InfoBarContext);

  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    axios('/api/v1/articles/' + section)
      .then(res => {
        setData(res.data)
      });
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleNew = () => {
    navigate(`${window.location.href}/ajouter`);
  };

  function closeDrawer() {
    setIsDrawerOpen(false);
    setCurrent(null);
  }

  const validate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments à valider"
      })

    axios.put('/api/v1/articles/validate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élements validé avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "add":
        return handleNew();
      case "validate":
        return validate();
    }
  };

  return (
    <>
      <PageHeader title={`${title} (${data.length})`}>

        <ActionsGroup onClick={handleAction} actions={actions} />

      </PageHeader>
      <Paper>
        <Table
          actionAbilities={["ADMIN", "MANAGER"]}
          hasSelection
          rowsIdentifier="id"
          columns={hasCategory ? CatArticlesDataRowsColumns : DataNewsRowsColumns}
          data={data}
          onRowClick={(row) => {
            setCurrent(row);
            setIsDrawerOpen(true)
          }}
          onRowDelete={(row) => {
            setCurrent(row)
            setOpenDelete(true);
          }}
          onRowEdit={(row) => {
            navigate(`${window.location.href}/editer`, { state: { current: row } });
          }}
          onRowChecked={setSelectedRows}
        />
      </Paper>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/articles/"
      />

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h3">
                  <b style={{ marginRight: 10 }}>{current?.name}</b>
                  {
                    current &&
                    <StatusInfo width="90px"
                      background={ARTICLE_STATUS_COLOR[current?.status]['bg']} status={ARTICLE_STATUS_COLOR[current?.status]['text']} />
                  }
                </Typography>
              }
            />

          </ListItem>

          <Divider />

          <ListItem>
            <img className={classes.coverImage} src={current?.cover_image || '/images/img_bg_1.jpg'} alt="" />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <Typography>
                  {current?.description}
                </Typography>
              }
            />
          </ListItem>

          <Divider />

          <ListItem>
            <div dangerouslySetInnerHTML={{ __html: current?.presentation }}>
            </div>
          </ListItem>

        </List>
      </Drawer>
    </>
  );
};
export default Articles;