import React from "react";
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce';
import Compress from "browser-image-compression";


export function compressImage(file: File) {
  const options = {
    maxSizeMB: 0.01,
    useWebWorker: true,
    fileType: "image/jpeg"
  }

  return Compress(file, options)
    .then(compressedBlob => {
      const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() })
      return convertedBlobFile;
    })
    .catch(e => {
      return null;
    })
}
interface EditorProps {
  value: string;
  onChange: (content: string) => void;
}

const Editor = ({ value, onChange }: EditorProps) => {

  const change = (content: any, editor: any) => {
    onChange(content)
  }

  return (
    <TinyEditor
      apiKey="gn1xo17ff60q5tvwebn6ns27n08khaepwulgawmwntgdd39c"
      value={value}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic underline backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help image media',
        image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image media',
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          input.onchange = async function (e: any) {
            var file = e.target.files[0];
            if (file) {
              // formData.append('cover_image', await compressImage(picture));
              console.log(file.size)
              file = await compressImage(file)
              console.log(file.size)
            }
            var reader = new FileReader();
            reader.onload = function () {
              var id = 'blobid' + (new Date()).getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = (reader.result as string).split(',')[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        },
      }}
      onEditorChange={change}
    />
  )
}

export default Editor;