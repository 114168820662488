import React from "react";
import { RouteComponentProps } from "@reach/router";
import Can from "shared/@pbac/Can";

import {
  Button,
} from "@material-ui/core";

import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import Paper from "ui/Paper";
import { Sheet } from '__types__';

import { DataRowsColumns } from './data';
import EditSchoolLevel from "./Edit/SchoolLevel";
import axios from "axios";
import Deletion from "pages/Deletion";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}
const SchoolLevel: React.FC<DefaultPageProps> = () => {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<Sheet[]>([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [current, setCurrent] = React.useState(null);



  React.useEffect(() => {
    axios('/api/v1/settings/school-level')
      .then(res => {
        setData(res.data)
      });
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <>
      <PageHeader title={`Niveau d'étude (${data.length})`}>
        <Can abilities={["ADMIN", "MANAGER"]}>
          <Button
            variant="contained"
            color="primary"
            css={`
              margin-right: 12px;
            `}
            onClick={() => setOpen(true)}
          >
            Ajouter
          </Button>
        </Can>
      </PageHeader>
      <Paper>
        <Table
          actionAbilities={["ADMIN", "MANAGER"]}
          rowsIdentifier='id'
          columns={DataRowsColumns}
          data={data}
          onRowDelete={(row) => {
            setCurrent(row)
            setOpenDelete(true);
          }}
          onRowEdit={(row) => {
            setCurrent(row);
            setOpen(true);
          }}
        />
      </Paper>
      <EditSchoolLevel
        open={open}
        onClose={() => {
          setOpen(false);
          setCurrent(null);
        }}
        current={current}
      />

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/settings/school-level/"
      />
    </>
  );
};

export default SchoolLevel;
