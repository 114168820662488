import React from "react";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "ui/Form/TextField";

export interface AutocompleteProps {
  label: string;
  options: Array<any>;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  name?: string;
  [key: string]: any;
}
export default function Autocomplete({
  label,
  options,
  fullWidth,
  error,
  required,
  name,
  helperText,
  ...props
}: AutocompleteProps) {
  return (
    <MuiAutocomplete
      {...props}
      id="autocomplete"
      options={options}
      renderInput={(params) => (
        <>
          <TextField
            name={name}
            helperText={helperText}
            error={error}
            required={required}
            fullWidth={fullWidth}
            {...params}
            label={label}
            variant="outlined"
          />
        </>
      )}
    />
  );
}
