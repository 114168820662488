import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { MainLayout } from "ui/Layout/Main";

import { Users } from "./Users";
import { Activities } from "./Activities"
import { Informations } from "./Informations"
import { Cities } from "./Cities";
import SchoolLevel from "./SchoolLevel";
import ProfessionalSituation from "./ProfessionalSituation";
import ExperienceLevel from "./ExperienceLevel";
import ContractType from "./ContractType";
import Levels from "pages/Settings/Levels";
import About from "./About";

const SettingsPage: React.FC<RouteComponentProps> = () => {
  return (
    <MainLayout>
      <Router>
        <Users path="/utilisateurs" noPageTitle />
        <Activities path="/historique" noPageTitle />
        <Informations path="/informations" noPageTitle />
        <About path="/a-propos" noPageTitle />

      </Router>
    </MainLayout>
  );
};

export const Settings1Page: React.FC<RouteComponentProps> = () => {
  return (
    <MainLayout>
      <Router>
        <Cities path="/villes" noPageTitle />
        <Levels path="/niveau" noPageTitle />
        <SchoolLevel path="/etudes" noPageTitle />
        <ProfessionalSituation path="/situation-professionnel" noPageTitle />
        <ExperienceLevel path="/experience" noPageTitle />
        <ContractType path="/types-de-contrat" noPageTitle />
      </Router>
    </MainLayout>
  );
};

export default SettingsPage;
