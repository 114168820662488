import React from "react";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from 'ui/Form/TextField';

import { InfoBarContext } from "contexts/InfoBarContext";
import axios from "axios";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const defaultValues = {
  ontop: false,
  top_start_date: '',
  top_end_date: ''
}

interface EditArticleProps {
  open: boolean;
  onClose: () => void;
  article?: any;
}
export const EditArticle: React.FC<EditArticleProps> = ({ open, onClose, article }) => {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);


  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    if (article) {
      setCurrent(article);
      setFormValues({
        ontop: article.ontop,
        top_start_date: article.ontop_start_date,
        top_end_date: article.ontop_end_date,
      });
    }
  }, [article]);

  const handleClose = () => {
    setCurrent(null);
    setFormValues(defaultValues);
    onClose();
  };

  const handleSave = () => {
    setFormSubmited(true);

    if (formValues.ontop && (!Boolean(formValues.top_start_date) || !Boolean(formValues.top_end_date))) return;

    if (current) {
      axios.put('/api/v1/joboffers/' + current.id + "/ontop", {
        ...formValues
      }).then(async res => {
        setFormSubmited(false);
        onClose();
        setInfo({
          type: "success",
          message: "Article modifier avec succès"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
    }
  };

  return (
    <Dialog
      title="Mettre à la une"
      confirmButtonTitle={"Validé"}
      open={open}
      handleConfirm={handleSave}
      handleClose={handleClose}>

      <TextFieldGroup>
        <RadioGroup name="ontop" value={formValues.ontop} onChange={(e) => setFormValues({
          ...formValues,
          ontop: e.target.value === "true" ? true : false
        })} row>
          <FormControlLabel value={true} control={<Radio />} label="Oui" />
          <FormControlLabel value={false} control={<Radio />} label="Non" />
        </RadioGroup>
      </TextFieldGroup>


      {
        formValues.ontop &&
        <>
          <TextFieldGroup>
            <TextField
              label="Date de debut"
              name="top_start_date"
              value={formValues.top_start_date}
              autoFocus
              margin="dense"
              type="date"
              fullWidth
              error={isFormSubmited && !Boolean(formValues.top_start_date)}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && !Boolean(formValues.top_start_date)
                  ? "Veuillez saisir la date de debut"
                  : ""
              }
              required

            />
          </TextFieldGroup>

          <TextFieldGroup>
            <TextField
              label="Date de fin"
              name="top_end_date"
              value={formValues.top_end_date}
              margin="dense"
              type="date"
              fullWidth
              error={isFormSubmited && !Boolean(formValues.top_end_date)}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && !Boolean(formValues.top_end_date)
                  ? "Veuillez saisir la date de fin"
                  : ""
              }
              required

            />
          </TextFieldGroup>
        </>
      }

    </Dialog>
  );
};
export default EditArticle;