import React from "react";
import InfoBar, { InfoType } from "ui/InfoBar";

export interface ITaskContext {
  info: InfoType;
  setInfo: React.Dispatch<React.SetStateAction<InfoType>>;
}
export const InfoBarContext = React.createContext<ITaskContext>(null);

const InfoBarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [info, setInfo] = React.useState<InfoType>(null);
  return (
    <InfoBarContext.Provider
      value={{
        info,
        setInfo,
      }}
    >
      {children}
      <InfoBar onInfo={() => setInfo(null)} info={info} />
    </InfoBarContext.Provider>
  );
};

export default InfoBarProvider;
