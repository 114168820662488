import { format } from "date-fns";
import Compress from "browser-image-compression";
import { ca } from "date-fns/locale";

export function formatDate<T extends string | number | Date>(date: T, formatStr: string) {
  return format(new Date(date), formatStr, {
    useAdditionalWeekYearTokens: true,
    useAdditionalDayOfYearTokens: true,
  });
}

export function sortByDescendant<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function stablilizeTableSorting<T extends any[]>(
  array: T,
  cmp: (a: any, b: any) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getTableSorting(
  order: "asc" | "desc",
  orderBy: any
): (a: any, b: any) => number {
  return order === "desc"
    ? (a, b) => sortByDescendant(a, b, orderBy)
    : (a, b) => -sortByDescendant(a, b, orderBy);
}

/**
 *
 * @param obj
 * @param term
 * @param key
 * @param columnsToSearchIn
 * @returns {boolean}
 */
const doesExist = (
  obj: any,
  term: string,
  key: string,
  columnsToSearchIn?: Array<string>
) => {
  if (columnsToSearchIn === undefined)
    return String(obj[key]).toLowerCase().includes(term);
  return columnsToSearchIn.includes(key) && String(obj[key]).toLowerCase().includes(term);
};

export function makeSearch<T extends any[]>(
  items: T,
  term: string,
  columnsToSearchIn?: Array<string>
) {
  let getNeedles = (obj: any): boolean =>
    Object.keys(obj).some((key) => {
      let haystack =
        obj[key] !== null && typeof obj[key] === "object"
          ? // Go full recursive when the key is an object ✨
          getNeedles(obj[key])
          : doesExist(obj, term, key, columnsToSearchIn);

      return haystack;
    });

  return items.filter((item) => getNeedles(item)) as T;
}

/**
 *
 * @param index
 */
export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
export function compressImage(file: File) {
  const options = {
    maxSizeMB: 0.5,
    useWebWorker: true,
    fileType: "image/jpeg"
  }

  return Compress(file, options)
    .then(compressedBlob => {
      const convertedBlobFile = new File([compressedBlob], file.name, { type: file.type, lastModified: Date.now() })
      return convertedBlobFile;
    })
    .catch(e => {
      return null;
    })
}