import React from "react";

import { Column } from "ui/Table";
import { Sheet, Sector } from '__types__';
import { formatDate } from "utils";
import { ARTICLE_STATUS_COLOR } from "pages/StudentLife/data";
import StatusInfo from "ui/StatusInfo";

type S = {
  id: string;
  name: string;
  description?: string;
  presentation?: string;
  curriculum?: string;
  remuneration?: string;
  opportunities?: string;
  createdAt?: string;
  category?: any;
}

export const SectorDataRowsColumns: Column<Sector>[] = [
  {
    title: "Nom",
    field: "name",
  },
  // {
  //   title: "Description",
  //   field: "description",
  // },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo width="90px" background={ARTICLE_STATUS_COLOR[status]['bg']} status={ARTICLE_STATUS_COLOR[status]['text']} />
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "LL/dd/yyyy · HH:mm")}
      </>
    ),
  },
]
export const SheetDataRowsColumns: Column<any>[] = [
  {
    title: "Nom",
    field: "name",
  },
  {
    title: "Catégorie",
    field: "category",
    render: ({ category }) => (
      <>
        {category?.name}
      </>
    ),
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo width="90px" background={ARTICLE_STATUS_COLOR[status]['bg']} status={ARTICLE_STATUS_COLOR[status]['text']} />
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "LL/dd/yyyy · HH:mm")}
      </>
    ),
  },
]