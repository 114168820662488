import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Followup } from '__types__';
import Paper from "ui/Paper";
import Accordion from "@material-ui/core/Accordion";

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from "@material-ui/core";
import { InfoBarContext } from "contexts/InfoBarContext";
import { EducationTypeDataRowsColumns } from "./data";
import EditEducationType from "./Edit/EditEducationType";
import DeletionDialog from "ui/Dialog/Deletion";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      backgroundColor: "#f1f1f1",
      display: "block"
    }
  }),
);


export const Home: React.FC<DefaultPageProps> = () => {
  const [openEducationType, setOpenEducationType] = React.useState(false);
  const [data, setData] = React.useState<Followup[]>([]);
  const classes = useStyles();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);

  React.useEffect(() => {
    axios('/api/v1/settings/education-type')
      .then(res => {
        setData(res.data)
      });
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };


  const handleDelete = () => {
    axios.delete('/api/v1/settings/education-type/' + current.id)
      .then(res => {
        setOpenDelete(false);
        setInfo({
          type: "success",
          message: "Type d'enseignement supprimer avec succès'"
        })
        window.location.reload();
      });
  }

  return (
    <>
      <PageHeader title={`Paramétrages Études (${data.length})`}>
      </PageHeader>

      <Paper style={{ padding: "50px" }}>
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Type d'enseignement</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <div>
              <p>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenEducationType(true)
                  }}
                >
                  Ajouter
              </Button>
              </p>
            </div>
            <Table
              actionAbilities={["ADMIN", "MANAGER"]}
              hasToolBar={false}
              rowsIdentifier='id'
              columns={EducationTypeDataRowsColumns}
              data={data}
              onRowEdit={(row) => {
                setCurrent(row)
                setOpenEducationType(true);
              }}
              onRowDelete={(row) => {
                setCurrent(row)
                setOpenDelete(true);
              }}
            />
          </AccordionDetails>
        </Accordion>

      </Paper>

      <EditEducationType open={openEducationType}
        onClose={() => {
          setOpenEducationType(false)
          setCurrent(null)
        }}
        current={current}
      />
      <DeletionDialog
        title="Suppression"
        message={"Voulez-vous vraiment supprimer cet élément ?"}
        open={openDelete}
        onClose={handleCloseDelete}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default Home;
