import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Followup } from '__types__';
import { FollowupColumns } from './data';
import { Button } from "@material-ui/core";
import EditIntervention from "./EditIntervention";
import Paper from "ui/Paper";
export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = () => {
  const [data, setData] = React.useState<Followup[]>([]);

  React.useEffect(() => {
    axios('/api/v1/tutoring/followups')
      .then(res => {
        setData(res.data)
      })
  }, []);
  return (
    <>
      <PageHeader title={`Suivis intervention (${data.length})`}>
      </PageHeader>

      <Paper>
        <Table
          rowsIdentifier="id"
          columns={FollowupColumns}
          data={data}
        />
      </Paper>
    </>
  );
};

export default Home;
