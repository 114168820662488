import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import axios from "axios";
import Paper from "ui/Paper";
import Accordion from "@material-ui/core/Accordion";

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from "@material-ui/core";
import EditPage from "pages/JobOffers/Edit/EditPage";
export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      backgroundColor: "#f1f1f1",
      display: "block"
    }
  }),
);


export const Home: React.FC<DefaultPageProps> = () => {
  const classes = useStyles();
  const [openPage, setOpenPage] = React.useState(false);
  const [page, setPage] = React.useState("about_studies");

  const [currentPage, setCurrentPage] = React.useState(null);

  const [pageEntreprise, setPageEntreprise] = React.useState(null);

  React.useEffect(() => {
    axios('/api/v1/tutoring/page-text/15')
      .then(res => {
        setPageEntreprise(res.data)
      });
  }, []);

  return (
    <>
      <PageHeader title="À propos">
      </PageHeader>

      <Paper style={{ padding: "50px" }}>
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}></Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <div>
              <p>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenPage(true)
                    setCurrentPage(pageEntreprise);
                  }}
                >
                  Modifier
                </Button>
              </p>
            </div>
            <Typography variant="h3">
              {pageEntreprise?.name}
            </Typography>
            <br />
            {
              pageEntreprise?.cover_image &&
              <img width="100%" height="300" src={pageEntreprise?.cover_image} alt="" />
            }
            <Typography variant="h5">
              {pageEntreprise?.description}
            </Typography>
            <Typography>
              <div dangerouslySetInnerHTML={{ __html: pageEntreprise?.presentation }}></div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Paper>

      <EditPage open={openPage} onClose={() => setOpenPage(false)} page={page} article={currentPage} />
    </>
  );
};

export default Home;
