import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Intervention } from "__types__";
import { InterventionColumns } from "./data";
import { Button } from "@material-ui/core";
import EditIntervention from "./EditIntervention";
import Paper from "ui/Paper";
import Deletion from "pages/Deletion";
export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = () => {
  const [data, setData] = React.useState<Intervention[]>([]);
  const [open, setOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);

  console.log(data);

  React.useEffect(() => {
    axios("/api/v1/tutoring/interventions").then((res) => {
      setData(res.data);
    });
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <>
      <PageHeader title={`Interventions (${data.length})`}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          Ajouter
        </Button>
      </PageHeader>

      <Paper>
        <Table
          rowsIdentifier="id"
          columns={InterventionColumns}
          data={data}
          onRowClick={(row) => {
            navigate("/soutien-scolaire/interventions/" + row.id);
          }}
          onRowDelete={(row) => {
            setCurrent(row);
            setOpenDelete(true);
          }}
        />
      </Paper>

      <EditIntervention open={open} onClose={() => setOpen(false)} />

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/tutoring/interventions/"
      />
    </>
  );
};

export default Home;
