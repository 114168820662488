import React from "react";

import { Column } from "ui/Table";
import { User, Activity, StudentLifeArticle } from "__types__";
import { formatDate } from "utils";
import StatusInfo from "ui/StatusInfo";
import "styled-components/macro";
import { ACCOUNT_STATUS_COLOR } from "pages/Tutoring/data";

const ROLE_COLOR = {
  ADMIN: {
    bg: "#4CBE29",
    text: "ADMIN"
  },
  MANAGER: {
    bg: "#DBA226",
    text: "MANAGER"
  },
  OPERATOR: {
    bg: "#287EDB",
    text: "OPERATEUR"
  }
}

const d = {
  true: "Activer",
  false: "Desactiver",
}

export const DataRowsColumns: Column<any>[] = [
  {
    title: "Nom",
    field: "name"
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
];


export const UserDataRowsColumns: Column<User>[] = [
  {
    title: "Nom",
    field: "firstname"
  },
  {
    title: "Prénom",
    field: "lastname"
  },
  {
    title: "Email",
    field: "email"
  },
  {
    title: "Role",
    field: "role",
    render: ({ role }) => (
      <StatusInfo width="90px" background={ROLE_COLOR[role]['bg']} status={ROLE_COLOR[role]['text']} />
    ),
  },
  {
    title: "Compte",
    field: "activate",
    render: ({ active }) => (
      <>
        <StatusInfo width="80px" background={ACCOUNT_STATUS_COLOR[active.toString()]['bg']} status={ACCOUNT_STATUS_COLOR[active.toString()]['text']} />
      </>
    ),
  },
  {
    title: "Statut",
    field: "account_status",
    render: ({ account_status }) => (
      <>
        <span
          css={`
            width: 10px;
            height: 10px;
            background-color: ${account_status ? "green" : "gray"};
            display: inline-block;
            border-radius: 30px;
            margin-right: 5px;
          `}
        ></span>
        {account_status ? "Activer" : "Désactiver"}
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LL/dd/yyyy · HH:mm")}</>
    ),
  },
];

export const ActivityDataRowsColumns: Column<Activity>[] = [
  {
    title: "Date",
    field: "trigger_date",
    render: ({ trigger_date }) => (
      <>{formatDate(trigger_date, "LL/dd/yyyy · HH:mm")}</>
    ),
  },
  {
    title: "Action",
    field: "trigger_action",
    render: ({ trigger_action }) => (
      <b
        css={`
          font-size: 13px;
        `}
      >
        {trigger_action}
      </b>
    ),
  },
  {
    title: "User",
    field: "trigger_by",
    render: ({ trigger_by }) => <>{trigger_by}</>,
  },
  {
    title: "Source",
    field: "trigger_source",
    render: ({ trigger_source }) => <>{trigger_source}</>,
  },
];
