import React from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  InputLabel, Radio, RadioGroup,
} from "@material-ui/core";

import "styled-components/macro";
import { CustomSelect } from "ui/Custom/Select";
import { TextField, TextFieldGroup } from "ui/Form/TextField";

import { Sheet } from "__types__";

import ImageUploader from "react-images-upload";

import { useStyles } from 'constants/styles';
import "styled-components/macro";
import { InfoBarContext } from "contexts/InfoBarContext";
import { compressImage } from "utils";
import Editor from "pages/Editor";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";

export interface EditSheetProps extends RouteComponentProps {
  open?: boolean;
  onClose?: (data: any) => void;
  current?: any;
}

interface IForm {
  name: string;
  description?: string;
  sector_id?: string;
  presentation?: string;
  curriculum?: string;
  remuneration?: string;
  opportunities?: string;

  onhome_page?: string;
  onhome_start_date?: string;
  onhome_end_date?: string;

}

const defaultValues: IForm = {
  sector_id: "",
  name: "",
  description: "",
  presentation: "",
  curriculum: "",
  remuneration: "",
  opportunities: "",
  onhome_page: "false",
  onhome_start_date: null,
  onhome_end_date: null,
};

export const EditSheet: React.FC<EditSheetProps> = ({ ...props }) => {
  const classes = useStyles();
  const [sectors, setSectors] = React.useState<Sheet[]>([]);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const [picture, setPicture] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    if (props['id']) {
      fetch('/api/v1/sheets/' + props['id'])
        .then(res => res.json())
        .then((data) => {
          setCurrent(data)
        })
        .catch(console.log)
    }
  }, [props['id']]);

  React.useEffect(() => {
    fetch("/api/v1/sheets/sectors")
      .then((res) => res.json())
      .then((data) => {
        setSectors(data);
      })
      .catch(console.log);
  }, []);

  React.useEffect(() => {
    if (current) {
      setFormValues({
        name: current.name,
        description: current.description,
        presentation: current.presentation,
        curriculum: current.curriculum,
        remuneration: current.remuneration,
        opportunities: current.opportunities,
        sector_id: current.sector_id,
        onhome_page: current.onhome_page.toString(),
        onhome_start_date: current.onhome_start_date,
        onhome_end_date: current.onhome_end_date,
      });
    }
  }, [current]);

  const handleSave = async () => {
    setFormSubmited(true);

    if (formValues.name.length === 0 || formValues.sector_id.length === 0)
      return;

    setLoading(true);

    const formData = new FormData();

    formData.append("data", JSON.stringify(formValues));

    if (picture) {
      formData.append('cover_image', await compressImage(picture));
    }

    if (current) {
      const requestOptions = {
        method: "PUT",
        body: formData,
      };

      fetch("/api/v1/sheets/" + current.id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setFormSubmited(false);
          setFormValues(defaultValues);
          setInfo({
            type: 'success',
            message: "Fiche metier modifié avec succès"
          })
          navigate("/fiches-metier/fiches-metiers");
        })
        .catch((error) => {
          setInfo({
            type: 'error',
            message: "Erreur"
          });
        });
    } else {
      const requestOptions = {
        method: "POST",
        body: formData,
      };

      fetch("/api/v1/sheets", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          setFormSubmited(false);
          setFormValues(defaultValues);

          setInfo({
            type: 'success',
            message: "Fiche metier ajouté avec succès"
          });
          navigate("/fiches-metier/fiches-metiers");
        })
        .catch((error) => {
          setInfo({
            type: 'error',
            message: "Erreur"
          });
        });
    }
  };

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  return (
    <>
      <PageHeader title={`Fiches métiers`}>
        <Button style={{ width: "168px" }} color="primary" variant="contained" onClick={handleSave} disabled={loading}>
          {loading ? <CircularProgress size={30} /> : (Boolean(current) ? "Modifier" : "Ajouter")}
        </Button>
      </PageHeader>

      <TextFieldGroup>
        <TextField
          label="Nom"
          name="name"
          value={formValues.name}
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.name.length === 0}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && formValues.name.length === 0
              ? "Veuillez saisir le nom de la fiche"
              : ""
          }
          required
        />
      </TextFieldGroup>
      <TextFieldGroup>
        <CustomSelect
          css={`
                width: 100%;
              `}
          label="Secteur"
          name="sector_id"
          onChange={handleInputChange}
          required
          value={formValues.sector_id}
          error={isFormSubmited && formValues.sector_id.length === 0}
          helperText={
            isFormSubmited && formValues.sector_id.length === 0
              ? "Veuillez sélectionner le secteur"
              : ""
          }
        >
          {sectors.map((s, k) => (
            <option key={k} value={s.id}>
              {s.name}
            </option>
          ))}
        </CustomSelect>
      </TextFieldGroup>

      <TextFieldGroup>
        <TextField
          label="Desciption"
          name="description"
          value={formValues.description}
          margin="dense"
          type="text"
          fullWidth
          onChange={handleInputChange}
        />
      </TextFieldGroup>
      <InputLabel className={classes.label}>Présentation</InputLabel>
      <br />
      <Editor
        value={formValues.presentation}
        onChange={(content) => {
          setFormValues({
            ...formValues,
            presentation: content
          });
        }}
      />
      <br />

      <InputLabel className={classes.label}>Cursus</InputLabel>
      <br />
      <Editor
        value={formValues.curriculum}
        onChange={(content) => {
          setFormValues({
            ...formValues,
            curriculum: content
          });
        }}
      />

      <br />

      <InputLabel className={classes.label}>Remunération</InputLabel>
      <br />
      <Editor
        value={formValues.remuneration}
        onChange={(content) => {
          setFormValues({
            ...formValues,
            remuneration: content
          });
        }}
      />

      <br />

      <InputLabel className={classes.label}>Débouchés</InputLabel>
      <br />
      <Editor
        value={formValues.opportunities}
        onChange={(content) => {
          setFormValues({
            ...formValues,
            opportunities: content
          });
        }}
      />

      <ImageUploader
        singleImage
        withIcon={true}
        withPreview={true}
        buttonText="Image de couverture"
        onChange={onDrop}
        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
        maxFileSize={5242880}
      />

      <div>
        <InputLabel
          className={classes.label}
        >
          Mettre sur la page d'accueil
        </InputLabel>
        <RadioGroup defaultValue="" name="onhome_page" value={formValues.onhome_page} onChange={handleInputChange} row>

          <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
          <FormControlLabel value={"false"} control={<Radio />} label="Non" />
        </RadioGroup>

        {
          formValues.onhome_page === "true" &&
          <>
            <TextFieldGroup>
              <TextField
                label="Date de debut"
                name="onhome_start_date"
                value={formValues.onhome_start_date}
                margin="dense"
                type="date"
                fullWidth
                error={isFormSubmited && !Boolean(formValues.onhome_start_date)}
                onChange={handleInputChange}
                helperText={
                  isFormSubmited && !Boolean(formValues.onhome_start_date)
                    ? "Veuillez saisir la date de debut"
                    : ""
                }
                required

              />
            </TextFieldGroup>

            <TextFieldGroup>
              <TextField
                label="Date de fin"
                name="onhome_end_date"
                value={formValues.onhome_end_date}
                margin="dense"
                type="date"
                fullWidth
                error={isFormSubmited && !Boolean(formValues.onhome_end_date)}
                onChange={handleInputChange}
                helperText={
                  isFormSubmited && !Boolean(formValues.onhome_end_date)
                    ? "Veuillez saisir la date de fin"
                    : ""
                }
                required

              />
            </TextFieldGroup>
          </>
        }

      </div>

      <div style={{ textAlign: "right" }}>
        <br />
        <Button style={{ width: "168px" }} color="primary" variant="contained" onClick={handleSave} disabled={loading}>
          {loading ? <CircularProgress size={30} /> : (Boolean(current) ? "Modifier" : "Ajouter")}
        </Button>

      </div>
    </>
  );
};

export default EditSheet;
