import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Course, Level, Teacher } from '__types__';
import { TeacherColumns } from './data';
import Paper from "ui/Paper";
import FiltersOptions from "./FiltersOptions";
import Autocomplete from "ui/Autocomplete";
import Deletion from "pages/Deletion";
import ActionsGroup from "ui/ActionsGroup";
import { InfoBarContext } from "contexts/InfoBarContext";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const defaultFilters = {
  course_id: [] as string[],
  level_id: [] as string[],
}


const actions = [
  { key: "activate", label: "Activer", abilities: ["ADMIN", "MANAGER"] },
  { key: "desactivate", label: "Désactiver", abilities: ["ADMIN", "MANAGER"] },
];

export const Home: React.FC<DefaultPageProps> = () => {
  const [data, setData] = React.useState<Teacher[]>([]);
  const [dataToShow, setDataToShow] = React.useState<Teacher[]>([]);
  const [levels, setLevels] = React.useState<Level[]>([]);
  const [courses, setCourses] = React.useState<Course[]>([]);
  const [cities, setCities] = React.useState<string[]>([]);
  const [city, setCity] = React.useState(null);

  const [filters, setFilters] = React.useState(defaultFilters);
  const { setInfo } = React.useContext(InfoBarContext);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handlFilters = (key: string, value: any) => {
    setFilters({ ...filters, [key]: value });
  }

  React.useEffect(() => {
    const res = (data.filter(item => item.teachings.filter(nn => getNeedles(nn)).length > 0)).filter(item => item.city.includes(city || ""))
    setDataToShow(res)

  }, [filters.course_id.length, filters.level_id.length, city]);


  const [current, setCurrent] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  let getNeedles = (obj: any): boolean =>
    Object.keys(filters).every((key) => {
      if (filters[key].length > 0) {
        return filters[key].includes(obj[key].toString())
      }
      return true;
    });


  React.useEffect(() => {
    axios('/api/v1/studies/cities')
      .then(res => {
        setCities(res.data.map((city: any) => city["name"]))
      });
    axios('/api/v1/teachers')
      .then(res => {
        setData(res.data);
        setDataToShow(res.data);
      });

    axios('/api/v1/tutoring/levels')
      .then(res => {
        setLevels(res.data)
      });

    axios('/api/v1/tutoring/courses')
      .then(res => {
        setCourses(res.data)
      });
  }, []);

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "activate":
        return activate();
      case "desactivate":
        return desactivate();
    }
  };

  const activate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments"
      })

    axios.put('/api/v1/teachers/activate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élements activé avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const desactivate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments"
      })

    axios.put('/api/v1/teachers/desactivate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élements désactivé avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const Filters = (
    <div
      style={{ display: 'flex' }}
    >
      <FiltersOptions
        label="Niveau"
        options={levels}
        onChange={(value) => { handlFilters("level_id", value) }}
      />

      <FiltersOptions
        label="Matiere"
        options={courses}
        onChange={(value) => { handlFilters("course_id", value) }}
      />

      <Autocomplete
        style={{ width: 220 }}
        label="Ville"
        options={cities}
        getOptionLabel={(option: any) => option}
        onChange={(event: any, newValue: any) => {
          setCity(newValue)
        }}
        value={city}
        getOptionSelected={(option: any, val: any) => option === val}
      />
    </div>
  )

  return (
    <>
      <PageHeader title={`Enseignants (${data.length})`} >
        <ActionsGroup onClick={handleAction} actions={actions} />
      </PageHeader>
      <Paper>
        <Table
          hasSelection
          filters={Filters}
          rowsIdentifier="id"
          columns={TeacherColumns}
          data={dataToShow}
          onRowClick={(row) => {
            navigate("/soutien-scolaire/enseignants/" + row.code)
          }}
          onRowDelete={(row) => {
            setCurrent(row)
            setOpenDelete(true);
          }}
          onRowChecked={setSelectedRows}
        />
      </Paper>
      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/teachers/"
      />
    </>
  );
};

export default Home;
