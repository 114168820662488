import { Grid } from "@material-ui/core";
import React from "react";
import Dialog from 'ui/Dialog';
import { TextField } from "ui/Form/TextField";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoBarContext } from "contexts/InfoBarContext";
import Autocomplete from "ui/Autocomplete";


export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  family?: any;
}

const schema = yup.object().shape({
  name: yup.string()
    .required("Veuillez saisir le nom"),
  in_charge: yup.string()
    .required("Veuillez saisir le responsable"),
  email: yup.string()
    .email("Veuillez saisir une adresse email valide.")
    .required("Veuillez saisir l'adresse email."),
  phonenumber: yup.string()
    .required("Veuillez saisir le telephone")
});


export const EditFamily: React.FC<EditFamilyProps> = ({ open, onClose, family }) => {

  const { register, handleSubmit, errors, reset, control, formState, setValue } = useForm({ resolver: yupResolver(schema) });

  const [cities, setCities] = React.useState<string[]>([]);
  const [city, setCity] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);




  React.useEffect(() => {
    axios('/api/v1/studies/cities')
      .then(res => {
        setCities(res.data.map((city: any) => city["name"]))
      });
  }, []);

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = (data: any) => {
    if (!Boolean(city)) return;

    // setIsSubmited(true);
    if (family) {
      // handleEdit({ ...data, role });
    } else {
      axios.post("/api/v1/families", {
        ...data,
        city: city
      }).then(async res => {
        setInfo({
          type: "success",
          message: "Famille ajouter avec succès!"
        })
        reset();
        handleClose();
        window.location.reload();
      })
    }
  };



  return (

    <Dialog
      title={"Ajouter une famille"}
      confirmButtonTitle={"Ajouter"}
      cancelButtonTitle={"Annuler"}
      open={open}
      handleConfirm={handleSubmit(onSubmit)}
      handleClose={handleClose}
      maxWidth="lg"
      height="612px"
    >
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TextField
            required
            name="name"
            fullWidth
            label="Nom"
            inputRef={register}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="in_charge"
            fullWidth
            label="Responsable"
            inputRef={register}
            error={Boolean(errors.in_charge)}
            helperText={errors.in_charge?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="email"
            fullWidth
            label="Email"
            type="email"
            inputRef={register}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            name="phonenumber"
            fullWidth
            label="Téléphone"
            inputRef={register}
            error={Boolean(errors.phonenumber)}
            helperText={errors.phonenumber?.message}
          />
        </Grid>

        <Grid item xs={6}>
          <Autocomplete
            required
            label="Ville"
            options={cities}
            getOptionLabel={(option: any) => option}
            onChange={(event: any, newValue: any) => {
              setCity(newValue)
            }}
            error={formState.isSubmitted && !Boolean(city)}
            helperText={formState.isSubmitted && !Boolean(city) && "Veuillez saisir la ville."}
            value={city}
            getOptionSelected={(option: any, val: any) => option === val}
          />

        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditFamily;
