import React from "react";
import {
  List, ListItem, ListItemText, Divider,
  Typography,
  Drawer,
  Button
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import Table from "ui/Table";

import { useStyles } from 'constants/styles';
import { StudentLifeArticle } from '__types__';
import { InfoBarContext } from "contexts/InfoBarContext";
import axios from "axios";
import "styled-components/macro";
import { PageHeader } from "ui/Header";
import Paper from "ui/Paper";
import ActionsGroup from "ui/ActionsGroup";
import { DataProfRowsColumns } from "./data";
import EditProf from "./EditProf";
import Deletion from "pages/Deletion";

export interface DefaultPageProps {

}

const actions = [
  { key: "add", label: "Ajouter", abilities: [] },
  { key: "validate", label: "Validé", abilities: ["ADMIN", "MANAGER"] },
];
export const Articles: React.FC<DefaultPageProps> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [data, setData] = React.useState<StudentLifeArticle[]>([]);
  const [current, setCurrent] = React.useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const { setInfo } = React.useContext(InfoBarContext);

  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    axios('/api/v1/tutoring/professors')
      .then(res => {
        setData(res.data)
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCurrent(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpen = () => {
    setCurrent(null);
    setOpen(true);
  };


  function closeDrawer() {
    setIsDrawerOpen(false);
    setCurrent(null);
  }

  return (
    <>
      <br />

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setOpen(true)
        }}
      >
        Ajouter
      </Button>
      <br />
      <br />
      <Table
        actionAbilities={["ADMIN", "MANAGER"]}
        rowsIdentifier="id"
        hasToolBar={false}
        columns={DataProfRowsColumns}
        data={data}
        onRowClick={(row) => {
          setCurrent(row);
          setIsDrawerOpen(true)
        }}
        onRowDelete={(row) => {
          setCurrent(row)
          setOpenDelete(true);
        }}
        onRowEdit={(row) => {
          setCurrent(row);
          setOpen(true);
        }}
        onRowChecked={setSelectedRows}
      />

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/tutoring/professors/"
      />

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h3">
                  <b style={{ marginRight: 10 }}>{current?.name}</b>
                </Typography>
              }
            />

          </ListItem>

          <ListItem>
            Facebook: {current?.facebook}

          </ListItem>

          <ListItem>
            Twitter: {current?.twitter}

          </ListItem>

          <Divider />

          <ListItem>
            <img className={classes.coverImage} src={current?.image} alt="" />
          </ListItem>

          <Divider />

        </List>
      </Drawer>

      <EditProf open={open} onClose={handleClose} current={current} />
    </>
  );
};
export default Articles;