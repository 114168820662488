import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, RouteComponentProps, useLocation } from "@reach/router";
import { parse } from "query-string";
import jwtDecode from "jwt-decode";

import {
  Paper,
  Box,
  Typography,
  Container,
  Button,
} from "@material-ui/core";
import { TextFieldGroup, TextField } from "ui/Form/TextField";
import { User } from "__types__";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
    display: "flex-start",
    alignItems: "center",
    paddingTop: 100,
  },
  content: {
    width: "30%",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  form: {
    width: "100%",
  },
  title: {
    fontSize: 40,
    fontWeight: 800,
  },
  subtitle: {
    fontSize: 23,
    fontWeight: 400,
    lineHeight: "15px",
  },
  footer: {
    height: "10vh",
    backgroundColor: "#353743",
  },
  link: {
    textDecoration: "none",
    color: "grey",
  },
  titleFooter: {
    color: "white",
  },
}));


const defaultValues = {
  email: "",
  password: "",
  password_confirmation: "",
};

const Activating: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = parse(location.search);
  const [user, setUser] = React.useState<User>();
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [tokenNotValid, setTokenNotValid] = React.useState(false);

  const decoded = jwtDecode(searchParams.idToken as string);

  React.useEffect(() => {
    if (new Date(decoded["exp"] * 1000) <= new Date()) {
      setTokenNotValid(true);
    }
    fetch(`/api/v1/users/${decoded["id"]}`)
      .then((res) => res.json())
      .then((data) => {
        console.log("k")
        setUser(data);
      })
      .catch(console.log);
  }, [decoded, formValues]);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleSave = () => {
    setFormSubmited(true);

    if (
      formValues.password.length === 0 ||
      formValues.password_confirmation.length === 0
    )
      return;

    if (formValues.password !== formValues.password_confirmation) return;

    const formData = new FormData();
    formData.append("password", formValues.password);

    const body = JSON.stringify(Object.fromEntries(formData));

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    };

    fetch(`/api/v1/users/active-account/${decoded["id"]}`, requestOptions)

      .then((res) => res.json())
      .then((data) => {
        window.location.href = "/connexion";
        setFormSubmited(false);
        setFormValues(defaultValues);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <>
      <Paper className={classes.root} elevation={0}>
        <Container className={classes.content}>
          <Box>
            <Box display="flex" justifyContent="center" marginBottom={5}>
              <Typography variant="h1" component="h1" className={classes.title}>
                Studies
              </Typography>
            </Box>
            {!tokenNotValid ? (
              <>
                <Box display="flex" justifyContent="center" marginBottom={3}>
                  <Typography variant="h3" component="h3">
                    Bienvenue, {user?.firstname} {user?.lastname}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" marginBottom={3}>
                  <Typography variant="body1" component="p">
                    Veuillez configurer un mot de passe pour accéder à votre
                    compte.
                  </Typography>
                </Box>

                <Box>
                  <TextFieldGroup>
                    <TextField
                      label="Email"
                      name="email"
                      autoFocus
                      margin="dense"
                      type="email"
                      disabled
                      value={user?.email}
                      onChange={handleInputChange}
                      helperText="Cet email sera utilisé pour votre inscription."
                      fullWidth
                      required
                    />
                  </TextFieldGroup>
                  <TextFieldGroup>
                    <TextField
                      label="Mot de passe"
                      name="password"
                      autoFocus
                      margin="dense"
                      value={formValues.password}
                      onChange={handleInputChange}
                      type="password"
                      fullWidth
                      error={isFormSubmited && formValues.password.length === 0}
                      helperText={
                        isFormSubmited && formValues.password.length === 0
                          ? "Veuillez entrer votre mot de passe"
                          : ""
                      }
                      required
                    />
                  </TextFieldGroup>
                  <TextFieldGroup>
                    <TextField
                      label="Confirmation de mot de passe"
                      name="password_confirmation"
                      autoFocus
                      margin="dense"
                      value={formValues.password_confirmation}
                      onChange={handleInputChange}
                      type="password"
                      fullWidth
                      error={
                        (isFormSubmited &&
                          formValues.password_confirmation.length === 0) ||
                        (isFormSubmited &&
                          formValues.password_confirmation !==
                          formValues.password)
                      }
                      helperText={
                        isFormSubmited &&
                          formValues.password_confirmation.length === 0
                          ? "Veuillez confirmer votre mot de passe"
                          : isFormSubmited &&
                            formValues.password_confirmation !==
                            formValues.password
                            ? "Les mots de passe ne correspondent pas"
                            : ""
                      }
                      required
                    />
                  </TextFieldGroup>

                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      textTransform: "none",
                      backgroundColor: "#233266",
                      color: "#FFF",
                    }}
                    className={classes.form}
                    disableElevation
                    onClick={handleSave}
                  >
                    Suivant
                  </Button>
                </Box>
              </>
            ) : (
                <div>
                  Token non valid - Veuillez contacter votre administrateur
                </div>
              )}
          </Box>
        </Container>
      </Paper>
      <Paper className={classes.footer}>
        <Container>
          <Box display="flex" alignItems="center">
            <Box
              flexGrow={1}
              display="flex"
              alignItems="center"
              style={{ height: "10vh" }}
            >
              <Typography variant="body2" className={classes.titleFooter}>
                {"Copyright © "}
                <Link to="" className={classes.link}>
                  Studies
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
              </Typography>
            </Box>
            <Box display="flex" style={{ color: "white" }}>
              <Typography
                variant="body2"
                style={{ borderRight: "1px solid white", paddingRight: 10 }}
              >
                Politique de confidentialité
              </Typography>
              <Typography variant="body2" style={{ paddingLeft: 10 }}>
                Conditions d'utilisation
              </Typography>
            </Box>
          </Box>
        </Container>
      </Paper>
    </>
  );
};

export default Activating;
