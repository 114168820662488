import React from "react";
import { TableCell, IconButton, Checkbox } from "@material-ui/core";
import MuiTableRow from "@material-ui/core/TableRow";
import { Column } from "./Table";
// import { EditIcon, RemoveIcon } from "icons";
import { EditOutlined, DeleteOutline } from "@material-ui/icons";

import "styled-components/macro";
import Can from "shared/@pbac/Can";

export interface RowProps<RowData, RowDataKey extends keyof RowData> {
  actionAbilities?: Array<string>;

  columns: Array<Column<RowData>>;
  rowContent: RowData;
  rowsIdentifier: RowDataKey;
  onRowClick?(data: RowData): void;
  onRowDelete?(data: RowData): void;
  onRowEdit?(data: RowData): void;
  onRowChecked(event: React.MouseEvent<unknown, MouseEvent>, id: string): void;
  rowsCount?: number;
  selectRowsCount?: number;
  hasSelection?: boolean;
  isRowSelected(rowIdentifier: any): boolean;
  autoChecked?: boolean;
  isDisabledChecked?: boolean;
}

function FormattedTableCell<T>({ column, row }: { column: Column<T>; row: any }) {
  return <>{column.render ? column.render(row) : row[column.field]}</>;
}

export const TableRow = <RowData, RowDataKey extends keyof RowData>({
  actionAbilities,
  rowContent,
  columns,
  rowsCount,
  selectRowsCount,
  rowsIdentifier,
  onRowChecked,
  hasSelection,
  onRowClick,
  onRowDelete,
  onRowEdit,
  isRowSelected,
  autoChecked = false,
  isDisabledChecked = false,
}: RowProps<RowData, RowDataKey>) => {
  const rowId = (rowContent[rowsIdentifier] as unknown) as string;
  const isSelected = isRowSelected(rowId);
  return (
    <>
      <MuiTableRow
        role="row"
        selected={autoChecked}
        hover
        onClick={(event) => {
          event.stopPropagation();
          onRowClick && onRowClick(rowContent);
        }}
      >
        {hasSelection && (
          <TableCell align="right" padding="checkbox">
            <Checkbox
              color="primary"
              disabled={isDisabledChecked}
              checked={isSelected || rowsCount === selectRowsCount || autoChecked}
              onClick={(event) => {
                event.stopPropagation();
                onRowChecked && onRowChecked(event, rowId);
              }}
              css={`
                &.Mui-checked {
                  color: #399ebf;
                }
              `}
            />
          </TableCell>
        )}
        {columns?.map((column) => (
          <TableCell key={column.field} align="left">
            <FormattedTableCell column={column} row={rowContent} />
          </TableCell>
        ))}

        {(onRowDelete || onRowEdit) && (
          <TableCell align="right">
            {onRowEdit && (
              <Can abilities={actionAbilities}>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onRowEdit(rowContent);
                  }}
                >
                  <EditOutlined width="20" />
                </IconButton>
              </Can>
            )}

            {onRowDelete && (
              <Can abilities={actionAbilities}>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onRowDelete(rowContent);
                  }}
                >
                  <DeleteOutline width="20" height="20" />
                </IconButton>
              </Can>
            )}
          </TableCell>
        )}
      </MuiTableRow>
    </>
  );
};
