import React from "react";
import { useAuthToken } from "config/authProvider";
import { RouteComponentProps } from "@reach/router";

export const LougoutPage: React.FC<RouteComponentProps> = () => {
  const [, , removeAuthToken] = useAuthToken();

  React.useEffect(() => {
    removeAuthToken();
    window.location.href = "/connexion";
  }, [removeAuthToken]);

  return <></>;
};

export default LougoutPage;
