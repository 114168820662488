import React from "react";

import {
  CircularProgressProps,
  CircularProgress,
  Box,
  Grid,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";

import { useDelayNextChildren } from "utils/hooks";

import { MainLayout } from "ui/Layout/Main";

import {
  CircularProgressStyled,
  SpinnerWrapper,
  LinearProgressStyled,
  SimpleSpinnerContainer,
  SimpleSpinnerFullContainer,
} from "./styles";

import "styled-components/macro";
import { COLORS } from "constants/styles";

export const CustomSpinner = () => (
  <SpinnerWrapper>
    <CircularProgressStyled />
  </SpinnerWrapper>
);

const useStylesFacebook = makeStyles({
  root: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  top: {
    color: COLORS.gray5,
  },
  bottom: {
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
});

type SimpleSpinnerProps = {
  color?: string;
  isFullContainer?: boolean;
  as?: any;
} & CircularProgressProps;

export const SimpleSpinner = React.forwardRef((props: SimpleSpinnerProps, ref) => {
  const classes = useStylesFacebook({});

  const { color, isFullContainer = false, as, style, ...rest } = props;

  const SpinnerContainer = isFullContainer
    ? SimpleSpinnerFullContainer
    : SimpleSpinnerContainer;

  return (
    <SpinnerContainer as={as} style={style}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.top}
          size={props.size || 38}
          thickness={4.6}
          {...rest}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.bottom}
          style={{ color: color }}
          size={props.size || 38}
          thickness={4.6}
          {...rest}
        />
      </div>
    </SpinnerContainer>
  );
});

export function TableLoader({
  rowDepth = 3,
  cellDepth = 5,
}: {
  rowDepth?: number;
  cellDepth?: number;
}) {
  return (
    <TableBody>
      {Array.from(Array(rowDepth).keys()).map((i) => (
        <TableRow key={i}>
          {Array.from(Array(cellDepth).keys()).map((itd) => (
            <TableCell key={itd}>
              <Skeleton animation="pulse" variant="text" width="80%" height={30} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export function ProfilePageLoader(props: any) {
  return (
    <>
      <Grid container spacing={3} alignItems="center" justify="space-between">
        <Grid item sm={8} alignItems="center" container>
          <Skeleton animation="pulse" variant="circle" width={60} height={60} />
          <Box mr={[3, 2]} />
          <Skeleton animation="wave" width="50%" height={50} />
        </Grid>
        <Grid item container sm={4} alignItems="center" justify="flex-end">
          <Skeleton animation="wave" width="30%" height={50} />
        </Grid>
      </Grid>
      <Box
        css={`
          display: flex;
          align-items: center;
        `}
      >
        <Skeleton animation="wave" width="15%" height={40} />
        <Box mr={[3, 2]} />
        <Skeleton animation="wave" width="15%" height={40} />
        <Box mr={[3, 2]} />
        <Skeleton animation="wave" width="15%" height={40} />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Skeleton animation="wave" variant="rect" width="100%" height={260} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Skeleton animation="wave" variant="rect" width="100%" height={260} />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Skeleton animation="wave" variant="rect" width="100%" height={260} />
        </Grid>
      </Grid>
    </>
  );
}

export function ListItemTextLoader(props: any) {
  return (
    <>
      <Skeleton animation="wave" variant="text" height={45} width="65%" />
      <Skeleton animation="pulse" variant="text" height={25} width={200} />
      <Box mt={1} />
      <Skeleton animation="wave" variant="text" height={45} width="65%" />
      <Skeleton animation="pulse" variant="text" height={25} width={200} />
      <Box mt={1} />
      <Skeleton animation="wave" variant="text" height={45} width="55%" />
      <Skeleton animation="pulse" variant="text" height={25} width={200} />
      <Box mt={1} />
    </>
  );
}

export function SimpleSpinnerWrapper(props: any) {
  return (
    <SpinnerWrapper>
      <SimpleSpinner />
    </SpinnerWrapper>
  );
}

export const Pageloader = () => (
  <MainLayout>
    {/* <SpinnerWrapper>
      <SimpleSpinner />
    </SpinnerWrapper> */}
    <Grid container spacing={4} alignItems="center" justify="space-between">
      <Grid item sm={8} alignItems="center" container>
        <Skeleton animation="wave" width="100%" height="10vh" />
      </Grid>
    </Grid>

    <Grid container spacing={4} alignItems="center" justify="space-between">
      <Grid item xs={12} md={7}>
        <Skeleton animation="pulse" variant="rect" width="100%" height="25vh" />
      </Grid>
    </Grid>

    <Grid container spacing={4} alignItems="center" justify="space-between">
      <Grid item xs={12} md={7}>
        <Skeleton animation="pulse" variant="rect" width="100%" height="10vh" />
      </Grid>
    </Grid>

    <Grid container spacing={4} alignItems="center" justify="space-between">
      <Grid item xs={12} sm={8} alignItems="center" container>
        <Skeleton animation="pulse" width="100%" height="15vh" />
      </Grid>
    </Grid>
  </MainLayout>
);

export const LinearLoader = ({ delay = 0 }: any) =>
  useDelayNextChildren(
    <div style={{ margin: "1rem 0" }}>
      <LinearProgressStyled opacity={0.6} />
      <LinearProgressStyled opacity={0.4} />
      <LinearProgressStyled opacity={0.3} />
      <LinearProgressStyled opacity={0.2} />
    </div>,
    delay
  );

LinearLoader.defaultProps = {
  delay: 0,
};
