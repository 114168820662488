
import { ThemeOptions, createMuiTheme } from "@material-ui/core/styles";
import { lighten, darken } from "@material-ui/core/styles/colorManipulator";
import { APP_COLORS, COLORS } from "constants/styles";

export const muiTheme: ThemeOptions = {
  typography: {
    fontFamily: "inherit",
    h1: {
      fontSize: "2rem",
      fontWeight: 500,
      letterSpacing: "-0.06px",
      lineHeight: 1.3,
    },
    h2: {
      fontSize: "1.68rem",
      letterSpacing: "-0.05px",
    },
    h3: {
      fontSize: "1.4rem",
      letterSpacing: "-0.05px",
    },
    h4: {
      fontSize: "1.2rem",
      letterSpacing: "-0.05px",
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 500,

      lineHeight: "16px",
      letterSpacing: "-0.05px",
    },
    h6: {
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "-0.05px",
    },
    overline: {
      lineHeight: "13px",
    },
  },
  palette: {
    primary: {
      main: "#233266",
    },
    secondary: {
      main: "#1a224b",
    },
  },
  props: {
    MuiButtonGroup: {
      disableRipple: true,
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiPaper: {
      elevation: 0,
    },
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: "inherit",
        backgroundColor: "#fff",
      },
    },
    MuiTypography: {
      colorTextSecondary: { color: "var(--paps-internal-secondary-textColor)" },
    },
    MuiPaper: {
      root: {
        color: "inherit",
      },
      elevation1: {
        boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      },
    },
    MuiButton: {
      root: {
        fontSize: "15px",
        letterSpacing: "0.035rem",
        padding: "0 18px",
        textTransform: "none",
        color: "inherit",
        fontWeight: "normal",
        lineHeight: 1,
        height: "45px",
      },
      contained: {
        "&:hover": {
          boxShadow: "0 1px 4px rgba(0,0,0,0.18) !important",
        },
      },
      outlined: {
        padding: "0 18px",

        "&:hover": {
          boxShadow: "0 1px 4px rgba(0,0,0,0.08) !important",
        },
      },
      outlinedPrimary: {
        color: "#233266",
        border: "1px solid rgba(25, 118, 210, 0.8)",

        "&.Mui-focusVisible, &:focus": {
          boxShadow: `0 0 0 3px ${lighten(COLORS.blueFocus, 0.6)} !important`,
        },
      },
      containedPrimary: {
        backgroundColor: "#233266",

        "&:hover": {
          backgroundColor: "#115293",
        },
        "&.Mui-focusVisible, &:focus": {
          boxShadow: `0 0 0 3px ${lighten(COLORS.blueFocus, 0.6)} !important`,
        },
      },
      containedSecondary: {
        "&.Mui-focusVisible, &:focus": {
          boxShadow: `0 0 0 3px ${lighten("rgba(245, 0, 87, 1)", 0.6)} !important`,
        },
      },
      outlinedSecondary: {
        borderColor: "rgba(245, 0, 87, 0.3)",
        "&.Mui-focusVisible, &:focus": {
          boxShadow: `0 0 0 3px ${lighten("rgba(245, 0, 87, 1)", 0.6)} !important`,
        },
      },
      startIcon: {
        marginTop: "-1px",
      },
    },
    MuiCardHeader: {
      root: {
        padding: "20px 24px",
      },
    },
    MuiCardContent: {
      root: {
        padding: "24px",
      },
    },
    MuiCardActions: {
      root: {
        padding: "16px 24px",
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: "#fff",
        "&:nth-of-type(odd)": {
          backgroundColor: "#e1eaef",
        },
        cursor: "pointer",
        "& .MuiTableCell-root": {
          padding: 0,
        },
        "& .MuiTableCell-root:first-child": {
          paddingLeft: 20,
        },
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#fff",
        border: 0,
        color: APP_COLORS.primary,
      },
      body: {
        border: 0,
        color: "#616161",
        fontSize: 14,
      },
      root: {
        height: "45px",
        "&:first-child": {
          padding: 0,
          paddingLeft: "10px",
        },
        "& .MuiSkeleton-text": {
          borderRadius: "0px",
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        minWidth: "max-content",
      },
    },
    MuiTableFooter: {
      root: {
        display: "table-footer-group !important",
      },
    },
    MuiInputBase: {
      root: {
        color: "inherit",
        height: "45px",
      }
    },
    MuiTab: {
      root: {
        fontWeight: "normal",
        letterSpacing: "0.03rem",
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: "1.4rem",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#546e7a",
      },
    },

    MuiDialogTitle: {
      root: {
        borderBottom: "1px solid #d2cdcd",
        alignItems: "center",
        display: "flex",
        justifyContent: "space- between",
      },
    },
    MuiDialogActions: {
      root: {
        padding: "16px 24px",
        "& .MuiButtonBase-root": {
          minWidth: "120px",
          height: "45px",
          textTransform: "uppercase",
        },
      },
    },


    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.32)",
      },
    },
    MuiStepLabel: {
      label: {
        "&.MuiStepLabel-alternativeLabel": {
          marginTop: "8px",
          color: "inherit",
        },
        "&.MuiStepLabel-completed": {
          color: "inherit",
        },
      },
    },
    MuiSnackbar: {
      root: {
        "& .MuiAlert-root": {
          borderRadius: "7px",
          padding: "0 16px",
          minWidth: "382px",
        },
        "& .MuiAlertTitle-root": {
          margin: "0",
          fontSize: "16px",
          textAlign: "left",
        },
        "& .MuiAlert-message": {
          fontWeight: "normal",
          textAlign: "left",
        },
        "& .MuiAlert-icon": {
          display: "none",
        },
      },
    },
    MuiStepConnector: {
      active: {},
      completed: {
        "& $lineHorizontal": {
          borderColor: APP_COLORS.primary,
        },
      },
      lineHorizontal: {
        borderColor: "#dfdfdf",
        borderTopWidth: 2,
        borderRadius: 2,
      },
    },
  },
};

(muiTheme as any).overrides.MuiToggleButtonGroup = {
  groupedSizeSmall: { padding: "0px 14px 0px 14px" },
};

let primaryColor = "#233266";

(muiTheme as any).overrides.MuiToggleButton = {
  root: {
    textTransform: "none",
    color: darken(primaryColor, 0.3),
    border: `1px solid ${lighten(primaryColor, 0.7)} !important`,
    opacity: 0.6,

    "&:hover": {
      backgroundColor: lighten(primaryColor, 0.92),
    },
    "&.Mui-selected": {
      opacity: 1,
      color: darken(primaryColor, 0.3),
      backgroundColor: lighten(primaryColor, 0.83),
    },
    "&.Mui-selected&:hover": {
      backgroundColor: lighten(primaryColor, 0.85),
    },
  },
  sizeSmall: {
    height: "39px",
    fontSize: "0.92rem",
  },
};

export const MuiTheme = createMuiTheme(muiTheme);