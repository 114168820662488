import axios from "axios";
import React from "react";
import DeletionDialog from "ui/Dialog/Deletion";
import { InfoBarContext } from "contexts/InfoBarContext";

interface DeletionProps {
  current: any;
  open: boolean;
  onClose: () => void;

  path: string;

}

const Deletion = ({ open, onClose, current, path }: DeletionProps) => {
  const { setInfo } = React.useContext(InfoBarContext);

  const handleDelete = () => {
    if (current && current.id) {
      axios.delete(path + current.id)
        .then(res => {
          onClose()
          setInfo({
            type: "success",
            message: "Élément spprimé avec succès"
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }).catch(error => {
          if (error.response && error.response.status === 404)
            setInfo({
              type: "error",
              message: "Cet élement n'exixte pas"
            });
          else
            setInfo({
              type: "error",
              message: "Erreur lors de la suppression"
            });
        })
    }
  }

  return (
    <DeletionDialog
      title="Suppression"
      message={"Confirmer la suppression"}
      open={open}
      onClose={onClose}
      onConfirm={handleDelete}
    />
  )
}

export default Deletion;