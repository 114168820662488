import { Grid } from "@material-ui/core";
import React from "react";
import Dialog from "ui/Dialog";
import { TextField } from "ui/Form/TextField";
import axios from "axios";
import { InfoBarContext } from "contexts/InfoBarContext";

export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  family?: any;
  teacher?: string;
}

const defaultValues = {
  code: "",
  number_of_hours: "",
  start_time: "",
  end_time: "",
  start_date: "",
};
export const EditFamily: React.FC<EditFamilyProps> = ({
  open,
  onClose,
  family,
  teacher,
  ...props
}) => {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const { setInfo } = React.useContext(InfoBarContext);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = () => {
    setFormSubmited(true);

    if (
      !Boolean(formValues.code) ||
      !Boolean(formValues.start_time) ||
      !Boolean(formValues.end_time) ||
      !Boolean(formValues.start_date)
    )
      return;

    if (family) {
      // handleEdit({ ...data, role });
    } else {
      axios
        .post("/api/v1/tutoring/interventions/followups", {
          ...formValues,
          intervention_id: props["id"],
          teacher_id: teacher,
        })
        .then(async (res) => {
          setInfo({
            type: "success",
            message: "Suivi ajouter avec succès!",
          });
          handleClose();
          window.location.reload();
        })
        .catch((err) => {
          setInfo({
            type: "error",
            message: err.response.data,
          });
        });
    }
  };

  return (
    <Dialog
      title={"Ajouter un suivi"}
      confirmButtonTitle={"Ajouter"}
      cancelButtonTitle={"Annuler"}
      open={open}
      handleConfirm={onSubmit}
      handleClose={handleClose}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TextField
            required
            name="code"
            fullWidth
            label="Code de validation"
            type="text"
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.code)}
            helperText={
              isFormSubmited &&
              !Boolean(formValues.code) &&
              "Veuillez saisir le code de validation"
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            name="start_date"
            fullWidth
            label="Date d'intervention"
            type="date"
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.start_date)}
            helperText={
              isFormSubmited &&
              !Boolean(formValues.start_date) &&
              "Veuillez selectionner la date d'intervention"
            }
          />
        </Grid>

        {/* <Grid item xs={12}>
          <TextField
            required
            name="number_of_hours"
            fullWidth
            label="Nbre d'heures "
            type="number"
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.number_of_hours)}
            helperText={
              isFormSubmited &&
              !Boolean(formValues.number_of_hours) &&
              "Veuillez saisir le nombre d'heures"
            }
          />
        </Grid> */}

        <Grid item xs={12}>
          <TextField
            required
            name="start_time"
            fullWidth
            label="Heure de debut"
            type="time"
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.start_time)}
            helperText={
              isFormSubmited &&
              !Boolean(formValues.start_time) &&
              "Veuillez selectionner l'heure de debut"
            }
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            name="end_time"
            fullWidth
            label="Heure de fin"
            type="time"
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.end_time)}
            helperText={
              isFormSubmited &&
              !Boolean(formValues.end_time) &&
              "Veuillez selectionner l'heure de fin"
            }
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditFamily;
