import React from "react";
import { Column } from "ui/Table";
import { StudentLifeArticle } from '__types__';
import { formatDate } from "utils";
import StatusInfo from "ui/StatusInfo";

export const ARTICLE_STATUS_COLOR = {
  TO_VALIDATE: {
    bg: "#0D5593",
    text: "À Publier"
  },
  VALIDATE: {
    bg: "#098664",
    text: "Publié"
  }
}

export const DataRowsColumns: Column<StudentLifeArticle>[] = [
  {
    title: "Titre",
    field: "name",
  },
  {
    title: "Description",
    field: "description",
    render: ({ description }) => (
      <>
        {description.substr(0, 50)} ...
      </>
    ),
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo width="90px" background={ARTICLE_STATUS_COLOR[status]['bg']} status={ARTICLE_STATUS_COLOR[status]['text']} />
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "LL/dd/yyyy")}
      </>
    ),
  },
];