import React, { ReactNode } from "react";
import { Grid, makeStyles, Theme, createStyles, Radio } from "@material-ui/core";

interface IObjectContent {
  type: string;
  field: string;
}

interface ICardInfo {
  type?: "WithRadio";
  title: String;
  nameField: Array<String>;
  content: Array<String | IObjectContent | Date | ReactNode>;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleInfo: {
      display: "inline-block",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#233266",
      fontWeight: "bold",
      width: "100%",
    },
    radio: {
      display: "flex",
      flexDirection: "row",
      fontSize: "13px",
      marginTop: "5px",
      marginBottom: "10px",
    },
    hr: {
      backgroundColor: "#828282",
      height: "1px",
      opacity: 0.19,
      border: "none",
      width: "auto",
    },
    info: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "16px",
      marginTop: "5px",
      marginBottom: "10px",
    },
    nameField: {
      color: "#233266",
    },
    contentField: {
      color: "#707070",
    },
    l_type: {
      width: "30%",
      size: "14px",
      fontWeight: "bold",
      display: "inline-block",
      marginRight: "10px",
    },
    trained: {
      backgroundColor: "#27AE60",
      padding: 5,
      color: "white",
      borderRadius: 2,
    },
    not_trained: {
      backgroundColor: "#EB5757",
      padding: 5,
      color: "white",
      borderRadius: 2,
    },
    labelFile: {
      fontSize: "13px",
      color: "#233266",
    },
  })
);

const CardInfo: React.FC<ICardInfo> = ({ title, nameField, content, type }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.titleInfo}>
            {title}
            <hr className={classes.hr} />
          </div>
        </Grid>
        <Grid spacing={2} direction="column" item xs={12}>
          {nameField.length === content.length && type
            ? nameField?.map((key, index) => (
              <div className={classes.radio} key={index}>
                <Radio
                  checked
                  // checkedIcon={<SelectedIcon fontSize="inherit" />}
                  color="primary"
                  style={{
                    fontSize: "1.35rem",
                    marginTop: "-12px",
                  }}
                />
                <span className={classes.contentField}>{content[index]}</span>
              </div>
            ))
            : nameField.map((name, index) => (
              <div className={classes.info}>
                <span className={classes.nameField}>{name} :</span>
                <span className={classes.contentField}>{content[index]}</span>
              </div>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CardInfo;
