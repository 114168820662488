import React from "react";
import { Badge, Button, Checkbox, FormControl } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import FilterLabel from "ui/FilterLabel";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

interface FiltersOptionsProps {
  label: string;
  options: Array<any>;
  onChange?: (value: any) => void;
}

const FiltersOptions = ({ label, options, onChange }: FiltersOptionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filterOptions, setFilterOptions] = React.useState([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    onChange && onChange(filterOptions)

  }, [filterOptions])

  function handleFilter(filterOption: string) {
    if (filterOption) {
      setFilterOptions((prev) => {
        let x = [...prev];
        const i = x.indexOf(filterOption);
        if (i === -1) x.push(filterOption);
        else
          x = x.filter((v, id) => {
            return id !== i;
          });
        return x;
      });
    }
  }

  return (
    <>
      <Button
        style={{ marginRight: 10 }}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        endIcon={<FilterListIcon />}
        onClick={handleClick}
      >
        {label}
        {filterOptions.length !== 0 ? (
          <span style={{ marginLeft: 16 }}>
            <Badge color="primary" badgeContent={filterOptions.length} />
          </span>
        ) : (
            ""
          )}
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <FormControl
          onChange={(e: any) => handleFilter(e.target.value)}
          component="fieldset"
        >
          {
            options.map((option, key) =>
              <StyledMenuItem key={key}>
                <FilterLabel
                  value={option.id}
                  control={
                    <Checkbox
                      checked={filterOptions.indexOf(option.id.toString()) !== -1}
                      color="primary"
                    />
                  }
                  label={option.name}
                  labelPlacement="end"
                />

              </StyledMenuItem>
            )
          }
        </FormControl>

      </StyledMenu>
    </>
  )
}


export default FiltersOptions;