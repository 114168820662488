import React from "react";
import { RouteComponentProps } from "@reach/router";
import { MainLayout } from "ui/Layout";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = () => {
  return (
    <MainLayout>
      <h1>Bienvenue</h1>
    </MainLayout>
  );
};

export default Home;
