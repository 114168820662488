import React from "react";
import {
  List, ListItem, ListItemText, Divider,
  Typography,
  Drawer,
  Button
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import Table from "ui/Table";

import { useStyles } from 'constants/styles';
import { StudentLifeArticle } from '__types__';
import { InfoBarContext } from "contexts/InfoBarContext";
import StatusInfo from "ui/StatusInfo";
import axios from "axios";
import "styled-components/macro";
import { ARTICLE_STATUS_COLOR } from "pages/Studies/data";
import EditNew from "pages/EditArticle";
import { PageHeader } from "ui/Header";
import Paper from "ui/Paper";
import Deletion from "pages/Deletion";
import { DataSkillsRowsColumns } from "./data"
import EditSkill from "./EditSkill";

export interface DefaultPageProps {

  title: string;
  section: string;
  hasCategory?: boolean;

}

const actions = [
  { key: "add", label: "Ajouter", abilities: [] },
  { key: "validate", label: "Validé", abilities: ["ADMIN", "MANAGER"] },
];
export const Articles: React.FC<DefaultPageProps> = ({ title, section, hasCategory = false }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [data, setData] = React.useState<StudentLifeArticle[]>([]);
  const [current, setCurrent] = React.useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const { setInfo } = React.useContext(InfoBarContext);

  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    axios('/api/v1/tutoring/skills')
      .then(res => {
        setData(res.data)
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCurrent(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClickOpen = () => {
    setCurrent(null);
    setOpen(true);
  };


  function closeDrawer() {
    setIsDrawerOpen(false);
    setCurrent(null);
  }

  return (
    <>
      <Table
        rowsIdentifier="id"
        columns={DataSkillsRowsColumns}
        data={data}
        hasToolBar={false}
        onRowEdit={(row) => {
          setCurrent(row);
          setOpen(true);
        }}
      />

      <EditSkill open={open} onClose={handleClose} current={current} list={data} />
    </>
  );
};
export default Articles;