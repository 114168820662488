import { Checkbox, Grid } from "@material-ui/core";
import React from "react";
import Dialog from 'ui/Dialog';
import { TextField } from "ui/Form/TextField";
import axios from "axios";
import { InfoBarContext } from "contexts/InfoBarContext";
import Autocomplete from "ui/Autocomplete";
import { Level, Student, Teacher } from "__types__";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const frequences = [
  "1/semaine",
  "2/semaine",
  "3/semaine",
  "4/semaine",
  "5/semaine",
  "6/semaine",
  "7/semaine",
]

export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  family?: any;
  teacher: string
}

const defaultValues = {
  intervention_frequency: "",
  intervention_start_date: "",
  hours_affected: null as number,
  courses: [] as Level[],
  student: null as Student,
};
export const EditFamily: React.FC<EditFamilyProps> = ({ open, onClose, family, teacher }) => {
  const [courses, setCourses] = React.useState<Level[]>([]);
  const [students, setStudents] = React.useState<Student[]>([]);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);


  React.useEffect(() => {
    axios('/api/v1/families/students')
      .then(res => {
        setStudents(res.data)
      });

    axios('/api/v1/tutoring/courses')
      .then(res => {
        setCourses(res.data)
      });
  }, []);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );


  const { setInfo } = React.useContext(InfoBarContext);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = () => {
    setFormSubmited(true);

    if (
      !Boolean(formValues.courses.length)
      || !Boolean(formValues.student)
      || !Boolean(formValues.intervention_frequency)
      || !Boolean(formValues.intervention_start_date)
      || !Boolean(formValues.hours_affected)
    )
      return;

    if (family) {
      // handleEdit({ ...data, role });
    } else {
      axios.post("/api/v1/tutoring/interventions", {
        ...formValues,
        teacher: { id: teacher }
      }).then(async res => {
        setInfo({
          type: "success",
          message: "Intervention ajouter avec succès!"
        })
        handleClose();
        window.location.reload();
      })
    }
  };

  return (
    <Dialog
      title={"Ajouter une intervention"}
      confirmButtonTitle={"Ajouter"}
      cancelButtonTitle={"Annuler"}
      open={open}
      handleConfirm={onSubmit}
      handleClose={handleClose}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Autocomplete
            label="Elève"
            required
            options={students}
            getOptionLabel={(option: any) => option ? option?.family?.name + " " + option?.lastname : ""}
            onChange={(event: any, newValue: any) => {
              setFormValues({
                ...formValues,
                student: newValue,
              });
            }}
            value={formValues.student}
            error={isFormSubmited && !Boolean(formValues.student)}
            helperText={isFormSubmited && !Boolean(formValues.student) && "Veuillez selectionner l'élève"}
            getOptionSelected={(option: any, val: any) => option.id === val.id}
          />
        </Grid>

        <Grid item xs={12}>
          <MuiAutocomplete
            multiple
            options={courses}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            onChange={(event: any, newValue: any) => {
              setFormValues({
                ...formValues,
                courses: newValue,
              });
            }}
            value={formValues.courses}
            renderInput={(params) => (
              <TextField {...params}
                required
                variant="outlined"
                label="Matière(s)"
                error={isFormSubmited && !Boolean(formValues.courses.length)}
                helperText={isFormSubmited && !Boolean(formValues.courses.length) && "Veuillez selectionner les matieres"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            required
            label="Frequence d'intervention"
            options={frequences}
            getOptionLabel={(option: any) => option}
            onChange={(event: any, newValue: any) => {
              setFormValues({
                ...formValues,
                intervention_frequency: newValue,
              });
            }}
            error={isFormSubmited && !Boolean(formValues.intervention_frequency)}
            helperText={isFormSubmited && !Boolean(formValues.intervention_frequency) && "Veuillez selectionner la frequence"}
            value={formValues.intervention_frequency}
            getOptionSelected={(option: any, val: any) => option.id === val.id}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            name="intervention_start_date"
            fullWidth
            label="Date de debut"
            type="date"
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.intervention_start_date)}
            helperText={isFormSubmited && !Boolean(formValues.intervention_start_date) && "Veuillez selectionner la date de debut"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="hours_affected"
            fullWidth
            type="number"
            label="Nombre d'heures affecté"
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.hours_affected)}
            helperText={isFormSubmited && !Boolean(formValues.hours_affected) && "Veuillez saisir le nombre d'heures affecté'"}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditFamily;
