import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 700;

export const COLORS = {
  gray5: "#c4cdd5",
  blueFocus: "#1976D2",
  blueLight: "#0091FF",
  greenFocus: "#219653",
  greenLight: "#27AE60",
  redDanger: "#E53935",
};

export const APP_COLORS = {
  primary: "#233266",
  secondary: "#0ba094",
  success: "#1fcb6d",
  warning: "#fac666",
  error: "#ed7172",
  iconNeutral: "var(--studies-color-grayCool)",
  iconFocus: "var(--studies-color-secondaryText)",
  iconCurrent: "var(--studies-color-primary)",
  textPrimary: "var(--dark)",
  textSecondary: "var(--dark-800)",
  textLight: "var(--light)",
  textDangerDark: "var(--red-dark-700)",
  textGrayedIn: "var(--studies-color-textGrayedIn)",
  textGrayedOut: "var(--studies-color-textGrayedOut)",
  textDisabled: "var(--studies-color-textDisabled)",
};


export const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  breadcrumbs: {
    marginTop: 20,
  },
  title: {
    fontSize: 25,
    fontWeight: 600,
  },
  link: {
    display: "flex",
    fontSize: 16,
    textDecoration: "none",
    color: "#696969",
  },
  label: {
    color: "inherit",
    fontSize: "1rem"
  },
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  space: {
    marginTop: "50px",
  },
  coverImage: {
    width: "100%",
    maxHeight: "500px"
  },
  logoImage: {
    width: "200px",
    maxHeight: "200px"
  }
}));