import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import axios from "axios";
import { Box, Button, Grid, Radio, Tab, Tabs } from "@material-ui/core";
import Paper from "ui/Paper";
import { a11yProps } from "utils";
import { TabPanel } from "../Settings/Levels";
import { useStyles } from "pages/Tutoring/CardInfo";
import { SelectedIcon } from "ui/Custom/Icons";
import { OfferCandidatsColumns, JOB_OFFER_STATUS, CandidatsColumns } from "./data";
import StatusInfo from "ui/StatusInfo";
import OnTop from "./Edit/OnTop";
import Table from "ui/Table";
import Validate from "./Edit/Validate";
import Expire from "./Edit/Expire";
import Can from "shared/@pbac/Can";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = (props) => {
  const classes = useStyles();

  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openValidate, setOpenValidate] = React.useState(false);
  const [openExpire, setOpenExpire] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleValue = React.useCallback(
    (e: React.ChangeEvent<{}>, newValue: number) => {
      setValue((prevValue) => newValue);
    },
    [value, setValue]
  );

  React.useEffect(() => {
    axios('/api/v1/joboffers/' + props["id"])
      .then(res => {
        setData(res.data)
      })
  }, []);

  return (
    <>

      <PageHeader back title="Offres d'emploi">
        {
          data?.status === "VALIDATE" &&
          <Can abilities={["ADMIN", "MANAGER"]}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpen(true)
              }}
            >
              À la une
           </Button>
          </Can>
        }

        {
          data?.status === "TO_VALIDATE" &&
          <Can abilities={["ADMIN", "MANAGER"]}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenValidate(true)
              }}
            >
              Validé
          </Button>
          </Can>
        }

        {
          data?.status === "VALIDATE" &&
          <Can abilities={["ADMIN", "MANAGER"]}>
            <Button
              style={{ marginLeft: 10 }}
              color="primary"
              variant="contained"
              onClick={() => {
                setOpenExpire(true)
              }}
            >
              Date d'expiration
          </Button>
          </Can>
        }
      </PageHeader>

      <Paper>
        <Tabs
          onChange={handleValue}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Entreprise" {...a11yProps(1)} />
          <Tab label="Candidatures" {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid item xs={12} md={6}>
            {
              data &&
              <Box p={2}>
                <div className={classes.info}>
                  <span className={classes.nameField}>Titre  :</span>
                  <span className={classes.contentField}>{data?.title}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Statut  :</span>
                  <span className={classes.contentField}>
                    <StatusInfo width="90px" background={JOB_OFFER_STATUS[data?.status]['bg']} status={JOB_OFFER_STATUS[data?.status]['text']} />
                  </span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Date d'expiration :</span>
                  <span className={classes.contentField}>
                    {data?.expiration_date}
                  </span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>À la une :</span>
                  {
                    data &&
                    <Radio
                      checked={data?.ontop}
                      checkedIcon={<SelectedIcon fontSize="inherit" />}
                      color="primary"
                      style={{
                        fontSize: "1.35rem",
                        marginTop: "-12px",
                      }}
                      disabled
                    />
                  }
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Reference :</span>
                  <span className={classes.contentField}>{data?.reference}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Lieu du poste :</span>
                  <span className={classes.contentField}>{data?.address}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Type de contrat :</span>
                  <span className={classes.contentField}>{data?.contract_type}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Durée du contrat  :</span>
                  <span className={classes.contentField}>{data?.contract_length}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Remuneration :</span>
                  <span className={classes.contentField}>{data?.remuneration}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Niveau d'études :</span>
                  <span className={classes.contentField}>{data?.required_training}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Experience requise :</span>
                  <span className={classes.contentField}>{data?.required_experience}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Description du poste :</span>
                </div>
                <div>
                  <p className={classes.contentField}>{data?.description}</p>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Profil recherché :</span>
                </div>
                <div>
                  <p className={classes.contentField}>{data?.profile}</p>
                </div>
              </Box>
            }
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Grid item xs={12} md={6}>
            {
              data &&
              <Box p={2}>
                <div className={classes.info}>
                  <span className={classes.nameField}>Nom  :</span>
                  <span className={classes.contentField}>{data?.entreprise?.name}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Responsable  :</span>
                  <span className={classes.contentField}>{data?.entreprise?.owner_name}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Email :</span>
                  <span className={classes.contentField}>{data?.entreprise?.login_email}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Téléphone :</span>
                  <span className={classes.contentField}>{data?.entreprise?.phonenumber}</span>
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/emplois/entreprises/" + data?.entreprise.id)
                  }}
                >
                  Détails
                </Button>
              </Box>
            }
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Table
            rowsIdentifier="id"
            columns={OfferCandidatsColumns}
            data={data?.applications}
            onRowClick={(row) => {
              navigate("/emplois/candidats/" + row.candidat_id)
            }}
          />
        </TabPanel>
      </Paper>

      <OnTop open={open} onClose={() => setOpen(false)} article={data} />
      <Validate open={openValidate} onClose={() => setOpenValidate(false)} article={data} />
      <Expire open={openExpire} onClose={() => setOpenExpire(false)} article={data} />
    </>
  );
};

export default Home;
