import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { MainLayout } from "ui/Layout/Main";

import Ads from "./Ads";

const SheetPage: React.FC<RouteComponentProps> = () => {

  return (
    <MainLayout>
      <Router>
        <Ads path="/" noPageTitle />
      </Router>
    </MainLayout>
  );
};

export default SheetPage;