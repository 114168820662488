import React from "react";
import { RouteComponentProps } from "@reach/router";

import {
  List, ListItem, ListItemText, Divider,
  DialogContent, Typography,
  Drawer
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import { InlinedChip } from "ui/Custom";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from 'ui/Form/TextField';
import Paper from "ui/Paper";

import { Sheet } from '__types__';

import ImageUploader from 'react-images-upload';

import { formatDate } from "utils";

import { COLORS, useStyles } from 'constants/styles';
import { SectorDataRowsColumns } from './data';

import "styled-components/macro";
import { InfoBarContext } from "contexts/InfoBarContext";
import axios from "axios";
import ActionsGroup from "ui/ActionsGroup";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const defaultValues = {
  name: '',
}

const defaultValues1 = {
  name: '',
  description: ''
}

const actions = [
  { key: "add", label: "Ajouter", abilities: [] },
  { key: "validate", label: "Validé", abilities: ["ADMIN", "MANAGER"] },
];
export const Categories: React.FC<DefaultPageProps> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [data, setData] = React.useState<Sheet[]>([]);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [coverFormValues, setCoverFormValues] = React.useState(defaultValues1);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [picture, setPicture] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    fetch('/api/v1/articles/categories/orientation')
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
      .catch(console.log)
  }, []);

  const handleClickOpen = () => {
    setCurrent(null)
    setFormValues(defaultValues)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSave = () => {
    setFormSubmited(true);

    if (formValues.name.length === 0) return;

    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('cover_image', picture);

    if (current) {
      const requestOptions = {
        method: 'PUT',
        body: formData
      };

      fetch('/api/v1/articles/categories/' + current.id, requestOptions)
        .then(res => res.json())
        .then((res) => {
          setData(prev => {
            return prev.map(item => {
              if (item.id === res.id) {
                item = res
              }
              return item;
            })
          });
          setFormSubmited(false);
          setFormValues(defaultValues);
          setOpen(false);
          setInfo({
            type: "success",
            message: "Categorie modifier avec succès"
          });
          setCurrent(null);
          setPicture(null);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    } else {

      const requestOptions = {
        method: 'POST',
        body: formData
      };

      fetch('/api/v1/articles/categories/orientation/', requestOptions)
        .then(res => res.json())
        .then((res) => {
          setFormSubmited(false);
          setFormValues(defaultValues);
          setOpen(false);
          setInfo({
            type: "success",
            message: "Categorie ajouter avec succès"
          });
          setData([res, ...data]);
          setPicture(null);

        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  };

  const handleDelete = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    };
    fetch('/api/v1/orientation/categories/' + current.id, requestOptions)

      .then((res) => {
        setData(prev => prev.filter((item) => item.id !== current.id))
        setOpenDelete(false);
        setInfo({
          type: "success",
          message: "Categorie supprimer avec succès"
        });
        setCurrent(null);
      })
      .catch(error => {
        setInfo({
          type: "error",
          message: "Erreur"
        });
        console.error('There was an error!', error);
      });
  }

  function closeDrawer() {
    setIsDrawerOpen(false)
  }

  function onDrop(picture: any) {
    setPicture(picture[0])
  }

  const validate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments à valider"
      })

    axios.put('/api/v1/articles/categories/validate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élements validé avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "add":
        return handleClickOpen();
      case "validate":
        return validate();
    }
  };


  return (
    <>
      <PageHeader title={`Categories (${data.length})`}>
        <ActionsGroup onClick={handleAction} actions={actions} />
      </PageHeader>
      <Paper>
        <Table
          actionAbilities={["ADMIN", "MANAGER"]}
          hasSelection
          rowsIdentifier='id'
          columns={SectorDataRowsColumns}
          data={data}
          onRowClick={(row) => {
            setCurrent(row);
            setIsDrawerOpen(true);
          }}
          onRowDelete={(row) => {
            setCurrent(row);
            setOpenDelete(true);
          }}
          onRowEdit={(row) => {
            setCurrent(row);
            setFormValues({
              name: row.name
            })
            setOpen(true);
          }}
          onRowChecked={setSelectedRows}
        />
      </Paper>



      <Dialog
        title={Boolean(current) ? "Modifier " : "Nouvelle categorie"}
        confirmButtonTitle={Boolean(current) ? "Modifier" : "Ajouter"}
        open={open}
        handleConfirm={handleSave}
        handleClose={handleClose}>
        <DialogContent>
          <TextFieldGroup>
            <TextField
              label="Nom"
              name="name"
              value={formValues.name}
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              error={isFormSubmited && formValues.name.length === 0}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && formValues.name.length === 0
                  ? "Veuillez remplir le nom de la categorie"
                  : ""
              }
              required

            />

            <ImageUploader
              singleImage
              withIcon={true}
              withPreview={true}
              buttonText='Image de couverture'
              onChange={onDrop}
              imgExtension={['.jpg', '.gif', '.png', '.gif']}
              maxFileSize={5242880}
            />
          </TextFieldGroup>
        </DialogContent>
      </Dialog>

      <Dialog
        title="Suppression"
        confirmButtonTitle="Supprimer"
        open={openDelete}
        handleConfirm={handleDelete}
        handleClose={handleCloseDelete}>
        <DialogContent>
          Veuillez confirmer la suppression
        </DialogContent>
      </Dialog>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h3">
                  <b style={{ marginRight: 10 }}>{current?.name}</b>
                  <InlinedChip
                    label={current?.createdAt && formatDate(current?.createdAt, "LLL dd, yyyy · HH:mm")}
                    backgroundColor={COLORS['blueFocus']}
                    size="small"
                  />
                </Typography>
              }
            />

          </ListItem>

          <Divider />

          <ListItem>
            <img className={classes.coverImage} src={current?.cover_image || '/images/img_bg_1.jpg'} alt="" />
          </ListItem>

        </List>
      </Drawer>

    </>
  );
};

export default Categories;
