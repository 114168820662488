import { CircularProgress, InputLabel } from "@material-ui/core";
import React from "react";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from "ui/Form/TextField";
import { InfoBarContext } from "contexts/InfoBarContext";
import ImageUploader from 'react-images-upload';
import { useStyles } from "constants/styles";
import Autocomplete from "ui/Autocomplete";
import axios from "axios";
import { compressImage } from "utils";


export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  current?: any;
  page: string;
}

const PAGE = {
  home: { value: "home", label: "Accueil" },
  orientation_home: { value: "orientation_home", label: "Orientation" },
  student_life_home: { value: "student_life_home", label: "Vie étudiante" },
  studies_home: { value: "studies_home", label: "Études" },
  joboffer_home: { value: "joboffer_home", label: "Stage & emploi" },
  tutoring_home: { value: "tutoring_home", label: "Soutien scolaire" },
  sheet_home: { value: "sheet_home", label: "Fiches métiers" },
}

const PAGES = [
  { value: "home", label: "Accueil" },
  { value: "orientation_home", label: "Orientation" },
  { value: "student_life_home", label: "Vie étudiante" },
  { value: "studies_home", label: "Études" },
  { value: "joboffer_home", label: "Stage & emploi" },
  { value: "tutoring_home", label: "Soutien scolaire" },
  { value: "sheet_home", label: "Fiches métiers" }
]

interface IForm {
  name: string;
  action?: string;
  description?: string;
  link?: string;
  page: any;
  ads_end_date?: string;
  ads_start_date?: string;
}

const defaultValues: IForm = {
  name: '',
  action: '',
  description: '',
  link: '',
  page: { value: "home", label: "Accueil" },
  ads_end_date: null,
  ads_start_date: null,
}

export const EditFamily: React.FC<EditFamilyProps> = ({ open, onClose, current, page }) => {
  const classes = useStyles();

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [picture, setPicture] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (current) {
      setFormValues({
        name: current.name,
        action: current.action,
        link: current.link,
        description: current.description,
        ads_end_date: current.ads_end_date,
        ads_start_date: current.ads_start_date,
        page: PAGE[current.page]
      })
    }
  }, [current])

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  const { setInfo } = React.useContext(InfoBarContext);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleClose = () => {
    setFormValues(defaultValues);
    onClose();
  };

  const onSubmit = async () => {
    setFormSubmited(true);

    if (!Boolean(formValues.page)
      || !Boolean(formValues.ads_end_date) || !Boolean(formValues.ads_end_date)) return;

    setLoading(true);

    const formData = new FormData();

    formData.append("data", JSON.stringify({ ...formValues, page: formValues.page?.value }));

    if (picture) {
      formData.append('cover_image', await compressImage(picture));
    }

    if (current) {
      axios.put('/api/v1/page-ads/' + current.id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          setFormSubmited(false);
          onClose();
          setInfo({
            type: "success",
            message: "Banniere modifier avec succès"
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      axios.post('/api/v1/page-ads',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          setFormSubmited(false);
          onClose();
          setInfo({
            type: "success",
            message: "Banniere ajouter avec succès"
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  return (

    <Dialog
      title={Boolean(current) ? "Modifier " : "Ajouter"}
      confirmButtonTitle={loading ? <CircularProgress size={30} /> : (Boolean(current) ? "Modifier" : "Ajouter")}
      open={open}
      handleConfirm={onSubmit}
      handleClose={handleClose}
      disableConfirmButton={loading}
    >
      {/* <TextFieldGroup>
        <TextField
          label="Titre"
          name="name"
          value={formValues.name}
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.name.length === 0}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && formValues.name.length === 0
              ? "Veuillez remplir le titre"
              : ""
          }
          required

        />
      </TextFieldGroup> */}


      <TextFieldGroup>
        <Autocomplete
          label="Pages"
          options={PAGES}
          getOptionLabel={(option: any) => option.label}
          onChange={(event: any, newValue: any) => {
            setFormValues({
              ...formValues,
              page: newValue,
            });
          }}
          value={formValues.page}
          getOptionSelected={(option: any, val: any) => option.value === val.value}
        />
      </TextFieldGroup>

      <>
        <TextFieldGroup>
          <TextField
            label="Date de debut"
            name="ads_start_date"
            value={formValues.ads_start_date}
            margin="dense"
            type="date"
            fullWidth
            error={isFormSubmited && !Boolean(formValues.ads_start_date)}
            onChange={handleInputChange}
            helperText={
              isFormSubmited && !Boolean(formValues.ads_start_date)
                ? "Veuillez saisir la date de debut"
                : ""
            }
            required

          />
        </TextFieldGroup>

        <TextFieldGroup>
          <TextField
            label="Date de fin"
            name="ads_end_date"
            value={formValues.ads_end_date}
            margin="dense"
            type="date"
            fullWidth
            error={isFormSubmited && !Boolean(formValues.ads_end_date)}
            onChange={handleInputChange}
            helperText={
              isFormSubmited && !Boolean(formValues.ads_end_date)
                ? "Veuillez saisir la date de fin"
                : ""
            }
            required

          />
        </TextFieldGroup>
      </>


      {/* <TextFieldGroup>
        <InputLabel
          className={classes.label}
        >
          Description
        </InputLabel>
        <br />
        <textarea name="description" value={formValues.description} style={{ width: "100%" }} cols={30} rows={5}
          onChange={handleInputChange}
        >
        </textarea>
      </TextFieldGroup> */}

      <ImageUploader
        singleImage
        withIcon={true}
        withPreview={true}
        buttonText='Image de couverture'
        onChange={onDrop}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
      />

      {
        !picture && current && current.cover_image &&
        < img width="100%" src={current.cover_image} alt="" />
      }
    </Dialog>
  );
};

export default EditFamily;
