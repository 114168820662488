import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import React from "react";
import "styled-components/macro";
import { PageHeader } from "ui/Header";
import Paper from "ui/Paper";
import Table from "ui/Table";
import { JobOffer } from "__types__";
import { ApplicationsColumns, JobOfferColumns } from "./data";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}
export const Entreprises: React.FC<DefaultPageProps> = () => {
  const [data, setData] = React.useState<JobOffer[]>([]);

  React.useEffect(() => {
    axios("/api/v1/applications").then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <>
      <PageHeader title={`Candidatures(${data?.length})`} />
      <Paper>
        <Table rowsIdentifier="id" columns={ApplicationsColumns} data={data} />
      </Paper>
    </>
  );
};

export default Entreprises;
