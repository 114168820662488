import React from "react";
import MuiTextField, { TextFieldProps } from "@material-ui/core/TextField";
import {
  InputBase,
  InputProps,
  FormControl,
  InputLabel,
  FormHelperText,
  FormHelperTextProps
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { COLORS } from "constants/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    label: {
      color: "inherit",
      fontSize: "1.14rem"
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${COLORS.gray5}`,
      padding: "4px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),

      "label + &": {
        marginTop: theme.spacing(3)
      },

      "&:focus": {
        boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
        borderColor: theme.palette.primary.main
      }
    },
    inputFocus: {
      boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
      borderColor: theme.palette.primary.main
    },
    inputLabelMarginDense: {
      fontSize: "1.08rem"
    },
    inputBase: {
      padding: "6px 6px 4px"
    },
    inputBaseMarginDense: {
      paddingTop: "6px",
      paddingBottom: "4px",
      paddingLeft: 0
    },
    textfieldGroup: {
      marginBottom: theme.spacing(3),
    }
  })
);

export function TextFieldGroup(props: any) {
  const classes = useStyles({});
  return (
    <div className={classes.textfieldGroup}>
      {props.children}
    </div>
  )
}

export function TextField(props: TextFieldProps) {
  return (
    <MuiTextField
      {...props}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

export default TextField;