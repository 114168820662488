import { CircularProgress, InputLabel } from "@material-ui/core";
import React from "react";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from "ui/Form/TextField";
import { InfoBarContext } from "contexts/InfoBarContext";
import ImageUploader from 'react-images-upload';
import { useStyles } from "constants/styles";
import Autocomplete from "ui/Autocomplete";
import axios from "axios";
import { compressImage } from "utils";


export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  current?: any;
}

interface IForm {
  name: string;
  facebook: string;
  twitter: string;
}

const defaultValues: IForm = {
  name: '',
  facebook: '',
  twitter: '',
}

export const EditProf: React.FC<EditFamilyProps> = ({ open, onClose, current }) => {
  const classes = useStyles();

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [picture, setPicture] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (current) {
      setFormValues({
        name: current.name,
        facebook: current.facebook,
        twitter: current.twitter,
      })
    }
  }, [current])

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  const { setInfo } = React.useContext(InfoBarContext);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleClose = () => {
    setFormValues(defaultValues);
    onClose();
  };

  const onSubmit = async () => {
    setFormSubmited(true);

    if (!Boolean(formValues.name.trim())) return;

    setLoading(true);

    const formData = new FormData();

    formData.append("data", JSON.stringify({ ...formValues }));

    if (picture) {
      formData.append('image', await compressImage(picture));
    }

    if (current) {
      axios.put('/api/v1/tutoring/professors/' + current.id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          setFormSubmited(false);
          onClose();
          setInfo({
            type: "success",
            message: "Professeur modifier avec succès"
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      axios.post('/api/v1/tutoring/professors/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          setFormSubmited(false);
          onClose();
          setInfo({
            type: "success",
            message: "Professeur ajouter avec succès"
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  return (

    <Dialog
      title={Boolean(current) ? "Modifier " : "Ajouter"}
      confirmButtonTitle={loading ? <CircularProgress size={30} /> : (Boolean(current) ? "Modifier" : "Ajouter")}
      open={open}
      handleConfirm={onSubmit}
      handleClose={handleClose}
      disableConfirmButton={loading}
    >
      <TextFieldGroup>
        <TextField
          label="Nom & prénom"
          name="name"
          value={formValues.name}
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.name.length === 0}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && formValues.name.length === 0
              ? "Veuillez remplir le nom"
              : ""
          }
          required

        />
      </TextFieldGroup>


      <TextFieldGroup>
        <TextField
          label="Facebook"
          name="facebook"
          value={formValues.facebook}
          margin="dense"
          type="text"
          fullWidth
          onChange={handleInputChange}

        />
      </TextFieldGroup>

      <TextFieldGroup>
        <TextField
          label="Twitter"
          name="twitter"
          value={formValues.twitter}
          margin="dense"
          type="text"
          fullWidth
          onChange={handleInputChange}

        />
      </TextFieldGroup>

      <ImageUploader
        singleImage
        withIcon={true}
        withPreview={true}
        buttonText='Image de couverture'
        onChange={onDrop}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
      />

      {
        !picture && current && current.image &&
        < img width="100%" src={current.image} alt="" />
      }
    </Dialog>
  );
};

export default EditProf;
