import { Grid } from "@material-ui/core";
import React from "react";
import Dialog from 'ui/Dialog';
import { TextField } from "ui/Form/TextField";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoBarContext } from "contexts/InfoBarContext";
import Autocomplete from "ui/Autocomplete";
import { Level } from "__types__";


export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  family?: any;
}

const schema = yup.object().shape({
  name: yup.string()
    .required("Veuillez saisir le nom"),
  in_charge: yup.string()
    .required("Veuillez saisir le responsable"),
  email: yup.string()
    .email("Veuillez saisir une adresse email valide.")
    .required("Veuillez saisir l'adresse email."),
  phonenumber: yup.string()
    .required("Veuillez saisir le telephone")
});

const defaultValues = {
  lastname: "",
  level: null as Level,
  classe: null as Level,
}


export const EditFamilyStudent: React.FC<EditFamilyProps> = ({ open, onClose, family, ...props }) => {

  const { register, handleSubmit, errors, reset, control, formState, setValue } = useForm({ resolver: yupResolver(schema) });

  const [cities, setCities] = React.useState<string[]>([]);
  const [city, setCity] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);

  const [levels, setLevels] = React.useState<Level[]>([]);
  const [classes, setClasses] = React.useState<Level[]>([]);

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    axios('/api/v1/tutoring/levels')
      .then(res => {
        setLevels(res.data)
      });
    axios('/api/v1/tutoring/classes')
      .then(res => {
        setClasses(res.data)
      });
  }, []);

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = () => {
    setFormSubmited(true);

    if (!Boolean(formValues.lastname) || !Boolean(formValues.level) || !Boolean(formValues.classe))
      return;

    // if (family) {
    //   // handleEdit({ ...data, role });
    // } else {
    axios.post("/api/v1/families/student", {
      lastname: formValues.lastname,
      level_id: formValues.level.id,
      classe_id: formValues.classe.id,
      family_id: family
    }).then(async res => {
      setInfo({
        type: "success",
        message: "Enfant ajouter avec succès!"
      });
      handleClose();
      window.location.reload();
    })
    // }
  };



  return (

    <Dialog
      title={"Ajouter un enfant"}
      confirmButtonTitle={"Ajouter"}
      cancelButtonTitle={"Annuler"}
      open={open}
      handleConfirm={onSubmit}
      handleClose={handleClose}
    >
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <TextField
            required
            name="lastname"
            fullWidth
            label="Prénom"
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.lastname)}
            helperText={isFormSubmited && !Boolean(formValues.lastname) && "Veuillez saisir le prenom"}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            required
            label="Nievau"
            options={levels}
            getOptionLabel={(option: any) => option.name}
            onChange={(event: any, newValue: any) => {
              setFormValues({
                ...formValues,
                "level": newValue,
              });
            }}
            error={isFormSubmited && !Boolean(formValues.level)}
            helperText={isFormSubmited && !Boolean(formValues.level) && "Veuillez selectionner le niveau"}
            value={formValues.level}
            getOptionSelected={(option: any, val: any) => option.id === val.id}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            required
            label="Classe"
            options={classes}
            getOptionLabel={(option: any) => option.name}
            onChange={(event: any, newValue: any) => {
              setFormValues({
                ...formValues,
                "classe": newValue,
              });
            }}
            error={isFormSubmited && !Boolean(formValues.classe)}
            helperText={isFormSubmited && !Boolean(formValues.classe) && "Veuillez selectionner la classe"}
            value={formValues.classe}
            getOptionSelected={(option: any, val: any) => option.id === val.id}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditFamilyStudent;
