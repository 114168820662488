import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import axios from "axios";
import Paper from "ui/Paper";
import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "../Settings/Levels";
import { a11yProps } from "utils";
import { useStyles } from "pages/Tutoring/CardInfo";

const SCHOOL_TYPE = {
  elementary_primary: "Elementaire",
  secondary: "Secondaire",
  high: "Supérieur"
}

const SCHOOL_STATUS = {
  private: "Privé",
  public: "Public"
}

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = (props) => {
  const classes = useStyles();

  const [data, setData] = React.useState(null);

  const [value, setValue] = React.useState(0);
  const handleValue = React.useCallback(
    (e: React.ChangeEvent<{}>, newValue: number) => {
      setValue((prevValue) => newValue);
    },
    [value, setValue]
  );


  React.useEffect(() => {
    axios('/api/v1/studies/' + props["id"])
      .then(res => {
        setData(res.data)
      });
  }, []);

  return (
    <>
      <PageHeader back title="Écoles" />

      <Paper>
        <Tabs
          onChange={handleValue}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Details" {...a11yProps(0)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box p={2}>
                <Grid item xs={12}>
                  <div className={classes.titleInfo}>
                    Information
                    <hr className={classes.hr} />
                  </div>
                </Grid>
                {
                  data?.logo_image &&
                  <div className={classes.info}>
                    <img src={data?.logo_image} alt="" width="200" height="200" />
                  </div>
                }

                <div className={classes.info}>
                  <span className={classes.nameField}>Nom :</span>
                  <span className={classes.contentField}>{data?.name}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Type d'école :</span>
                  <span className={classes.contentField}>{SCHOOL_TYPE[data?.school_type]}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Statut :</span>
                  <span className={classes.contentField}>{SCHOOL_STATUS[data?.school_status]}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Email :</span>
                  <span className={classes.contentField}>{data?.email}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Tel :</span>
                  <span className={classes.contentField}>{data?.phonenumber}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Ville :</span>
                  <span className={classes.contentField}>{data?.city}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Adresse :</span>
                  <span className={classes.contentField}>{data?.district}</span>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={2}>
                <Grid item xs={12}>
                  <div className={classes.titleInfo}>
                    Type d'enseignement
                    <hr className={classes.hr} />
                  </div>
                </Grid>
                <div className={classes.info}>
                  <span className={classes.contentField}>{data?.education_sectors}</span>
                </div>
              </Box>
              <Box p={2}>
                <Grid item xs={12}>
                  <div className={classes.titleInfo}>
                    Niveau d'études
                    <hr className={classes.hr} />
                  </div>
                </Grid>
                <div className={classes.info}>
                  <span className={classes.contentField}>{data?.education_levels}</span>
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box p={2}>
                <Grid item xs={12}>
                  <div className={classes.titleInfo}>
                    Présentation
                    <hr className={classes.hr} />
                  </div>
                </Grid>
                <div className={classes.info}>
                  <div dangerouslySetInnerHTML={{ __html: data?.presentation }} />
                </div>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </Paper>
    </>
  );
};

export default Home;
