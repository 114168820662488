import styled from "styled-components";
export const EmptyTableContent = styled("h3")<{ color?: string }>`
  padding: 3rem 1rem;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${(p) => p.color || "#959595"};

  .highlighted {
    font-weight: 600;
    color: initial;
  }
`;
