import React from "react";

import {
  InputBase,
  InputProps,
  FormControl,
  InputLabel,
  FormHelperText,
  FormHelperTextProps
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { COLORS } from "constants/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    label: {
      color: "inherit",
      fontSize: "1.14rem"
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${COLORS.gray5}`,
      padding: "4px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),

      "label + &": {
        marginTop: theme.spacing(3)
      },

      "&:focus": {
        boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
        borderColor: theme.palette.primary.main
      }
    },
    inputFocus: {
      boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
      borderColor: theme.palette.primary.main
    },
    inputLabelMarginDense: {
      fontSize: "1.08rem"
    },
    inputBase: {
      padding: "6px 6px 4px"
    },
    inputBaseMarginDense: {
      paddingTop: "6px",
      paddingBottom: "4px",
      paddingLeft: 0
    }
  })
);

export interface CustomTextFieldProps extends InputProps {
  name: string;
  label?: string;
  size?: "small" | "medium";
  FormHelperTextProps?: FormHelperTextProps;
  helperText?: React.ReactNode;
  empty?: boolean;
}

export function CustomTextField({
  label,
  className,
  FormHelperTextProps,
  helperText,
  size,
  color,
  ...props
}: CustomTextFieldProps) {
  const classes = useStyles({});

  const helperTextId =
    helperText && props.id ? `${props.id}-helper-text` : undefined;

  return (
    <FormControl
      className={className}
      disabled={props.disabled}
      error={props.error}
      fullWidth={props.fullWidth}
      size={size}
      color={color}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={props.id ?? props.name}
          className={classes.label}
          error={props.error}
          classes={{ marginDense: classes.inputLabelMarginDense }}
        >
          {label} {props.required && <span>*</span>}
        </InputLabel>
      )}
      <InputBase
        classes={{
          root: classes.input,
          focused: classes.inputFocus,
          inputMarginDense: classes.inputBaseMarginDense
        }}
        id={props.id ?? props.name}
        {...props}
      />
      {helperText && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
