import { Grid } from "@material-ui/core";
import React from "react";
import Dialog from 'ui/Dialog';
import { TextField } from "ui/Form/TextField";
import axios from "axios";
import { InfoBarContext } from "contexts/InfoBarContext";
import Autocomplete from "ui/Autocomplete";
export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  current?: any;
}

const LEVEL = {
  secondary: {
    name: "secondary",
    label: "Secondaire",
  },
  high: {
    name: "high",
    label: "Supérieur",
  }
}

const levels = [
  {
    name: "secondary",
    label: "Secondaire",
  },
  {
    name: "high",
    label: "Supérieur",
  },
]

interface IP {
  name?: string;
  level?: any
}




const defaultValues: IP = {
  name: '',
  level: { name: "high", label: "Supérieur" },
}

export const EditEducationType: React.FC<EditFamilyProps> = ({ open, onClose, current }) => {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );


  const { setInfo } = React.useContext(InfoBarContext);

  React.useEffect(() => {
    if (current) {
      setFormValues({
        name: current.name,
        level: LEVEL[current.level],
      });
    }
  }, [current]);

  const handleClose = () => {
    onClose();
    setFormValues(defaultValues);
  };

  const onSubmit = () => {
    setFormSubmited(true);

    if (!Boolean(formValues.name.trim()) || !Boolean(formValues.level))
      return;

    if (current) {
      axios.put("/api/v1/settings/education-type/" + current.id, {
        name: formValues.name,
        level: formValues.level.name
      }).then(async res => {
        setFormSubmited(false);
        setInfo({
          type: "success",
          message: "Type d'enseignement modifier avec succès!"
        })
        handleClose();
        window.location.reload();
      })
    } else {
      axios.post("/api/v1/settings/education-type", {
        name: formValues.name,
        level: formValues.level.name
      }).then(async res => {
        setFormSubmited(false);
        setInfo({
          type: "success",
          message: "Type d'enseignement ajouter avec succès!"
        })
        handleClose();
        window.location.reload();
      })
    }
  };

  return (
    <Dialog
      title={Boolean(current) ? "Modifier " : "Ajouter"}
      confirmButtonTitle={Boolean(current) ? "Modifier" : "Ajouter"}
      cancelButtonTitle={"Annuler"}
      open={open}
      handleConfirm={onSubmit}
      handleClose={handleClose}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Autocomplete
            required
            label="Niveau"
            options={levels}
            getOptionLabel={(option: any) => option.label}
            onChange={(event: any, newValue: any) => {
              setFormValues({
                ...formValues,
                level: newValue,
              });
            }}
            error={isFormSubmited && !Boolean(formValues.level)}
            helperText={isFormSubmited && !Boolean(formValues.level) && "Veuillez selectionner le niveau"}
            value={formValues.level}
            getOptionSelected={(option: any, val: any) => option.name === val.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            name="name"
            fullWidth
            label="Type d'enseignement"
            value={formValues.name}
            onChange={handleInputChange}
            error={isFormSubmited && !Boolean(formValues.level)}
            helperText={isFormSubmited && !Boolean(formValues.level) && "Veuillez saisir le type d'enseignement"}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditEducationType;
