import React, { useState } from "react";
import { Link, navigate } from "@reach/router";

import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Avatar,
  Popover,
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import HamburgerIcon from "@material-ui/icons/Dehaze";

import { CustomIconButton } from "../Header/styles";

import "styled-components/macro";
import { deepOrange } from "@material-ui/core/colors";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useLogout } from "hooks/useLogout";

const useStyles = makeStyles((theme) =>
  createStyles({
    MuiList: {
      padding: 0,
      width: "440px",
      maxWidth: "440px",
    },
    large: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      fontSize: 14,
      backgroundColor: deepOrange[500],
    },
  })
);

const AppBar: React.FC<{ toggleDrawerOpen(): void }> = (
  props
) => {
  const theme = useTheme();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [user] = useCurrentUser();
  const logout = useLogout();

  const [anchorEl, setAnchorEl] = useState(null);
  const [
    anchorElUser,
    setAnchorElUser,
  ] = React.useState<HTMLButtonElement | null>(null);

  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUser = () => {
    setAnchorElUser(null);
  };

  const open = Boolean(anchorElUser);
  const id = open ? "popover-user" : undefined;

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  return (
    <MuiAppBar
      color="inherit"
      elevation={0}
      position="fixed"
      css={`
        max-height: 64px;
      `}
    >
      <Toolbar
        css={`
        padding-left: 0;
      `}
      >
        {isSmallScreen && (
          <CustomIconButton
            color="inherit"
            arial-label="Menu"
            onClick={props.toggleDrawerOpen}
            size="medium"
          >
            <HamburgerIcon fontSize="inherit" />
          </CustomIconButton>
        )}

        <div
          css={`
            display: flex;
            align-items: center;
            width: 240px;
            height: 64px;
            background-color: #233266;
            justify-content: center;
          `}
        >
          <Link
            to={`/`}
            css={`
              height: 36px;
              margin-right: 10px;
            `}
          >
            <img src="/logo-studies.png" alt="" width="100" />
          </Link>
        </div>
        <div
          css={`
            /* display: none; */
            align-items: center;
            margin-left: auto;
          `}
        >
          <div
            css={`
              margin-left: 1rem;
            `}
          >
            <IconButton onClick={handleClickUser}>
              {user && (
                <Avatar
                  alt={`${user.firstname} ${user.lastname}`}
                />
              )}
            </IconButton>

            {user && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorElUser}
                onClose={handleCloseUser}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box style={{ padding: theme.spacing(1) }}>
                  <List
                    dense
                    component="nav"
                    subheader={
                      <ListSubheader>{`${user?.firstname} ${user?.lastname}`}</ListSubheader>
                    }
                  >
                    <ListItem button onClick={() => window.location.href = "/profil"} >
                      <ListItemText primary="Profil" />
                    </ListItem>
                    {/* <ListItem button>
                      <ListItemText primary="Settings" />
                    </ListItem> */}
                  </List>
                  {/* <Divider /> */}
                  <List dense component="nav">
                    <ListItem button onClick={() => window.location.href = "/logout"}>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </Box>
              </Popover>
            )}
          </div>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;