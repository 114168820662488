import React from "react";
import {
  InputLabel,
} from "@material-ui/core";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from 'ui/Form/TextField';

import { useStyles } from 'constants/styles';
import { InfoBarContext } from "contexts/InfoBarContext";

const defaultValues = {
  name: '',
  description: '',
  subtitle: "",
}

interface EditArticleProps {
  open: boolean;
  onClose: () => void;
  article?: any;
}
export const EditArticle: React.FC<EditArticleProps> = ({ open, onClose, article }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const [picture, setPicture] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);

  React.useEffect(() => {
    if (article) {
      setCurrent(article);
      setFormValues({
        name: article.name,
        description: article.description,
        subtitle: article.subtitle,
      });
    }
  }, [article]);


  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleClose = () => {
    setCurrent(null);
    setFormValues(defaultValues);
    onClose();
  };

  const handleSave = () => {
    setFormSubmited(true);

    if (formValues.name.trim().length === 0) return;

    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('subtitle', formValues.subtitle);
    formData.append('description', formValues.description);
    formData.append('page', "family");

    const requestOptions = {
      method: 'POST',
      body: formData
    };

    fetch('/api/v1/tutoring/page-text', requestOptions)
      .then(res => res.json())
      .then((res) => {
        setFormSubmited(false);
        setFormValues(defaultValues);
        onClose();
        setInfo({
          type: "success",
          message: "Bloc modifier avec succès"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(error => {
      });
  };

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  return (
    <Dialog
      title={"Bloc famille page d'accueil"}
      confirmButtonTitle={"Modifier"}
      open={open}
      handleConfirm={handleSave}
      handleClose={handleClose}>
      <TextFieldGroup>
        <TextField
          label="Titre"
          name="name"
          value={formValues.name}
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.name.length === 0}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && formValues.name.length === 0
              ? "Veuillez saisir le titre"
              : ""
          }
          required

        />
      </TextFieldGroup>


      <TextFieldGroup>
        <TextField
          label="Sous-titre"
          name="subtitle"
          value={formValues.subtitle}
          margin="dense"
          type="text"
          fullWidth
          onChange={handleInputChange}

        />
      </TextFieldGroup>

      <TextFieldGroup>
        <InputLabel
          className={classes.label}
        >
          Description
        </InputLabel>
        <br />
        <textarea name="description" value={formValues.description} style={{ width: "100%" }} cols={30} rows={5}
          onChange={handleInputChange}
        >
        </textarea>
      </TextFieldGroup>
    </Dialog>
  );
};
export default EditArticle;