import React from "react";
import { Paper as MuiPaper, PaperProps } from "@material-ui/core";


const Paper: React.FC<PaperProps> = ({ children }) =>
  <MuiPaper
    style={{
      color: "inherit",
      height: "74vh",
      overflowX: "scroll",
    }}
  >
    {children}
  </MuiPaper>

export default Paper;