import React from "react";
import { RouteComponentProps } from "@reach/router";
import Table from "ui/Table";
import axios from "axios";
import { Followup } from '__types__';
import Accordion from "@material-ui/core/Accordion";

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Divider, Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import { InfoBarContext } from "contexts/InfoBarContext";
import { CoverDataRowsColumns } from "pages/Tutoring/data";
import EditPageCover from "./EditCover";
import { useStyles } from 'constants/styles';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      backgroundColor: "#f1f1f1",
      display: "block"
    }
  }),
);

interface CoversProps {
  page: string;
  title: string;
  expanded?: boolean;
}

const Covers = ({ title, page, expanded = false }: CoversProps) => {
  const [data, setData] = React.useState<Followup[]>([]);
  const classes = useStyles();
  const classes1 = useStyles1();
  const [openCover, setOpenCover] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);


  const { setInfo } = React.useContext(InfoBarContext);

  React.useEffect(() => {
    axios('/api/v1/page-covers?page=' + page)
      .then(res => {
        setData(res.data)
      })
  }, []);

  const deleteBanner = (id: string) => {
    axios.delete('/api/v1/page-covers/' + id)
      .then(res => {
        setInfo({
          type: "success",
          message: "Banniere supprimer avec succès"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
  }

  function closeDrawer() {
    setIsDrawerOpen(false);
    setCurrent(null);
  }

  const handleClose = () => {
    setOpenCover(false);
    setCurrent(null);
  };

  return (
    <>
      <Accordion
        {...((expanded) && { expanded: expanded })}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes1.heading}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes1.content}>
          <div>
            <p>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setOpenCover(true)
                }}
              >
                Ajouter
              </Button>
            </p>
          </div>
          <Table
            actionAbilities={["ADMIN", "MANAGER"]}
            hasToolBar={false}
            rowsIdentifier='id'
            columns={CoverDataRowsColumns}
            data={data}
            onRowClick={(row) => {
              setCurrent(row);
              setIsDrawerOpen(true);
            }}
            onRowDelete={(row) => {
              deleteBanner(row.id);
            }}
            onRowEdit={(row) => {
              setCurrent(row);
              setOpenCover(true);
            }}
          />
        </AccordionDetails>
      </Accordion>


      <EditPageCover current={current} open={openCover} onClose={handleClose} page={page} />

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h3">
                  <b style={{ marginRight: 10 }}>{current?.name}</b>
                </Typography>
              }
            />

          </ListItem>

          <Divider />

          <ListItem>
            <img className={classes.coverImage} src={current?.cover_image} alt="" />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <Typography>
                  {current?.description}
                </Typography>
              }
            />
          </ListItem>

          <Divider />
        </List>
      </Drawer>
    </>
  );
};

export default Covers;
