import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "@reach/router";

import {
  List,
  Collapse,
  Drawer,
  ListItem,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles, useTheme, Theme } from "@material-ui/core/styles";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

import "styled-components/macro";
import { APP_COLORS } from "constants/styles";

import { NavLinkNested } from "./styles";
import MENU from "config/menu";
import useCurrentRoute from "hooks/useCurrentRoute";

export const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      top: "64px",
      width: DRAWER_WIDTH,
      height: "calc(100% - 64px)",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      top: "64px",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      height: "calc(100% - 64px)",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    listItem: {
      padding: "10px 0",
      display: "block",
      color: "#fff"
    },
    listItemLink: {
      padding: "0 8px",
      letterSpacing: 0,
      justifyContent: "flex-start",
      width: "100%",
      fontWeight: 500,
      height: 50,
    },
    listItemCu: {
      backgroundColor: "#0ba094",
    },
    nested: {
      marginTop: 5,
      padding: 0,
      color: "#fff",
      "& .MuiButtonBase-root": {
        borderRadius: 0,
        paddingLeft: "32px",
      },
    },
    expendedbg: {
      borderRadius: 30,
      color: "#fff",
      marginLeft: "auto",
    },
  })
);

const ButtonLink = (props: any) => <Button component={Link} {...props} />;

interface LeftDrawerProps {
  isDrawerOpen: boolean;
  isMobileDrawerOpen: boolean;
  toggleLeftDrawerState: () => void;
  toggleMobileDrawerState: () => void;
}

export function Menu(props: LeftDrawerProps) {
  const [isCollapsed, setIsCollapsed] = useState("");
  const classes = useStyles({});
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { currentRoute } = useCurrentRoute();

  const toggleItemCollapse = (name: string) => {
    if (isCollapsed.includes(name)) {
      setIsCollapsed("");
    } else {
      setIsCollapsed(name);
    }
  };

  const drawerContent = (
    <nav
      css={`
        padding: 25px 8px 16px 8px;
        overflow: auto;
        flex-grow: 1;
        background-color: #233266;
      `}
    >
      <List>
        {
          MENU.map((menu, key) => {
            const isCurrent = currentRoute.includes(menu.url);
            const GroupIcon = menu.icon;

            return (
              <div key={key}>
                {!menu.menus && (
                  <ListItem component="li"
                    className={classes.listItem}
                  >
                    <ButtonLink
                      to={menu.url}
                      startIcon={
                        <GroupIcon
                          color={isCurrent ? "#3bccf6" : "#fff"}
                        />
                      }
                      getProps={({ isCurrent }: any) => ({
                        style: {
                          color: isCurrent ? APP_COLORS.primary : "inherit",
                        },
                      })}
                      className={clsx(classes.listItemLink, {
                        [classes.listItemCu]: isCurrent,
                      })}
                      style={{ color: isCurrent ? "#3bccf6" : "#fff" }}
                    >
                      {menu.label}
                    </ButtonLink>
                  </ListItem>
                )}

                {menu.menus && (
                  <ListItem
                    component="li"
                    className={classes.listItem}
                    style={{
                      backgroundColor: currentRoute.includes(menu.url)
                        ? "#131939"
                        : "",
                      padding: "0",
                    }}
                  >
                    <Button
                      onClick={() => toggleItemCollapse(menu.label)}
                      className={clsx(classes.listItemLink)}
                      startIcon={
                        <GroupIcon
                          color={currentRoute.includes(menu.url) ? "#3bccf6" : "#fff"}
                        />
                      }
                    // style={{
                    //   borderLeft: currentRoute.includes(menu.url)
                    //     ? "5px solid #3bccf6"
                    //     : "5px solid #1a224b",
                    //   borderRadius: 0,
                    //   // padding: `5px 5px 5px ${!open ? "0" : "15px"}`,
                    //   // padding: `5px 5px 5px 15px`,
                    //   height: "56px",
                    // }}
                    // style={{
                    //   backgroundColor: currentRoute.includes(menu.url)
                    //     ? "#0ba094"
                    //     : ""
                    // }}
                    >
                      {menu.label}
                      {
                        isCollapsed.includes(menu.label) ? (
                          <ExpandLess className={classes.expendedbg} />
                        ) : (
                            <ExpandMore className={classes.expendedbg} />
                          )
                      }
                    </Button>

                    <Collapse
                      in={isCollapsed.includes(menu.label)}
                      timeout="auto"
                      unmountOnExit
                    >
                      {menu.menus.map((item: any, key: number) => {
                        const isCurrent2 = currentRoute.includes(
                          menu.url + "/" + item.url
                        );
                        return (
                          <List key={key} disablePadding>
                            <ListItem button className={classes.nested}>
                              <NavLinkNested
                                to={`${menu.url}/${item.url}`}
                                getProps={({ isCurrent }: any) => ({
                                  style: {
                                    fontWeight: isCurrent2 ? 500 : "inherit",
                                    color: isCurrent2 ? "#fff" : "inherit",
                                    background: isCurrent2 ? "#0ba094" : "inherit",
                                  },
                                })}
                              >
                                <span>{item.label}</span>
                              </NavLinkNested>
                            </ListItem>
                          </List>
                        );
                      })}
                    </Collapse>
                  </ListItem>
                )}
              </div>
            )
          })
        }
      </List>
    </nav>
  );

  return (
    <>
      {isSmallScreen ? (
        <Drawer
          variant="temporary"
          classes={{
            paper: classes.drawer,
          }}
          open={props.isMobileDrawerOpen}
          anchor={theme.direction === "rtl" ? "right" : "left"}
          onClose={props.toggleMobileDrawerState}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: props.isDrawerOpen,
              [classes.drawerClose]: !props.isDrawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: props.isDrawerOpen,
                [classes.drawerClose]: !props.isDrawerOpen,
              }),
            }}
          >
            {drawerContent}
          </Drawer>
        )}
    </>
  );
}
