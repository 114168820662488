import React from "react";
import { Button } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

export default function Back({ onBack = () => { } }: { onBack?: () => void }) {
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onBack}
      style={{ minWidth: 30, height: 40, width: 40, marginRight: 5 }}
    >
      <ArrowBackIosRoundedIcon />
    </Button>
  );
}
