import React from "react";
import axios from "axios";
import { InfoBarContext } from "contexts/InfoBarContext";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, InputLabel } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import { TextField, TextFieldGroup } from "ui/Form/TextField";
import Editor from "./Editor";
import ImageUploader from 'react-images-upload';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      backgroundColor: "#f1f1f1",
      display: "block"
    },
    label: {
      color: "inherit",
      fontSize: "1rem"
    },
  }),
);

interface PageProps {
  title: string;
  page: string;


}
const defaultValues = {
  name: '',
  description: '',
  presentation: ''
}

const PAGE = {
  teacher: 1,
  family: 2,
  teacher_signup: 3,
  family_signup: 4,
  request: 5,
  teacher_page: 6,
  family_page: 7,
  course_particular_page: 8,
  course_intensif_page: 9,
  course_online_page: 10,

  joboffers_candidat: 11,
  joboffers_entreprise: 12,
  joboffers_home: 13,

  studies_home: 14,
  about_studies: 15,
}

const Page = ({ title, page }: PageProps) => {
  const classes = useStyles();

  const [formValues, setFormValues] = React.useState(defaultValues);
  // const [page, setPage] = React.useState(null);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [coverImage, setCoverImage] = React.useState(null);
  const [picture, setPicture] = React.useState(null);
  const { setInfo } = React.useContext(InfoBarContext);


  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    axios('/api/v1/tutoring/page-text/' + PAGE[page])
      .then(res => {
        // setPage(res.data)
        setFormValues({
          name: res.data.name,
          description: res.data.description,
          presentation: res.data.presentation,
        });
        setCoverImage(res.data?.cover_image)

      })
  }, []);

  const handleSave = () => {
    setFormSubmited(true);

    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('description', formValues.description);
    formData.append('presentation', formValues.presentation);
    formData.append('cover_image', picture);
    formData.append('page', page);

    const requestOptions = {
      method: 'POST',
      body: formData
    };

    fetch('/api/v1/page-text', requestOptions)
      .then(res => res.json())
      .then((res) => {
        setInfo({
          type: "success",
          message: "Page modifier avec succès"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(error => {
      });
  };

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.content}>
        <div>
          <p style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
            >
              Modifier
          </Button>
          </p>
        </div>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <TextField
              label="Titre"
              name="name"
              value={formValues.name}
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              error={isFormSubmited && formValues.name.length === 0}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && formValues.name.length === 0
                  ? "Veuillez saisir le titre"
                  : ""
              }
              required

            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldGroup>
              <InputLabel
                className={classes.label}
              >
                Description
        </InputLabel>
              <br />
              <textarea name="description" value={formValues.description} style={{ width: "100%" }} cols={30} rows={5}
                onChange={handleInputChange}
              >
              </textarea>
            </TextFieldGroup>
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              className={classes.label}
            >
              Présentation
      </InputLabel>
            <br />
            <Editor
              value={formValues.presentation}
              onChange={(content) => {
                setFormValues({
                  ...formValues,
                  presentation: content
                });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ImageUploader
              singleImage
              withIcon={true}
              withPreview={true}
              buttonText='Image de couverture'
              onChange={onDrop}
              imgExtension={['.jpg', '.gif', '.png', '.gif']}
              maxFileSize={5242880}
            />

            {
              !picture && coverImage &&
              <img width="100%" height="300" src={coverImage} alt="" />
            }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default Page;