import React from "react";

import { Column } from "ui/Table";
import {
  Family,
  Teacher,
  Teaching,
  Request,
  Level,
  Course,
  Student,
  Intervention,
  Followup,
} from "__types__";
import { formatDate } from "utils";
import StatusInfo from "ui/StatusInfo";
import "styled-components/macro";

export const ROLE_COLOR = {
  TO_VALIDATE: {
    bg: "#0D5593",
    text: "À Traiter",
  },
  VALIDATE: {
    bg: "#098664",
    text: "Traité",
  },
};

export const COUPON_COLOR = {
  UNUSED: {
    bg: "#0D5593",
    text: "Valide",
  },
  USED: {
    bg: "#098664",
    text: "Utilisé",
  },
};

export const FOLW_COLOR = {
  TO_VALIDATE: {
    bg: "#0D5593",
    text: "À Valider",
  },
  VALIDATE: {
    bg: "#098664",
    text: "Valider",
  },
};

export const ACCOUNT_STATUS_COLOR = {
  false: {
    bg: "#414f5a",
    text: "Non vérifié",
  },
  true: {
    bg: "#08a179",
    text: "Verifié",
  },
};

export const INTRV_STATUS_COLOR = {
  TO_VALIDATE: {
    bg: "#0D5593",
    text: "À Valider",
  },
  VALIDATE: {
    bg: "#17a2b8",
    text: "Validée",
  },
  START: {
    bg: "#098664",
    text: "En cours",
  },
  CANCEL: {
    bg: "#dc3545",
    text: "Suspendu",
  },
  CONTENTIOUS: {
    bg: "#098664",
    text: "Litigieux",
  },
  ABORT: {
    bg: "#098664",
    text: "Abandon",
  },
  END: {
    bg: "#098664",
    text: "Terminer",
  },
};

export const FamilyColumns: Column<any>[] = [
  {
    title: "Code",
    field: "code",
    render: ({ code }) => <b>{code}</b>,
  },
  {
    title: "Nom",
    field: "name",
  },
  {
    title: "Responsable",
    field: "in_charge",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Téléphone",
    field: "phonenumber",
  },
  {
    title: "Ville",
    field: "city",
  },
  {
    title: "Compte",
    field: "activate",
    render: ({ activate }) => (
      <>
        <StatusInfo
          width="80px"
          background={ACCOUNT_STATUS_COLOR[activate.toString()]["bg"]}
          status={ACCOUNT_STATUS_COLOR[activate.toString()]["text"]}
        />
      </>
    ),
  },
  {
    title: "Statut",
    field: "account_status",
    render: ({ account_status }) => (
      <>
        <span
          css={`
            width: 10px;
            height: 10px;
            background-color: ${account_status ? "green" : "gray"};
            display: inline-block;
            border-radius: 30px;
            margin-right: 5px;
          `}
        ></span>
        {account_status ? "Activer" : "Désactiver"}
      </>
    ),
  },
  {
    title: "Date d'interscription",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const FamilyStudentColumns: Column<Student>[] = [
  {
    title: "Prénom",
    field: "lastname",
  },
  {
    title: "Niveau",
    field: "level",
    render: ({ level }) => level?.name,
  },
  {
    title: "Classe",
    field: "classe",
    render: ({ classe }) => classe?.name,
  },
];

export const FindTeacherColumns: Column<Teacher>[] = [
  {
    title: "Code",
    field: "code",
    render: ({ code }) => <b>{code}</b>,
  },
  {
    title: "Nom & Prénom",
    field: "name",
    render: ({ firstname, lastname }) => <>{firstname + " " + lastname}</>,
  },
  {
    title: "Téléphone",
    field: "phonenumber",
  },
  {
    title: "Email",
    field: "email",
  },
];

export const TeacherColumns: Column<any>[] = [
  {
    title: "Code",
    field: "code",
    render: ({ code }) => <b>{code}</b>,
  },
  {
    title: "Civilité",
    field: "civility",
  },
  {
    title: "Nom & Prénom",
    field: "name",
    render: ({ firstname, lastname }) => <>{firstname + " " + lastname}</>,
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Téléphone",
    field: "phonenumber",
  },
  {
    title: "Ville",
    field: "city",
  },
  {
    title: "Quartier",
    field: "district",
  },
  {
    title: "Compte",
    field: "activate",
    render: ({ activate }) => (
      <>
        <StatusInfo
          width="80px"
          background={ACCOUNT_STATUS_COLOR[activate.toString()]["bg"]}
          status={ACCOUNT_STATUS_COLOR[activate.toString()]["text"]}
        />
      </>
    ),
  },
  {
    title: "Statut",
    field: "account_status",
    render: ({ account_status }) => (
      <>
        <span
          css={`
            width: 10px;
            height: 10px;
            background-color: ${account_status ? "green" : "gray"};
            display: inline-block;
            border-radius: 30px;
            margin-right: 5px;
          `}
        ></span>
        {account_status ? "Activer" : "Désactiver"}
      </>
    ),
  },
  {
    title: "Date d'interscription",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const RequestColumns: Column<Request>[] = [
  {
    title: "Civilité",
    field: "requester_civility",
  },
  {
    title: "Nom",
    field: "requester_firstname",
  },
  {
    title: "Prénom",
    field: "requester_lastname",
  },
  {
    title: "Email",
    field: "requester_email",
  },
  {
    title: "Téléphone",
    field: "requester_phonenumber",
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <StatusInfo
        width="90px"
        background={ROLE_COLOR[status]["bg"]}
        status={ROLE_COLOR[status]["text"]}
      />
    ),
  },
  {
    title: "Date",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const RequestItemColumns: Column<Request>[] = [
  {
    title: "Niveau",
    field: "level",
  },
  {
    title: "Matiere",
    field: "course",
  },
  {
    title: "Classe",
    field: "classe",
  },
];

export const LevelColumns: Column<Level>[] = [
  {
    title: "Niveau",
    field: "name",
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => <>{formatDate(createdAt, "dd/LL/yyyy")}</>,
  },
];

export const CourseColumns: Column<Course>[] = [
  {
    title: "Matiere",
    field: "name",
  },
  {
    title: "Niveau",
    field: "level",
    render: ({ level }) => <>{level.name}</>,
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => <>{formatDate(createdAt, "dd/LL/yyyy")}</>,
  },
];

export const ClasseColumns: Column<Course>[] = [
  {
    title: "Classe",
    field: "name",
  },
  {
    title: "Niveau",
    field: "name",
    render: ({ level }) => <>{level.name}</>,
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => <>{formatDate(createdAt, "dd/LL/yyyy")}</>,
  },
];

export const TeacherTeachingsColumns: Column<Teaching>[] = [
  {
    title: "Niveau",
    field: "level",
    render: ({ level }) => <>{level.name}</>,
  },
  {
    title: "Matiere",
    field: "course",
    render: ({ course }) => <>{course.name}</>,
  },
];

export const TeacherInterventionColumns: Column<Intervention>[] = [
  {
    title: "Code",
    field: "name",
    render: ({ code }) => <b>{code}</b>,
  },
  {
    title: "Elève",
    field: "name",
    render: ({ student }) => <>{student.lastname}</>,
  },
  {
    title: "Matière(s)",
    field: "courses",
    render: ({ courses }) => (
      <>
        {courses.map(
          (st, i) => st.course.name + (i + 1 !== courses.length ? ", " : "")
        )}
      </>
    ),
  },
  {
    title: "Frequence d'intervention",
    field: "frequency",
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo
          width="90px"
          background={INTRV_STATUS_COLOR[status]["bg"]}
          status={INTRV_STATUS_COLOR[status]["text"]}
        />
      </>
    ),
  },
  {
    title: "Date de début",
    field: "start_date",
  },
];

export const InterventionColumns: Column<Intervention>[] = [
  {
    title: "Code",
    field: "code",
    render: ({ code }) => <b>{code}</b>,
  },
  {
    title: "Enseignant",
    field: "teacher",
    render: ({ teacher }) => teacher?.firstname,
  },
  {
    title: "Elève",
    field: "name",
    render: ({ student }) => student?.lastname,
  },
  {
    title: "Matière(s)",
    field: "courses",
    render: ({ courses }) => (
      <>
        {courses.map(
          (st, i) => st.course.name + (i + 1 !== courses.length ? ", " : "")
        )}
      </>
    ),
  },
  {
    title: "Frequence d'intervention",
    field: "frequency",
  },
  {
    title: "Nbre d'heures affecté",
    field: "hours_affected",
  },
  {
    title: "Nbre d'heures effectuées",
    field: "number_of_hours",
    render: ({ number_of_hours }) => <>{number_of_hours || 0}</>,
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo
          width="80px"
          background={INTRV_STATUS_COLOR[status]["bg"]}
          status={INTRV_STATUS_COLOR[status]["text"]}
        />
      </>
    ),
  },
  {
    title: "Date de début",
    field: "start_date",
  },
];

export const TeacherFollowupColumns: Column<Followup>[] = [
  {
    title: "Date d'intervention",
    field: "start_date",
  },
  {
    title: "Nbr d'heures",
    field: "number_of_hours",
  },
  {
    title: "Heure de debut",
    field: "start_time",
  },
  {
    title: "Heure de fin",
    field: "end_time",
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const InterventionFollowupColumns: Column<Intervention>[] = [
  {
    title: "Date d'intervention",
    field: "start_date",
  },
  {
    title: "Nbr d'heures",
    field: "number_of_hours",
  },
  {
    title: "Heure de debut",
    field: "start_time",
  },
  {
    title: "Heure de fin",
    field: "end_time",
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const CouponsColumns: Column<Intervention>[] = [
  {
    title: "Code",
    field: "code",
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <StatusInfo
        width="90px"
        background={COUPON_COLOR[status]["bg"]}
        status={COUPON_COLOR[status]["text"]}
      />
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const FollowupColumns: Column<Followup>[] = [
  {
    title: "Intervention",
    field: "intervention",
    render: ({ intervention }) => intervention?.code,
  },
  {
    title: "Date d'intervention",
    field: "start_date",
  },
  {
    title: "Enseignant",
    field: "intervention",
    render: ({ intervention }) => intervention?.teacher?.firstname,
  },
  {
    title: "Elève",
    field: "intervention",
    render: ({ intervention }) => <>{intervention?.student?.lastname}</>,
  },
  {
    title: "Matière(s)",
    field: "intervention",
    render: ({ intervention }) => (
      <>
        {intervention?.courses.map(
          (st, i) =>
            st.course.name +
            (i + 1 !== intervention?.courses.length ? ", " : "")
        )}
      </>
    ),
  },
  {
    title: "Nbr d'heures",
    field: "number_of_hours",
  },
  // {
  //   title: "Statut",
  //   field: "status",
  //   render: ({ status }) => (
  //     <StatusInfo width="90px" background={FOLW_COLOR[status]['bg']} status={FOLW_COLOR[status]['text']} />
  //   ),
  // },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

// -- Cover

const COVER_TYPE = {
  image: "Image",
  ads: "Publicité",
};

export const CoverDataRowsColumns: Column<any>[] = [
  {
    title: "Titre",
    field: "name",
  },
  {
    title: "Type",
    field: "type",
    render: ({ type }) => <>{COVER_TYPE[type]}</>,
  },
  {
    title: "Date de début",
    field: "ads_start__date",
    render: ({ ads_start_date, type }) => (
      <>{type === "ads" ? formatDate(ads_start_date, "dd/LL/yyyy") : "-"}</>
    ),
  },
  {
    title: "Date de fin",
    field: "ads_end_date",
    render: ({ ads_end_date, type }) => (
      <>{type === "ads" ? formatDate(ads_end_date, "dd/LL/yyyy") : "-"}</>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => <>{formatDate(createdAt, "dd/LL/yyyy")}</>,
  },
];

export const DataSectionRowsColumns: Column<any>[] = [
  {
    title: "Titre",
    field: "name",
  },
  {
    title: "Description",
    field: "description",
    render: ({ description }) => <>{description.substr(0, 50)} ...</>,
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => <>{formatDate(createdAt, "dd/LL/yyyy")}</>,
  },
];

export const DataRefRowsColumns: Column<any>[] = [
  {
    title: "Référence",
    field: "ref",
  },
  {
    title: "Titre",
    field: "name",
  },
  {
    title: "Valeur",
    field: "value",
  },
];

export const DataSkillsRowsColumns: Column<any>[] = [
  {
    title: "Compétence",
    field: "ref",
  },
  {
    title: "Titre",
    field: "value",
  },
];

export const DataProfRowsColumns: Column<any>[] = [
  {
    title: "Nom",
    field: "name",
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => <>{formatDate(createdAt, "dd/LL/yyyy")}</>,
  },
];
