import React from "react";

import { Column } from "ui/Table";
import { Sheet, Sector } from '__types__';
import { formatDate } from "utils";
import { ARTICLE_STATUS_COLOR } from "pages/StudentLife/data";
import StatusInfo from "ui/StatusInfo";

export const SectorDataRowsColumns: Column<Sector>[] = [
  {
    title: "Nom",
    field: "name",
  },
  {
    title: "Nb. fiches métiers",
    field: "count",
    render: ({ sheets }) => (
      <>
        {sheets?.length || 0}
      </>
    ),
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo width="90px" background={ARTICLE_STATUS_COLOR[status]['bg']} status={ARTICLE_STATUS_COLOR[status]['text']} />
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
]
export const SheetDataRowsColumns: Column<Sheet>[] = [
  {
    title: "Nom",
    field: "name",
    render: ({ name }) => (
      <>
        {name.length <= 50 ? name : name.substr(0, 50) + "..."}
      </>
    ),
  },
  {
    title: "Description",
    field: "description",
    render: ({ description }) => (
      <>
        {description.length <= 50 ? description : description.substr(0, 50) + "..."}
      </>
    ),
  },

  {
    title: "Secteur",
    field: "sector",
    render: ({ sector }) => (
      <>
        {sector?.name}
      </>
    ),
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo width="90px" background={ARTICLE_STATUS_COLOR[status]['bg']} status={ARTICLE_STATUS_COLOR[status]['text']} />
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
]