import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { MainLayout } from "ui/Layout/Main";

import { Articles } from "./Articles";
import { Categories } from "./Categories";
import Presentation from "./Presentation";
import EditArticle from "pages/EditArticle";

const OrientationPage: React.FC<RouteComponentProps> = () => {

  return (
    <MainLayout>
      <Router>
        <Articles path="/articles" noPageTitle />
        <EditArticle path="/articles/ajouter" section="orientation" hasCategory />
        <EditArticle path="/articles/editer" section="orientation" hasCategory />
        <Categories path="/categories" noPageTitle />
        <Presentation path="/presentation" noPageTitle />
      </Router>
    </MainLayout>
  );
};

export default OrientationPage;