import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import {
  CouponsColumns,
  InterventionFollowupColumns,
  INTRV_STATUS_COLOR,
} from "./data";
import { Button, Tab, Tabs, Grid, Box } from "@material-ui/core";
import EditFollowup from "./EditFollowup";
import Paper from "ui/Paper";
import { a11yProps } from "utils";
import { TabPanel } from "../Settings/Levels";
import { useStyles } from "./CardInfo";
import StatusInfo from "ui/StatusInfo";
import { InfoBarContext } from "contexts/InfoBarContext";
import Can from "shared/@pbac/Can";
import { Alert } from "@material-ui/lab";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = (props) => {
  const classes = useStyles();

  const [data, setData] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const { setInfo } = React.useContext(InfoBarContext);

  const handleValue = React.useCallback(
    (e: React.ChangeEvent<{}>, newValue: number) => {
      setValue((prevValue) => newValue);
    },
    [value, setValue]
  );

  React.useEffect(() => {
    axios("/api/v1/tutoring/interventions/" + props["id"]).then((res) => {
      setData(res.data);
    });
  }, []);

  const validate = () => {
    axios
      .put("/api/v1/tutoring/interventions/validate/" + data?.id)
      .then(async (res) => {
        setInfo({
          type: "success",
          message: "Intervention validé avec succès!",
        });
        window.location.reload();
      });
  };

  const start = () => {
    axios
      .put("/api/v1/tutoring/interventions/start/" + data?.id)
      .then(async (res) => {
        setInfo({
          type: "success",
          message: "Intervention debuter",
        });
        window.location.reload();
      });
  };

  let sum = 0;

  for (let i = 0; i < data?.followups.length; i++) {
    sum += data?.followups[i]["number_of_hours"];
  }

  const handleGenerateCoupons = () => {
    axios
      .post(`/api/v1/tutoring/interventions/${data?.id}/generate-coupons`)
      .then(async (res) => {
        setInfo({
          type: "success",
          message: "Coupons générer avec succes",
        });
        window.location.reload();
      });
  };

  if (!data) return null;

  return (
    <>
      <PageHeader back title={"Interventions : " + data?.code}>
        {data?.status === "START" && (
          <Can abilities={["ADMIN", "MANAGER"]}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setOpen(true);
              }}
            >
              Ajouter un suivi
            </Button>
          </Can>
        )}

        {data?.status === "TO_VALIDATE" && (
          <Can abilities={["ADMIN", "MANAGER"]}>
            <Button color="primary" variant="contained" onClick={validate}>
              Validé
            </Button>
          </Can>
        )}

        {data?.status === "VALIDATE" && (
          <Can abilities={["ADMIN", "MANAGER"]}>
            <Button color="primary" variant="contained" onClick={start}>
              Debuter
            </Button>
          </Can>
        )}
      </PageHeader>

      <Paper>
        {data &&
          data?.status === "START" &&
          sum >= (data.hours_affected * 3) / 4 && (
            <Alert severity="warning">Alerte consomation</Alert>
          )}
        <Tabs
          onChange={handleValue}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Suivis" {...a11yProps(1)} />
          <Tab label="Coupons de validation" {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid item xs={12} md={5}>
            <Box p={2}>
              <div className={classes.info}>
                <span className={classes.nameField}>Enseignant :</span>
                <span className={classes.contentField}>
                  {data?.teacher.firstname}
                </span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>Elève :</span>
                <span className={classes.contentField}>
                  {data?.student.lastname}
                </span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>Matiere(s) :</span>
                <span className={classes.contentField}>
                  {data?.courses.map(
                    (st: any, i: any) =>
                      st.course.name +
                      (i + 1 !== data?.courses.length ? ", " : "")
                  )}
                </span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>
                  Fréquence d'intervention :
                </span>
                <span className={classes.contentField}>{data?.frequency}</span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>
                  Nombre d'heures affecté :
                </span>
                <span className={classes.contentField}>
                  {data?.hours_affected}
                </span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>
                  Nombre d'heures effectuées :
                </span>
                <span className={classes.contentField}>
                  {sum}
                  {/* {data?.followups?.reduce((a: any, b: any) => a?.number_of_hours + b?.number_of_hours, {})} */}
                </span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>Statut :</span>
                <span className={classes.contentField}>
                  <StatusInfo
                    width="80px"
                    background={INTRV_STATUS_COLOR[data?.status]["bg"]}
                    status={INTRV_STATUS_COLOR[data?.status]["text"]}
                  />
                </span>
              </div>
            </Box>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Table
            rowsIdentifier="id"
            columns={InterventionFollowupColumns}
            data={data?.followups || []}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Table
            rowsIdentifier="id"
            columns={CouponsColumns}
            data={data?.coupons || []}
            actions={
              <div>
                {!data?.coupons?.length && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleGenerateCoupons}
                  >
                    Générer les coupons
                  </Button>
                )}
              </div>
            }
          />
        </TabPanel>
      </Paper>

      <EditFollowup
        {...props}
        teacher={data?.teacher?.id}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default Home;
