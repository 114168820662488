import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Paper from "ui/Paper";
import Covers from "pages/Covers";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = () => {
  return (
    <>
      <PageHeader title="Présentation">
      </PageHeader>

      <Paper style={{ padding: "50px" }}>
        <Covers expanded title="Banniere" page="orientation_home" />
      </Paper>
    </>
  );
};

export default Home;
