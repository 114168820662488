import * as React from "react";
import { InboxOutlined, Settings } from "@material-ui/icons";
import { useLocation } from "utils/hooks";

export const topLevelPathNames = {
  DASHBOARD: "/",
  MESSAGE: "/messages",
  COVER: "/couverture",
  SHEETS: "/fiches-metier/*",
  STUDENT_LIFE: "/vie-etudiante/*",
  ORIENTATION: "/orientation/*",
  STUDIES: "/etudes/*",
  SETTINGS: "/reglages/*",
  SETTINGS_1: "/parametrages/*",
  JOBS: "/emplois/*",
  TUTORING: "/soutien-scolaire/*",
  NOT_FOUND: "/404",
};

export const PathsGroup = {
  Paremètres: { icon: Settings, wildcardPathName: "/parametres/*" },
  Message: { icon: InboxOutlined, wildcardPathName: "/messages/*" },
  Couverture: { icon: InboxOutlined, wildcardPathName: "/couverture/*" },
  "Fiches Métiers": {
    icon: InboxOutlined,
    wildcardPathName: "/fiches-metier/*",
  },
  "Vie Etudiante": {
    icon: InboxOutlined,
    wildcardPathName: "/vie-etudiante/*",
  },
  Etudes: { icon: InboxOutlined, wildcardPathName: "/etudes/*" },
  Orientation: { icon: InboxOutlined, wildcardPathName: "/orientation/*" },
  "Stage et emploi": { icon: InboxOutlined, wildcardPathName: "/emlpois/*" },
};

export interface IAllRoutes {
  id: keyof typeof topLevelPathNames | string;
  relativeUrl: string;
  label?: string;
  url: string;
  fromGroup?: keyof typeof PathsGroup;
  isTopLevel?: boolean;
  disabled?: boolean;
  notListed?: boolean;
  order?: number;
  customRender?: boolean;
  hidden?: boolean;
}

export const AllRoutes: IAllRoutes[] = [
  {
    id: "MESSAGE",
    url: "/messages",
    relativeUrl: "/",
    label: "Messages",
    order: 1,
    fromGroup: "Message",
    isTopLevel: true,
  },
  {
    id: "SEETINGS",
    url: "/parametres/utilisateurs",
    relativeUrl: "utilisateurs",
    label: "Utilisateurs",
    order: 1,
    fromGroup: "Paremètres",
  },
  {
    id: "SEETINGS_2",
    url: "/parametres/historique",
    relativeUrl: "historique",
    label: "Historique",
    order: 1,
    fromGroup: "Paremètres",
  },
  {
    id: "SEETINGS_3",
    url: "/parametres/informations",
    relativeUrl: "informations",
    label: "Informations",
    order: 1,
    fromGroup: "Paremètres",
  },
  {
    id: "SHEETS",
    url: "/fiches-metier",
    relativeUrl: "/",
    label: "Fiches Métiers",
    order: 1,
    fromGroup: "Fiches Métiers",
  },
  {
    id: "SHEETS_SECTOR",
    url: "/fiches-metier/secteurs",
    relativeUrl: "secteurs",
    label: "Secteurs",
    order: 2,
    fromGroup: "Fiches Métiers",
  },
  {
    id: "STUDENT_LIFE",
    url: "/vie-etudiante",
    relativeUrl: "/",
    label: "Vie Etudiante",
    order: 2,
    fromGroup: "Vie Etudiante",
  },
  {
    id: "ORIENTATION",
    url: "/orientation",
    relativeUrl: "/",
    label: "Articles",
    order: 2,
    fromGroup: "Orientation",
  },
  {
    id: "ORIENTATION_CATEGORIE",
    url: "/orientation/categories",
    relativeUrl: "categories",
    label: "Categories ",
    order: 2,
    fromGroup: "Orientation",
  },
  {
    id: "STUDIES",
    url: "/etudes",
    relativeUrl: "",
    label: "Etudes",
    order: 2,
    fromGroup: "Etudes",
  },
  {
    id: "STUDIES_INFOS",
    url: "/etudes/informations",
    relativeUrl: "",
    label: "Demandes d'informations",
    order: 2,
    fromGroup: "Etudes",
  },
  {
    id: "STUDIES_CITIES",
    url: "/etudes/villes",
    relativeUrl: "",
    label: "Villes",
    order: 2,
    fromGroup: "Etudes",
  },
  {
    id: "STUDIES_CITIES",
    url: "/couverture",
    relativeUrl: "",
    label: "Couverture Accueil",
    order: 2,
    fromGroup: "Couverture",
  },
  {
    id: "Em1",
    url: "/emplois/candidats",
    relativeUrl: "candidats",
    label: "Particuliers",
    order: 2,
    fromGroup: "Stage et emploi",
  },
  {
    id: "Em2",
    url: "/emplois/entreprises",
    relativeUrl: "entreprises",
    label: "Entreprises",
    order: 2,
    fromGroup: "Stage et emploi",
  },
  {
    id: "Em3",
    url: "/emplois/offres",
    relativeUrl: "offres",
    label: "Offre d'emploi",
    order: 2,
    fromGroup: "Stage et emploi",
  },
];

export function useCurrentRoute() {
  const { location } = useLocation();

  let currentRoute = React.useMemo(() => {
    let route = AllRoutes.find((route) => route.url === location.pathname);

    if (!route || typeof route === "undefined") {
      route = AllRoutes.filter((route) => route.id !== "DASHBOARD").find(
        (route) =>
          route.url === location.state?.fromGroup ||
          location.pathname.startsWith(route.url)
      );
    }
    return route;
  }, [location.pathname, location.state]);

  return { currentRoute };
}
