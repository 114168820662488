import React from "react";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { MuiTheme } from "config/theme";
import InfoBarProvider from "contexts/InfoBarContext";
import Pages from "pages";
import { AbitlityContext } from "shared/@pbac";
import { useAuth } from "hooks/useAuth";

const App = () => {
  const [, , authData] = useAuth();
  const abilities: string[] = []

  React.useEffect(() => {
    if (authData) {
      abilities.push(authData.role)
    }
  }, [authData])

  return (
    <ThemeProvider theme={MuiTheme}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={MuiTheme}>
          <InfoBarProvider>
            <AbitlityContext.Provider value={{ abilities }}>
              <Pages />
            </AbitlityContext.Provider>
          </InfoBarProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
