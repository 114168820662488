import EmailIcon from "@material-ui/icons/Email";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import { Settings } from "@material-ui/icons";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import DirectionsIcon from "@material-ui/icons/Directions";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LocationCitySharpIcon from "@material-ui/icons/LocationCitySharp";
import PublicIcon from "@material-ui/icons/Public";

export const MENU: Array<any> = [
  {
    url: "/publicite",
    label: "Publicité",
    icon: PublicIcon,
  },
  {
    label: "Messages",
    url: "/messages",
    icon: EmailIcon,
  },
  {
    url: "/accueil",
    label: "Page d'accueil",
    menus: [
      {
        url: "articles",
        label: "Articles",
      },
      {
        url: "banniere",
        label: "Bannière",
      },
    ],
    icon: ViewCarouselIcon,
  },

  {
    url: "/vie-etudiante",
    label: "Vie Étudiante",
    icon: SchoolIcon,
    menus: [
      {
        url: "presentation",
        label: "Présentation",
      },
      {
        url: "articles",
        label: "Articles",
      },
    ],
  },
  {
    url: "/fiches-metier",
    label: "Fiches Métiers",
    menus: [
      {
        url: "presentation",
        label: "Présentation",
      },
      {
        url: "secteurs",
        label: "Secteurs",
      },
      {
        url: "fiches-metiers",
        label: "Fiches Métiers",
      },
    ],
    icon: AssignmentIcon,
  },
  {
    url: "/orientation",
    label: "Orientation",
    menus: [
      {
        url: "presentation",
        label: "Présentation",
      },
      {
        url: "categories",
        label: "Categories",
      },
      {
        url: "articles",
        label: "Articles",
      },
    ],
    icon: DirectionsIcon,
  },
  {
    url: "/etudes",
    label: "Études",
    menus: [
      {
        url: "presentation",
        label: "Présentation",
      },
      {
        url: "ecoles",
        label: "Écoles",
      },
      {
        url: "avis",
        label: "Avis",
      },

      {
        url: "informations",
        label: "Demande d'information",
      },
      {
        url: "ne-pas-manquer",
        label: "À ne pas manquer",
      },
      {
        url: "parametrages",
        label: "Paramétrages Études",
      },
    ],
    icon: LocationCitySharpIcon,
  },
  {
    url: "/emplois",
    label: "Stage et Emploi",
    menus: [
      {
        url: "presentation",
        label: "Présentation",
      },
      {
        url: "candidats",
        label: "Candidats",
      },
      {
        url: "entreprises",
        label: "Entreprises",
      },
      {
        url: "offres",
        label: "Offres d'emploi",
      },
      {
        url: "candidatures",
        label: "Candidatures",
      },
      {
        url: "articles",
        label: "Articles",
      },
    ],
    icon: WorkIcon,
  },
  {
    url: "/soutien-scolaire",
    label: "Soutien Scolaire",
    menus: [
      {
        url: "presentation",
        label: "Présentation",
      },
      {
        url: "familles",
        label: "Familles",
      },
      {
        url: "enseignants",
        label: "Enseignants",
      },
      {
        url: "suivis",
        label: "Suivis intervention",
      },
      {
        url: "interventions",
        label: "Interventions",
      },
      {
        url: "demandes",
        label: "Demandes d'intervention",
      },
      {
        url: "articles",
        label: "Articles",
      },
    ],
    icon: SupervisorAccountIcon,
  },
  {
    url: "/parametrages",
    label: "Paramétrages Stage et emploi",
    menus: [
      {
        url: "experience",
        label: "Niveau d'experience",
      },
      {
        url: "situation-professionnel",
        label: "Situation professionnelle",
      },
      {
        url: "types-de-contrat",
        label: "Types de contrat",
      },
    ],
    icon: Settings,
  },
  {
    url: "/parametrages",
    label: "Paramétrages Soutien scolaire",
    menus: [
      {
        url: "niveau",
        label: "Niveau scolaire",
      },
    ],
    icon: Settings,
  },
  {
    url: "/parametrages",
    label: "Paramétrages Général",
    menus: [
      {
        url: "etudes",
        label: "Niveau d'études",
      },
      // {
      //   url: "disponibilite",
      //   label: "Disponibilité",
      // },
      {
        url: "villes",
        label: "Villes",
      },
    ],
    icon: Settings,
  },
  {
    url: "/reglages",
    label: "Réglages",
    menus: [
      {
        url: "utilisateurs",
        label: "Utilisateurs",
      },
      {
        url: "informations",
        label: "Informations",
      },
      {
        url: "a-propos",
        label: "À propos",
      },
    ],
    icon: Settings,
  },
];

export default MENU;
