import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogProps as MuiDialogProps,
  Typography,
  Slide,
  ButtonProps,
  IconButton,
} from "@material-ui/core";
import MuiDialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { SlideTransition } from "ui/Transition";

import "styled-components/macro";

import { APP_COLORS } from "constants/styles";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWidthMd: {
      width: 740,
    },
    paperWidthLg: {
      width: 940,
    },
    dialogSpacingContent: {
      padding: "28px",
    },
    paperDialogRoot: {
      position: "relative",
      boxShadow: "0px 15px 20px 3px rgba(0, 0, 0, 0.1)",
    },
    dialogActions: {
      borderTop: "1px solid #d2cdcd",
    },
    dialogActionsContainer: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
    },
    dialogActionsRoot: {
      marginLeft: "auto",
      padding: 0,
    },
    dialogActionsConfirmClose: {
      position: "absolute",
      bottom: 0,
      justifyContent: "flex-end",
      backgroundColor: APP_COLORS.primary,
      pointerEvents: "auto",
      height: "16%",
      width: "100%",
      padding: "14px 28px",
      borderRadius: "4px",
      zIndex: theme.zIndex.modal + 1,
      boxSizing: "border-box",
      textTransform: "none",
    },
  })
);

export interface ExtendedButtonProps extends ButtonProps {
  "data-testid"?: string;
}

export interface DialogProps extends MuiDialogProps {
  title?: string;
  subtitle?: string;
  customDialogTitle?: React.ReactNode;

  confirmButtonTitle?: React.ReactNode;
  cancelButtonTitle?: string;
  handleClose?(event?: any, reason?: string): void;
  handleConfirm?(): void;
  disableConfirmButton?: boolean;
  PrimaryButtonProps?: ExtendedButtonProps;
  SecondaryButtonProps?: ExtendedButtonProps;
  isFormDirty?: boolean;
  hasStickyBars?: boolean;
  height?: string;
}

export default function Dialog<T extends object>({
  title,
  subtitle,
  confirmButtonTitle = "Confirmer",
  cancelButtonTitle = "Annuler",
  disableConfirmButton = false,
  handleConfirm,
  handleClose,
  PrimaryButtonProps = {},
  SecondaryButtonProps = {},
  isFormDirty = false,
  children,
  hasStickyBars = false,
  maxWidth = "sm",
  height,
  customDialogTitle,
  ...props
}: DialogProps) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [showFooterActions, setShowFooterActions] = React.useState(false);
  const [, setTopSticky] = React.useState(false);
  const [, setBottomSticky] = React.useState(false);
  const dialogRef = React.useRef(null);

  const handleScroll = React.useCallback(() => {
    let throttleTimeout: any = null;

    function goesSticky() {
      let scrollEl = dialogRef?.current;
      let scrollTop = scrollEl?.scrollTop;

      let doesTopStick = window?.scrollY === scrollTop;
      let doesBottomStick = scrollTop + scrollEl?.clientHeight === scrollEl?.scrollHeight;

      setTopSticky(!doesTopStick);
      setBottomSticky(!doesBottomStick);

      throttleTimeout = null;
    }

    if (throttleTimeout === null) {
      throttleTimeout = setTimeout(goesSticky, 200);
    } else {
      goesSticky();
    }
  }, [setTopSticky]);

  function handleCloseDialog(event: any, reason?: string) {
    if (reason === "icon" || reason === "escapeKeyDown") {
      handleIconCloseDialog(event, reason);
    } else {
      handleButtonCloseDialog(event, reason);
    }
  }
  function handleIconCloseDialog(event: any, reason?: string) {
    setShowFooterActions(true);
  }

  function handleButtonCloseDialog(event: any, reason?: string) {
    if (!isFormDirty) {
      handleClose && handleClose(event, reason);
      setShowFooterActions(false);
    } else {
      setShowFooterActions(true);
    }
  }

  React.useEffect(() => {
    if (hasStickyBars) {
      window.addEventListener("scroll", handleScroll, true);

      return () => {
        window.removeEventListener("scroll", () => handleScroll, true);
      };
    }
  }, [handleScroll, hasStickyBars]);

  return (
    <MuiDialog
      fullWidth
      maxWidth={maxWidth}
      TransitionComponent={props.TransitionComponent || SlideTransition}
      fullScreen={fullScreen}
      onClose={(event, reason) => handleCloseDialog(event, reason)}
      scroll="paper"
      aria-labelledby={title?.toLowerCase().split(" ").join("-")}
      {...props}
      css={`
        > div > .MuiPaper-root {
          ${height && `height: ${fullScreen ? "100%" : height}`}
        }
      `}
      className={isFormDirty && "dirty"}
      classes={{
        paperWidthMd: classes.paperWidthMd,
        paperWidthLg: classes.paperWidthLg,
        paper: classes.paperDialogRoot,
      }}
    >
      <DialogTitle disableTypography>
        {customDialogTitle || (
          <Typography
            variant="h3"
            css={`
              font-weight: 500;
              color: #707070;
            `}
          >
            {title}
          </Typography>
        )}
        <IconButton
          onClick={(event) => handleCloseDialog(event, "icon")}
          css={`
            margin-left: auto;
            padding: 8px;
          `}
        >
          <Close fontSize="small" color="action" />
        </IconButton>
      </DialogTitle>

      <DialogContent ref={dialogRef} className={classes.dialogSpacingContent}>
        {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
        {children}
      </DialogContent>

      {(handleClose || handleConfirm) && (
        <DialogActions className={classes.dialogActions}>
          {handleClose && (
            <Button
              variant="outlined"
              onClick={(event) => handleCloseDialog(event, "button")}
              {...SecondaryButtonProps}
            >
              {cancelButtonTitle}
            </Button>
          )}

          {handleConfirm && (
            <Button
              variant="contained"
              color="primary"
              style={{ width: "auto" }}
              disabled={disableConfirmButton}
              onClick={handleConfirm}
              {...PrimaryButtonProps}
            >
              {confirmButtonTitle}
            </Button>
          )}
        </DialogActions>
      )}
      <Slide direction="up" in={showFooterActions} mountOnEnter unmountOnExit>
        <div
          className={`${classes.dialogActionsContainer} ${classes.dialogActionsConfirmClose}`}
        >
          <div
            css={`
              max-width: 45%;
            `}
          >
            <span
              css={`
                color: #ed7172;
              `}
            >
            </span>
          </div>
          <DialogActions className={classes.dialogActionsRoot}>
            <Button
              variant="outlined"
              onClick={() => setShowFooterActions(false)}
              style={{
                textTransform: "none",
              }}
            >
              Rester
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={(event) => {
                handleClose && handleClose(event);
                setShowFooterActions(false);
              }}
              {...SecondaryButtonProps}
              style={{
                textTransform: "none",
                backgroundColor: "#ed7172",
              }}
            >
              Annuler et fermer
            </Button>
          </DialogActions>
        </div>
      </Slide>
    </MuiDialog>
  );
}
