import React from "react";
import { Column } from "ui/Table";
import { StudentLifeArticle } from '__types__';
import { formatDate } from "utils";
import StatusInfo from "ui/StatusInfo";

export const ARTICLE_STATUS_COLOR = {
  TO_VALIDATE: {
    bg: "#0D5593",
    text: "À Publier"
  },
  VALIDATE: {
    bg: "#098664",
    text: "Publié"
  }
}

const LEVEL = {
  high: "Supérieur",
  secondary: "Secondaire"
}
export const DataRowsColumns: Column<StudentLifeArticle>[] = [
  {
    title: "Nom",
    field: "name",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Téléphone",
    field: "phonenumber",
  },
  {
    title: "Ville",
    field: "city",
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo width="90px" background={ARTICLE_STATUS_COLOR[status]['bg']} status={ARTICLE_STATUS_COLOR[status]['text']} />
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
];


export const SectorDataRowsColumns: Column<any>[] = [
  {
    title: "Nom",
    field: "name"
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Téléphone",
    field: "phonenumber",
  },
  {
    title: "Ecole",
    field: "name",
    render: ({ school }) => (
      school.name
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
]

export const EducationTypeDataRowsColumns: Column<any>[] = [
  {
    title: "Type d'enseignement",
    field: "name"
  },
  {
    title: "Niveau",
    field: "level",
    render: ({ level }) => (
      <>
        {LEVEL[level]}
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
];

export const DataNewsRowsColumns: Column<StudentLifeArticle>[] = [
  {
    title: "Titre",
    field: "name",
  },
  {
    title: "Description",
    field: "description",
    render: ({ description }) => (
      <>
        {description.substr(0, 50)} ...
      </>
    ),
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo width="90px" background={ARTICLE_STATUS_COLOR[status]['bg']} status={ARTICLE_STATUS_COLOR[status]['text']} />
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
  {
    title: "Date d'expiration",
    field: "expiration_date",
    render: ({ expiration_date }) => (
      <>
        {expiration_date && formatDate(expiration_date, "dd/LL/yyyy")}
      </>
    ),
  },
];

export const RatingDataRowsColumns: Column<any>[] = [
  {
    title: "Nom",
    field: "name",
  },
  {
    title: "Message",
    field: "message",
  },
  {
    title: "Note",
    field: "rating",
    render: ({ rating }) => <StatusInfo width="10px" background={"gray"} status={rating} />
  },
  {
    title: "Ecole",
    field: "name",
    render: ({ school }) => (
      school.name
    ),
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo width="90px" background={ARTICLE_STATUS_COLOR[status]['bg']} status={ARTICLE_STATUS_COLOR[status]['text']} />
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
];