import React, { useEffect, useState, useCallback, useRef } from "react";
import { globalHistory } from "@reach/router";
export const useLocation = () => {
  const initialState = {
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  };
  const [state, setState] = useState(initialState);
  useEffect(() => {
    const removeListener = globalHistory.listen((params) => {
      const { location } = params;
      const newState = Object.assign({}, initialState, { location });
      setState(newState);
    });
    return () => {
      removeListener();
    };
  }, [initialState]);
  return state;
};


export const useTimeout = (callback: () => void, delay: number) => {
  // save id in a ref
  const timeoutId: React.MutableRefObject<null | typeof callback> = useRef(null);

  // save callback as a ref so we can update the timeout callback without resetting the clock
  const savedCallback: React.MutableRefObject<null | typeof callback> = useRef(null);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // clear the timeout and start a new one, updating the timeoutId ref
  const reset = useCallback(() => {
    clearTimeout(timeoutId.current as any);
    const id = setTimeout(savedCallback.current as any, delay);
    timeoutId.current = id as any;
  }, [delay]);

  useEffect(() => {
    if (delay !== null) {
      reset();
      return () => clearTimeout(timeoutId.current as any);
    }
  }, [delay, reset]);

  return { reset };
};


export const useDelayNextChildren = (children: React.ReactNode, delay: number) => {
  const [finalChildren, setFinalChildren] = useState(null);

  const { reset } = useTimeout(() => {
    setFinalChildren(children as any);
  }, delay);

  useEffect(() => {
    reset();
  }, [reset, children]);

  return finalChildren || null;
};

export const useThrottle = <T>(value: T, ms: number = 200) => {
  const [state, setState] = useState<T>(value);
  const timeout = useRef<any>(null);
  const nextValue = useRef(null) as any;
  const hasNextValue = useRef(0) as any;

  useEffect(() => {
    if (!timeout.current) {
      setState(value);
      const timeoutCallback = () => {
        if (hasNextValue.current) {
          hasNextValue.current = false;
          setState(nextValue.current);
          timeout.current = setTimeout(timeoutCallback, ms);
        } else {
          timeout.current = null;
        }
      };
      timeout.current = setTimeout(timeoutCallback, ms);
    } else {
      nextValue.current = value;
      hasNextValue.current = true;
    }

    return () => clearTimeout(timeout.current);
  }, [value, hasNextValue, ms, nextValue]);

  return state;
};