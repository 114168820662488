import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { navigate, RouteComponentProps } from "@reach/router";
import { TextFieldGroup, TextField } from "ui/Form/TextField";
import axios from "axios";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoBarContext } from "contexts/InfoBarContext";
import {
  Paper,
  Box,
  Typography,
  Container,
  Button,
} from "@material-ui/core";
import { useAuthToken } from "config/authProvider";
import { useAuth } from "hooks/useAuth";
import { SimpleSpinner } from "ui/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
    display: "flex-start",
    alignItems: "center",
    paddingTop: 100,
  },
  content: {
    width: "30%",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  form: {
    width: "100%",
  },
  title: {
    fontSize: 40,
    fontWeight: 800,
  },
  subtitle: {
    fontSize: 23,
    fontWeight: 400,
    lineHeight: "15px",
  },
  footer: {
    backgroundColor: "rgb(35, 50, 102)",
    padding: "25px",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
    color: "grey",
  },
  titleFooter: {
    color: "white",
  },
}));

const schema = yup.object().shape({
  email: yup.string()
    .email("Veuillez saisir une adresse email valide.")
    .required("Veuillez saisir votre adresse email."),
  password: yup.string().required("Veuillez saisir votre mot de passe.")
});

const Signin: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors, reset } = useForm({ resolver: yupResolver(schema) });

  const [loading, setLoading] = React.useState(false);
  const [_, setAuthToken] = useAuthToken();
  const [isAuth] = useAuth();
  const { setInfo } = React.useContext(InfoBarContext);


  React.useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  }, [isAuth]);

  const onSubmit = (data: any) => {
    setLoading(true);
    axios.post("/api/v1/users/signin", {
      ...data
    }).then(async res => {
      setLoading(false);
      await setAuthToken(res.data);
      window.location.href = "/";
    })
      .catch(error => {
        setLoading(false);
        error.response.status === 500 && setInfo({ type: "error", message: "Une erreur s'est produite" });
        (error.response.status === 403 || error.response.status === 404) && setInfo({ type: "error", message: "Email ou mot de passe incorrect" });
        (error.response.status === 401) && setInfo({ type: "error", message: "Votre compte n'est pas activer" });
      })
  }

  return (
    <>
      <Paper className={classes.root} elevation={0}>
        <Container className={classes.content}>
          <Box>

            <Box display="flex" justifyContent="center" marginBottom={5}>
              <img src="logo-studies.png" alt="Logo Studies" width="200px" />
            </Box>

            <Box display="flex" justifyContent="center" marginBottom={7}>
              <Typography variant="h3" component="h3">
                Connectez-vous
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <TextFieldGroup>
                  <TextField
                    label="Email"
                    name="email"
                    autoFocus
                    type="email"
                    fullWidth
                    required
                    inputRef={register}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </TextFieldGroup>
                <TextFieldGroup>
                  <TextField
                    label="Mot de passe"
                    name="password"
                    type="password"
                    fullWidth
                    required
                    inputRef={register}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                </TextFieldGroup>

                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    backgroundColor: "#233266",
                    color: "#FFF",
                  }}
                  className={classes.form}
                  disabled={loading}
                  type="submit"
                >
                  {
                    loading ? <SimpleSpinner /> : "Connexion"
                  }
                </Button>
              </Box>
            </form>

          </Box>
        </Container>
      </Paper>

      <div className={classes.footer}>
        <Typography variant="body2" className={classes.titleFooter}>
          {"Copyright © "}
          <span style={{ color: "#0ba094" }}>
            Studies
          </span>
          {" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </div>
    </>
  );
};

export default Signin;
