import { useAuthToken } from "config/authProvider";

export const useLogout = () => {
  const [, , removeAuthToken] = useAuthToken();

  const logout = async () => {
    removeAuthToken();
    setTimeout(() => {
      window.location.href = "/";
    });
  };
  return logout;
};
