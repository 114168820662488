import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Paper from "ui/Paper";
import axios from "axios";
import { AdsDataRowsColumns } from "./data";
import Table from "ui/Table";
import EditPageCover from "./EditCover";
import Deletion from "pages/Deletion";
import { useStyles } from 'constants/styles';
import { Button, Divider, Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}
export const Banners: React.FC<DefaultPageProps> = () => {
  const [data, setData] = React.useState<any[]>([]);
  const [openCover, setOpenCover] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);

  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    axios('/api/v1/page-ads')
      .then(res => {
        setData(res.data)
      })
  }, []);


  const handleClose = () => {
    setOpenCover(false);
    setCurrent(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  function closeDrawer() {
    setIsDrawerOpen(false);
    setCurrent(null);
  }


  return (
    <>
      <PageHeader title="Publicité">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setOpenCover(true)
          }}
        >
          Ajouter
        </Button>
      </PageHeader>
      <Paper>
        <Table
          actionAbilities={["ADMIN", "MANAGER"]}
          hasToolBar={false}
          rowsIdentifier='id'
          columns={AdsDataRowsColumns}
          data={data}
          onRowClick={(row) => {
            setCurrent(row);
            setIsDrawerOpen(true);
          }}
          onRowDelete={(row) => {
            setCurrent(row);
            setOpenDelete(true)
          }}
          onRowEdit={(row) => {
            setCurrent(row);
            setOpenCover(true);
          }}
        />
      </Paper>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h3">
                  <b style={{ marginRight: 10 }}>{current?.name}</b>
                </Typography>
              }
            />

          </ListItem>

          <Divider />

          <ListItem>
            <img className={classes.coverImage} src={current?.cover_image} alt="" />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <Typography>
                  {current?.description}
                </Typography>
              }
            />
          </ListItem>

          <Divider />
        </List>
      </Drawer>

      <EditPageCover current={current} open={openCover} onClose={handleClose} page={"page"} />
      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/page-ads/"
      />
    </>
  );
};
export default Banners;