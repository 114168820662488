import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import axios from "axios";
import { Box, Button, Grid, Link, Radio, Tab, Tabs } from "@material-ui/core";
import Paper from "ui/Paper";
import { a11yProps } from "utils";
import { TabPanel } from "../Settings/Levels";
import { useStyles } from "pages/Tutoring/CardInfo";
import { SelectedIcon } from "ui/Custom/Icons";
import { CandidatApplicationColumns, CandidatsColumns, JOB_OFFER_STATUS } from "./data";
import StatusInfo from "ui/StatusInfo";
import Table from "ui/Table";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = (props) => {
  const classes = useStyles();

  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openValidate, setOpenValidate] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleValue = React.useCallback(
    (e: React.ChangeEvent<{}>, newValue: number) => {
      setValue((prevValue) => newValue);
    },
    [value, setValue]
  );

  React.useEffect(() => {
    axios('/api/v1/candidats/' + props["id"])
      .then(res => {
        setData(res.data)
      })
  }, []);

  return (
    <>

      <PageHeader back title="Candidats">
      </PageHeader>

      <Paper>
        <Tabs
          onChange={handleValue}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Candidatures" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid item xs={12} md={6}>
            {
              data &&
              <Box p={2}>
                <div className={classes.titleInfo}>
                  CV
                  <hr className={classes.hr} />
                </div>
                {data?.resume ? <a
                  style={{ backgroundColor: "#233266", color: "#fff", padding: "8px 16px", borderRadius: "5px" }}
                  href={data?.resume} target="_blank">Voir le CV</a>
                  : <Button color="default" variant="contained">Pas de CV</Button>}

                <br />
                <br />
                <br />

                <div className={classes.titleInfo}>
                  Infos
                  <hr className={classes.hr} />
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Nom  :</span>
                  <span className={classes.contentField}>{data?.firstname}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>prénom :</span>
                  <span className={classes.contentField}>{data?.lastname}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Téléphone :</span>
                  <span className={classes.contentField}>{data?.phonenumber}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Email :</span>
                  <span className={classes.contentField}>{data?.email}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Adresse  :</span>
                  <span className={classes.contentField}>{data?.address}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Ville  :</span>
                  <span className={classes.contentField}>{data?.city}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Niveau d'étude :</span>
                  <span className={classes.contentField}>{data?.study_level}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Type de contrat recherché  :</span>
                  <span className={classes.contentField}>{data?.search_contract_type}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Disponibilité :</span>
                  <span className={classes.contentField}>{data?.availability}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Expérience professionnelle :</span>
                  <span className={classes.contentField}>{data?.current_job_level}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Situation professionnelle :</span>
                  <span className={classes.contentField}>{data?.professional_situation}</span>
                </div>
                <div className={classes.info}>
                  <span className={classes.nameField}>Métiers ciblé  :</span>
                  <span className={classes.contentField}>{data?.sought_sectors}</span>
                </div>

              </Box>
            }
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Table
            rowsIdentifier="id"
            columns={CandidatApplicationColumns}
            data={data?.applications}
            onRowClick={(row) => {
              navigate("/emplois/offres/" + row.id)
            }}
          />
        </TabPanel>
      </Paper>
    </>
  );
};

export default Home;
