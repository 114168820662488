import React from "react";
import { RouteComponentProps } from "@reach/router";

import {
  Grid,
  Box,
  Tabs,
  Tab
} from "@material-ui/core";
import { PageHeader } from "ui/Header";

import Paper from "ui/Paper";
import "styled-components/macro";
import { useStyles } from "pages/Tutoring/CardInfo";
import Covers from "pages/Covers";
import { a11yProps } from "utils";
import { TabPanel } from "pages/Settings/Levels";
import ArticleList from "pages/ArticleList";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Sectors: React.FC<DefaultPageProps> = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState(null);

  const [value, setValue] = React.useState(0);
  const handleValue = React.useCallback(
    (e: React.ChangeEvent<{}>, newValue: number) => {
      setValue((prevValue) => newValue);
    },
    [value, setValue]
  );


  React.useEffect(() => {
    fetch('/api/v1/sheets/' + props['id'])
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
      .catch(console.log)
  }, []);

  return (
    <>
      <PageHeader back title={`Fiches metiers`} />
      <Paper>
        <Tabs
          onChange={handleValue}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Articles" {...a11yProps(1)} />

        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box p={2}>
                {
                  data?.cover_image &&
                  <div className={classes.info}>
                    <img src={data?.cover_image} alt="" width="200" />
                  </div>
                }
                <div className={classes.info}>
                  <span className={classes.nameField}>Nom :</span>
                  <span className={classes.contentField}>{data?.name}</span>
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box p={2}>
                <div className={classes.nameField}>
                  Description
                </div>
                <div className={classes.info}>
                  <span className={classes.contentField}>{data?.description}</span>
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box p={2}>
                <div className={classes.nameField}>
                  Présentation
                </div>
                <div className={classes.info}>
                  <div dangerouslySetInnerHTML={{ __html: data?.presentation }} />
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box p={2}>
                <div className={classes.nameField}>
                  Cursus
                </div>
                <div className={classes.info}>
                  <div dangerouslySetInnerHTML={{ __html: data?.cursus }} />
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box p={2}>
                <div className={classes.nameField}>
                  Remunération
                </div>
                <div className={classes.info}>
                  <div dangerouslySetInnerHTML={{ __html: data?.remuneration }} />
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box p={2}>
                <div className={classes.nameField}>
                  Débouchés
                </div>
                <div className={classes.info}>
                  <div dangerouslySetInnerHTML={{ __html: data?.opportunities }} />
                </div>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box p={2}>
            {
              data &&
              <ArticleList title="Articles" section={`sheet_${data?.id}`} />
            }
          </Box>
        </TabPanel>
      </Paper>
    </>
  );
};

export default Sectors;
