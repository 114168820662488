import React from "react";
import { RouteComponentProps } from "@reach/router";
import ArticleList from "pages/ArticleList";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Articles: React.FC<DefaultPageProps> = () =>
  <ArticleList title="Articles" section="studies_news" />
export default Articles;