import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Paper from "ui/Paper";
import Covers from "pages/Covers";
import Page from "pages/Page";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Home: React.FC<DefaultPageProps> = () => {
  return (
    <>
      <PageHeader title="Présentation">
      </PageHeader>

      <Paper style={{ padding: "50px" }}>
        <Covers title="Banniere Page d'accueil" page="joboffers_home" />
        <Covers title="Banniere Page Candidat" page="joboffers_candidat" />
        <Covers title="Banniere Page Entreprise" page="joboffers_entreprise" />

        <Page title="Page Stage & emploi" page="joboffers_home" />
        <Page title="Page Candidat" page="joboffers_candidat" />
        <Page title="Page Entreprise" page="joboffers_entreprise" />
      </Paper>
    </>
  );
};

export default Home;
