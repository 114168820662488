import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import { Button } from "@material-ui/core";
import Table from "ui/Table";
import "styled-components/macro";
import { User } from "__types__";
import { UserDataRowsColumns } from "./data";
import Paper from "ui/Paper";
import EditUser from "./Edit/User";
import Can from "shared/@pbac/Can";
import Deletion from "pages/Deletion";
import { InfoBarContext } from "contexts/InfoBarContext";
import ActionsGroup from "ui/ActionsGroup";
import axios from "axios";
export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const actions = [
  { key: "add", label: "Ajouter", abilities: ["ADMIN"] },
  { key: "activate", label: "Activer", abilities: ["ADMIN"] },
  { key: "desactivate", label: "Désactiver", abilities: ["ADMIN", "MANAGER"] },
];

export const Users: React.FC<DefaultPageProps> = () => {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState<User[]>([]);
  const [toDelete, setToDelete] = React.useState(null);
  const [current, setCurrent] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const { setInfo } = React.useContext(InfoBarContext);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrent(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  React.useEffect(() => {
    fetch("/api/v1/users")
      .then((res) => res.json())
      .then((data) => {
        setUsers(data);
      })
      .catch(console.log);
  }, []);

  const onRowEdit = (row: any) => {
    setCurrent(row)
    setOpen(true);
  }

  const onRowDelete = (row: any) => {
    setToDelete(row);
    setOpenDelete(true);
  }

  const activate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments"
      })

    axios.put('/api/v1/users/activate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élements activé(s) avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const desactivate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments"
      })

    axios.put('/api/v1/users/desactivate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élements désactivé(s) avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "add":
        return handleClickOpen();
      case "activate":
        return activate();
      case "desactivate":
        return desactivate();
    }
  };

  return (
    <>
      <PageHeader title={`Utilisateurs (${users?.length})`}>
        <Can abilities={["ADMIN"]}>
          <ActionsGroup onClick={handleAction} actions={actions} />
        </Can>
      </PageHeader>

      <Paper>
        <Table
          hasAllSelection
          hasSelection
          actionAbilities={["ADMIN"]}
          rowsIdentifier="id"
          columns={UserDataRowsColumns}
          data={users}
          onRowDelete={onRowDelete}
          onRowEdit={onRowEdit}
          onRowChecked={setSelectedRows}
        />
      </Paper>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={toDelete}
        path="/api/v1/users/"
      />

      <EditUser open={open} current={current} onClose={handleClose} />
    </>
  );
};
