import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Family } from "__types__";
import {
  FamilyStudentColumns,
  FollowupColumns,
  InterventionColumns,
} from "./data";
import { Box, Button, Grid, Tab, Tabs } from "@material-ui/core";
import EditFamilyStudent from "./EditFamilyStudent";
import Paper from "ui/Paper";
import { a11yProps } from "utils";
import { TabPanel } from "../Settings/Levels";
import { useStyles } from "./CardInfo";
import Can from "shared/@pbac/Can";
import ActionsGroup from "ui/ActionsGroup";
import EditFamilyIntervention from "./EditFamilyIntervention";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const actions = [
  { key: "add_children", label: "Ajouter un enfant" },
  { key: "add_intervention", label: "Ajouter une intervention" },
];

export const interventionLabel = {
  onsite: "À domicile",
  online: "En ligne",
};
export const Home: React.FC<DefaultPageProps> = (props) => {
  const classes = useStyles();

  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [interventions, setInterventions] = React.useState([]);
  const [followups, setFollowups] = React.useState([]);
  const [openIntervention, setOpenIntervention] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleValue = React.useCallback(
    (e: React.ChangeEvent<{}>, newValue: number) => {
      setValue((prevValue) => newValue);
    },
    [value, setValue]
  );

  React.useEffect(() => {
    axios("/api/v1/families/bycode/" + props["id"]).then((res) => {
      setData(res.data);
    });

    // axios('/api/v1/tutoring/interventions/bystudents', {students: })
    //   .then(res => {
    //     // setData(res.data)
    //   })
  }, []);

  React.useEffect(() => {
    if (data) {
      const st = data.students.map((item: any) => item.id);

      axios(
        "/api/v1/tutoring/interventions/bystudents/" + JSON.stringify(st)
      ).then((res) => {
        setInterventions(res.data);
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (interventions.length > 0) {
      const st = interventions.map((item: any) => item.id);

      axios(
        "/api/v1/tutoring/followups/byinterventionss/" + JSON.stringify(st)
      ).then((res) => {
        setFollowups(res.data);
      });
    }
  }, [interventions]);

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "add_children":
        return setOpen(true);
      case "add_intervention":
        return setOpenIntervention(true);
    }
  };

  return (
    <>
      <PageHeader back title="Familles">
        <Can abilities={["ADMIN", "MANAGER"]}>
          <ActionsGroup onClick={handleAction} actions={actions} />
        </Can>
      </PageHeader>

      <Paper>
        <Tabs
          onChange={handleValue}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Enfants" {...a11yProps(1)} />
          <Tab label="Interventions" {...a11yProps(2)} />
          <Tab label="Suivis" {...a11yProps(3)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Grid item xs={12} md={5}>
            <Box p={2}>
              <div className={classes.info}>
                <span className={classes.nameField}>Nom :</span>
                <span className={classes.contentField}>{data?.name}</span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>Personne en charge :</span>
                <span className={classes.contentField}>{data?.in_charge}</span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>Email :</span>
                <span className={classes.contentField}>{data?.email}</span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>Téléphone :</span>
                <span className={classes.contentField}>
                  {data?.phonenumber}
                </span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>Ville :</span>
                <span className={classes.contentField}>{data?.city}</span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>Quartier :</span>
                <span className={classes.contentField}>{data?.district}</span>
              </div>
              <div className={classes.info}>
                <span className={classes.nameField}>
                  Types d'intervention souhaités :
                </span>
                <span className={classes.contentField}>
                  {data?.interventionTypes}
                  {data?.intervention_types
                    ?.split(",")
                    ?.map((item: any) => interventionLabel[item])
                    .join(", ") || "N/A"}
                </span>
              </div>
            </Box>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Table
            rowsIdentifier="id"
            columns={FamilyStudentColumns}
            data={data?.students || []}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Table
            rowsIdentifier="id"
            columns={InterventionColumns}
            data={interventions || []}
            onRowClick={(row) => {
              navigate("/soutien-scolaire/interventions/" + row.id);
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Table
            rowsIdentifier="id"
            columns={FollowupColumns}
            data={followups || []}
          />
        </TabPanel>
      </Paper>

      <EditFamilyStudent
        {...props}
        open={open}
        family={data?.id}
        onClose={() => setOpen(false)}
      />

      <EditFamilyIntervention
        students={data?.students || []}
        open={openIntervention}
        onClose={() => setOpenIntervention(false)}
      />
    </>
  );
};

export default Home;
