import * as React from "react";
import { Chip, ChipProps } from "@material-ui/core";

import { COLORS, APP_COLORS } from "constants/styles";

import "styled-components/macro";

export interface InlinedChipProps extends ChipProps {
  textColor?: string;
  backgroundColor?: string;
  error?: boolean;
}

export function InlinedChip({
  error,
  backgroundColor: chipBgColor,
  textColor = "white",
  children,
  ...props
}: InlinedChipProps) {
  let bgColor = chipBgColor || APP_COLORS.textGrayedOut;

  let fontWeight: any = 600;

  if (props.variant === "outlined") {
    fontWeight = "normal";
    bgColor = "transparent";
    textColor = APP_COLORS.textGrayedOut;
  }

  return (
    <Chip
      label={children || props.label}
      {...props}
      css={`
        background-color: ${error ? COLORS.redDanger : bgColor};
        color: ${textColor};
        font-size: 0.72rem;
        font-weight: ${fontWeight};
        text-transform: uppercase;
        cursor: inherit;
      `}
    />
  );
}

export function TonedChip({ verdict }: { verdict: boolean }) {
  return (
    <Chip
      label={verdict ? "Yes" : "No"}
      css={`
        background-color: ${verdict ? COLORS.greenFocus : COLORS.redDanger};
        color: #fff;
        border-radius: 4px;
        height: 24px;
      `}
    />
  );
}
