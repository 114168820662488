import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import { Button } from "@material-ui/core";
import Covers from "pages/Covers";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}
export const Banners: React.FC<DefaultPageProps> = () => {

  return (
    <>
      <PageHeader title="Bannieres">


      </PageHeader>
      <Covers expanded title="" page="home" />
    </>
  );
};
export default Banners;