import React from "react";

import {
  InputBase,
  Select,
  FormControl,
  InputLabel,
  SelectProps,
  InputBaseProps,
  FormHelperTextProps,
  FormHelperText,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { COLORS } from "constants/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    label: {
      color: "inherit",
      fontSize: "1.14rem",
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${COLORS.gray5}`,
      padding: "11px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontSize: "14px",
      "&:focus": {
        boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
        borderColor: theme.palette.primary.main,
      },
    },
  })
);

interface CustomSelectProps {
  label?: string;
  SelectProps?: SelectProps;
  FormHelperTextProps?: FormHelperTextProps;
  helperText?: React.ReactNode;
  children?: React.ReactNode;
}

export function CustomSelect({
  label,
  className,
  SelectProps,
  FormHelperTextProps,
  helperText,
  children,
  ...props
}: CustomSelectProps & InputBaseProps) {
  const classes = useStyles({});

  const helperTextId = helperText && props.id ? `${props.id}-helper-text` : undefined;

  return (
    <FormControl className={className} disabled={props.disabled} error={props.error}>
      {label && (
        <InputLabel
          shrink
          htmlFor={props.id ?? props.name}
          className={classes.label}
          error={props.error}
        >
          {label} {props.required && <span>*</span>}
        </InputLabel>
      )}
      <Select
        id={props.id ?? props.name}
        input={
          <InputBase
            classes={{ root: classes.root, input: classes.input }}
            {...props}
            // ariaLabel={props.id ?? props.name}
          />
        }
        {...SelectProps}
      >
        {children}
      </Select>
      {helperText && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
