import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import "styled-components/macro";
import Back from "ui/Back";
import { navigate } from "@reach/router";
export const PageHeader = ({ title, children, back }: { title: string, children?: any, back?: boolean }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      alignItems="center"
      css={`
          margin-bottom: ${theme.spacing(4)}px;
        `}
    >
      <Grid item xs={7}>
        <Typography
          variant="h1"
          css={`
              font-size: 1.46rem;
              font-weight: bold;
            `}
          color="primary"
        >
          {back && <Back onBack={() => { navigate(-1) }} />}
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        css={`
            margin-left: auto;
            display: flex;
            justify-content: space-between;
          `}
      >
        {children}
      </Grid>
    </Grid>
  );
};
