import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import { JobOffer } from '__types__';
import { JobOfferColumns } from './data';
import Paper from "ui/Paper";
import axios from "axios";
import "styled-components/macro";
import { InfoBarContext } from "contexts/InfoBarContext";
import Deletion from "pages/Deletion";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}
export const Entreprises: React.FC<DefaultPageProps> = () => {
  const [data, setData] = React.useState<JobOffer[]>([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const { setInfo } = React.useContext(InfoBarContext);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [current, setCurrent] = React.useState(null);


  React.useEffect(() => {
    axios('/api/v1/joboffers')
      .then(res => {
        setData(res.data)
      })
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const validate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les offres à validé"
      })

    axios.put('/api/v1/joboffers/validate', {
      offers: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Offres validé avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  return (
    <>
      <PageHeader title={`Offres d'emploi (${data?.length})`}>
      </PageHeader>
      <Paper>
        <Table
          rowsIdentifier="id"
          columns={JobOfferColumns}
          data={data}
          onRowChecked={setSelectedRows}
          onRowClick={(row) => {
            navigate("/emplois/offres/" + row.id)
          }}
          onRowDelete={(row) => {
            setCurrent(row)
            setOpenDelete(true);
          }}
        />
      </Paper>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/joboffers/"
      />
    </>
  );
};

export default Entreprises;
