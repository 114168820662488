import React from "react";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from 'ui/Form/TextField';

import { InfoBarContext } from "contexts/InfoBarContext";
import axios from "axios";

const defaultValues = {
  top_start_date: '',
  top_end_date: ''
}

interface EditArticleProps {
  open: boolean;
  onClose: () => void;
  article?: any;
}
export const EditArticle: React.FC<EditArticleProps> = ({ open, onClose, article }) => {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  React.useEffect(() => {
    if (article) {
      setCurrent(article);
      setFormValues({
        top_start_date: article.top_start_date,
        top_end_date: article.top_end_date,
      });
    }
  }, [article]);

  const handleClose = () => {
    setCurrent(null);
    setFormValues(defaultValues);
    onClose();
  };

  const handleSave = () => {
    setFormSubmited(true);

    if (!Boolean(formValues.top_start_date)) return;

    if (current) {
      axios.put('/api/v1/joboffers/validate', {
        offer_id: article.id,
        expiration_date: formValues.top_start_date
      })
        .then(res => {
          setInfo({
            type: "success",
            message: "Offre validéé avec succès"
          })
          setTimeout(() => {
            window.location.reload();
          }, 1500)
        })
    }
  };

  return (
    <Dialog
      title="Validé l'offre"
      confirmButtonTitle={"Validé"}
      open={open}
      handleConfirm={handleSave}
      handleClose={handleClose}>
      <TextFieldGroup>
        <TextField
          label="Date d'expiration"
          name="top_start_date"
          value={formValues.top_start_date}
          autoFocus
          margin="dense"
          type="date"
          fullWidth
          error={isFormSubmited && !Boolean(formValues.top_start_date)}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && !Boolean(formValues.top_start_date)
              ? "Veuillez saisir la date d'expiration de l'offre"
              : ""
          }
          required

        />
      </TextFieldGroup>
    </Dialog>
  );
};
export default EditArticle;