import React from "react";

import { Column } from "ui/Table";
import { Sector, Entreprise, JobOffer } from "__types__";
import { formatDate } from "utils";
import StatusInfo from "ui/StatusInfo";
import { ACCOUNT_STATUS_COLOR } from "pages/Tutoring/data";
import "styled-components/macro";

export const JOB_OFFER_STATUS = {
  TO_VALIDATE: {
    bg: "#0D5593",
    text: "À Validé",
  },
  VALIDATE: {
    bg: "#098664",
    text: "Validé",
  },
};

type S = {
  id: string;
  name: string;
  description?: string;
  presentation?: string;
  curriculum?: string;
  remuneration?: string;
  opportunities?: string;
  createdAt?: string;
  category?: any;
};

export const SectorDataRowsColumns: Column<Sector>[] = [
  {
    title: "Nom",
    field: "name",
    render: ({ name }) => (
      <b
        css={`
          font-size: 13px;
        `}
      >
        {name}
      </b>
    ),
  },
  {
    title: "Description",
    field: "description",
  },
  // {
  //   title: "Articles",
  //   field: "count",
  //   render: ({ sheets }) => (
  //     <b
  //       css={`
  //         font-size: 13px;
  //       `}
  //     >
  //       {sheets.length}
  //     </b>
  //   ),
  // },
  {
    title: "Date creation",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];
export const SheetDataRowsColumns: Column<S>[] = [
  {
    title: "Nom",
    field: "name",
    render: ({ name }) => (
      <b
        css={`
          font-size: 13px;
        `}
      >
        {name}
      </b>
    ),
  },
  {
    title: "Categorie",
    field: "category",
    render: ({ category }) => <>{category?.name}</>,
  },
  {
    title: "Date creation",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const EntrepriseColumns: Column<any>[] = [
  {
    title: "Nom",
    field: "name",
  },
  {
    title: "Tel.",
    field: "phonenumber",
  },
  {
    title: "Email",
    field: "login_email",
  },
  {
    title: "Option CV Theque",
    field: "has_cvtheque",
    render: ({ has_cvtheque }) => (has_cvtheque ? "Oui" : "Non"),
  },
  {
    title: "Compte",
    field: "active",
    render: ({ active }) => (
      <>
        <StatusInfo
          width="80px"
          background={ACCOUNT_STATUS_COLOR[active.toString()]["bg"]}
          status={ACCOUNT_STATUS_COLOR[active.toString()]["text"]}
        />
      </>
    ),
  },
  {
    title: "Statut",
    field: "account_status",
    render: ({ account_status }) => (
      <>
        <span
          css={`
            width: 10px;
            height: 10px;
            background-color: ${account_status ? "green" : "gray"};
            display: inline-block;
            border-radius: 30px;
            margin-right: 5px;
          `}
        ></span>
        {account_status ? "Activer" : "Désactiver"}
      </>
    ),
  },
  {
    title: "Date d'inscription",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
  // {
  //   title: "Derniere connexion",
  //   field: "createdAt",
  //   render: ({ createdAt }) => (
  //     <>
  //       {formatDate(createdAt, "LLL dd, yyyy · HH:mm")}
  //     </>
  //   ),
  // },
];

export const CandidatsColumns: Column<any>[] = [
  {
    title: "Nom",
    field: "firstname",
  },
  {
    title: "Péenom",
    field: "lastname",
  },
  {
    title: "Tel.",
    field: "phonenumber",
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Compte",
    field: "active",
    render: ({ active }) => (
      <>
        <StatusInfo
          width="80px"
          background={ACCOUNT_STATUS_COLOR[active.toString()]["bg"]}
          status={ACCOUNT_STATUS_COLOR[active.toString()]["text"]}
        />
      </>
    ),
  },
  {
    title: "Statut",
    field: "account_status",
    render: ({ account_status }) => (
      <>
        <span
          css={`
            width: 10px;
            height: 10px;
            background-color: ${account_status ? "green" : "gray"};
            display: inline-block;
            border-radius: 30px;
            margin-right: 5px;
          `}
        ></span>
        {account_status ? "Activer" : "Désactiver"}
      </>
    ),
  },
  {
    title: "Date d'inscription",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
  // {
  //   title: "Derniere connexion",
  //   field: "createdAt",
  //   render: ({ createdAt }) => (
  //     <>
  //       {formatDate(createdAt, "LLL dd, yyyy · HH:mm")}
  //     </>
  //   ),
  // },
];

export const OfferCandidatsColumns: Column<any>[] = [
  {
    title: "Nom",
    field: "firstname",
    render: ({ candidat }) => <>{candidat.firstname}</>,
  },
  {
    title: "Prénom",
    field: "lastname",
    render: ({ candidat }) => <>{candidat.lastname}</>,
  },
  {
    title: "Tel.",
    field: "phonenumber",
    render: ({ candidat }) => <>{candidat.phonenumber}</>,
  },
  {
    title: "Email",
    field: "email",
    render: ({ candidat }) => <>{candidat.email}</>,
  },
  {
    title: "Postulé le",
    field: "createdAt",
    render: ({ createdAt }) => <>{formatDate(createdAt, "LLL dd, yyyy")}</>,
  },
];

export const JobOfferColumns: Column<JobOffer>[] = [
  {
    title: "Titre",
    field: "title",
  },
  {
    title: "Entreprise",
    field: "entreprise",
    render: ({ entreprise }) => <>{entreprise?.name}</>,
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo
          width="90px"
          background={JOB_OFFER_STATUS[status]["bg"]}
          status={JOB_OFFER_STATUS[status]["text"]}
        />
      </>
    ),
  },
  {
    title: "Publié le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const EntrepriseJobOfferColumns: Column<JobOffer>[] = [
  {
    title: "Titre",
    field: "title",
  },
  {
    title: "Statut",
    field: "status",
    render: ({ status }) => (
      <>
        <StatusInfo
          width="90px"
          background={JOB_OFFER_STATUS[status]["bg"]}
          status={JOB_OFFER_STATUS[status]["text"]}
        />
      </>
    ),
  },
  {
    title: "Publié le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const CandidatApplicationColumns: Column<any>[] = [
  {
    title: "Offre",
    field: "title",
    render: ({ joboffer }) => <>{joboffer.title}</>,
  },
  {
    title: "Entreprise",
    field: "title",
    render: ({ entreprise }) => <>{entreprise?.name}</>,
  },
  {
    title: "Postulé le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>{formatDate(createdAt, "LLL dd, yyyy · HH:mm")}</>
    ),
  },
];

export const ApplicationsColumns: Column<any>[] = [
  {
    title: "Nom & Prénom",
    field: "firstname",
    render: ({ candidat }) => `${candidat.firstname} ${candidat.lastname}`,
  },
  {
    title: "Tel.",
    field: "phonenumber",
    render: ({ candidat }) => <>{candidat.phonenumber}</>,
  },
  {
    title: "Email",
    field: "email",
    render: ({ candidat }) => <>{candidat.email}</>,
  },
  {
    title: "Entreprise",
    field: "entreprise",
    render: ({ entreprise }) => entreprise.name,
  },
  {
    title: "Postulé le",
    field: "createdAt",
    render: ({ createdAt }) => <>{formatDate(createdAt, "LLL dd, yyyy")}</>,
  },
];
