import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { MainLayout } from "ui/Layout/Main";
import { Articles } from "./Articles";

import Banners from "./Banners";
import EditArticle from "pages/EditArticle";

const SheetPage: React.FC<RouteComponentProps> = () => {

  return (
    <MainLayout>
      <Router>
        <Articles path="/articles" noPageTitle />
        <EditArticle path="/articles/ajouter" section="home_page" />
        <EditArticle path="/articles/editer" section="home_page" />
        <Banners path="/banniere" noPageTitle />
      </Router>
    </MainLayout>
  );
};

export default SheetPage;