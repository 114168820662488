import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Button,
  Drawer,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import "styled-components/macro";

import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import { InlinedChip } from "ui/Custom";

import { Sheet } from "__types__";

import { formatDate } from "utils";

import { COLORS, useStyles } from 'constants/styles';
import { SheetDataRowsColumns } from './data';
import Paper from "ui/Paper";
import "styled-components/macro";
import axios from "axios";
import { InfoBarContext } from "contexts/InfoBarContext";
import Can from "shared/@pbac/Can";
import ActionsGroup from "ui/ActionsGroup";
import Deletion from "pages/Deletion";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const actions = [
  { key: "add", label: "Ajouter", abilities: [] },
  { key: "validate", label: "Validé", abilities: ["ADMIN", "MANAGER"] },
];

export const Sheets: React.FC<DefaultPageProps> = () => {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [sheets, setSheets] = React.useState<Sheet[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const { setInfo } = React.useContext(InfoBarContext);

  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    fetch("/api/v1/sheets")
      .then((res) => res.json())
      .then((data) => {
        setSheets(data);
        setLoading(false);
      })
      .catch(console.log);
  }, []);

  const handleNew = () => {
    navigate("/fiches-metier/fiches-metiers/ajouter");
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  const validate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments à valider"
      })

    axios.put('/api/v1/sheets/validate/multiple', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élements validé avec succès"
        })
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "add":
        return handleNew();
      case "validate":
        return validate();
    }
  };

  return (
    <>
      <PageHeader title={`Fiches métiers (${sheets.length})`}>
        <ActionsGroup onClick={handleAction} actions={actions} />
      </PageHeader>
      <Paper>
        <Table
          actionAbilities={["ADMIN", "MANAGER"]}
          columnsToSearchIn={["name"]}
          rowsIdentifier="id"
          columns={SheetDataRowsColumns}
          data={sheets}
          onRowClick={(row) => {
            navigate("/fiches-metier/fiches-metiers/" + row.id)
          }}
          onRowDelete={(row) => {
            setCurrent(row);
            setOpenDelete(true);
          }}
          onRowEdit={(row) => {
            navigate("/fiches-metier/fiches-metiers/editer/" + row.id)
          }}
          hasSelection
          onRowChecked={setSelectedRows}
        />

        {loading &&
          <div style={{ textAlign: "center" }}>
            <br />
            <CircularProgress size={60} />
          </div>
        }
      </Paper>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/sheets/"
      />

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h3">
                  <b style={{ marginRight: 10 }}>{current?.name}</b>
                  <InlinedChip
                    label={
                      current?.createdAt &&
                      formatDate(current?.createdAt, "LLL dd, yyyy · HH:mm")
                    }
                    backgroundColor={COLORS["blueFocus"]}
                    size="small"
                  />
                </Typography>
              }
            />
            {
              current && current.status === "TO_VALIDATE" &&
              <Can abilities={["ADMIN", "MANAGER"]}>
                <Button
                  variant="contained"
                  color="primary"
                  css={`
                  margin-right: 12px;
                `}
                  onClick={validate}
                >
                  Publier
                </Button>
              </Can>
            }
          </ListItem>

          <Divider />

          <ListItem>
            <img
              className={classes.coverImage}
              src={current?.cover_image || "/images/img_bg_1.jpg"}
              alt=""
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={<Typography>{current?.description}</Typography>}
            />
          </ListItem>

          <Divider />

          <ListItem>
            <div
              dangerouslySetInnerHTML={{ __html: current?.presentation }}
            ></div>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};
export default Sheets;
