
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";

import {
  List, ListItem, ListItemText, Divider,
  DialogContent, Typography,
  Drawer
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import { InlinedChip } from "ui/Custom";
import Dialog from 'ui/Dialog';

import { Sheet } from '__types__';

import { formatDate } from "utils";

import { COLORS, useStyles } from 'constants/styles';
import { RatingDataRowsColumns, SectorDataRowsColumns } from './data';
import Paper from "ui/Paper";
import "styled-components/macro";
import { InfoBarContext } from "contexts/InfoBarContext";
import ActionsGroup from "ui/ActionsGroup";
import axios from "axios";
import Deletion from "pages/Deletion";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const actions = [
  { key: "validate", label: "Valider", abilities: ["ADMIN", "MANAGER"] },
  { key: "desactivate", label: "Désactiver", abilities: ["ADMIN", "MANAGER"] },
];

export const Ratings: React.FC<DefaultPageProps> = () => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [data, setData] = React.useState<Sheet[]>([]);
  const { setInfo } = React.useContext(InfoBarContext);
  const [current, setCurrent] = React.useState(null);

  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    fetch('/api/v1/studies/ratings')
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const validate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments à valider"
      })

    axios.put('/api/v1/studies/ratings/validate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élement(s) validé(s) avec succès!"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const desactivate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments à désactiver"
      })

    axios.put('/api/v1/studies/ratings/desactivate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Élement(s) désactivé(s) avec succès!"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "validate":
        return validate();
      case "desactivate":
        return desactivate();
    }
  };

  const onRowDelete = (row: any) => {
    setCurrent(row)
    setOpenDelete(true);
  }

  return (
    <>
      <PageHeader title="Avis">
        <ActionsGroup onClick={handleAction} actions={actions} />
      </PageHeader>
      <Paper>
        <Table
          hasSelection
          rowsIdentifier='id'
          columns={RatingDataRowsColumns}
          data={data}
          onRowDelete={onRowDelete}
          onRowChecked={setSelectedRows}
        />
      </Paper>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/studies/rating/"
      />
    </>
  );
};

export default Ratings;
