import React from "react";
import { Column } from "./Table";
import { TableRow, TableHead, TableCell, TableSortLabel, Checkbox } from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import "styled-components/macro";
// import StyledCheckbox from "ui/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);
export interface TableHeaderProps<T extends any> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order?: "asc" | "desc";
  orderBy?: keyof T;
  rowCount?: number;
  columns: Column<T>[];
  selectedRowsCount?: number;
  onAllRowChecked?(event: React.ChangeEvent<HTMLInputElement>): void;
  hasSelection?: boolean;
  asAction?: boolean;
  isDisabledChecked?: boolean;
}

export function TableHeader<T extends any>({
  columns,
  hasSelection,
  asAction,
  onAllRowChecked,
  selectedRowsCount = 0,
  rowCount = 0,
  onRequestSort,
  orderBy,
  order,
  isDisabledChecked = false,
}: TableHeaderProps<T>) {
  const styles = useStyles();
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <>
      <TableHead>
        <TableRow>
          {hasSelection && (
            <TableCell align="right" padding="checkbox">
              <Checkbox
                color="primary"
                css={`
                  "& .PrivateSwitchBase-root-12" {
                    padding: 0;
                  }
                  &.Mui-checked {
                    color: #399ebf;
                  }
                `}
                onChange={onAllRowChecked}
                disabled={isDisabledChecked}
                indeterminate={selectedRowsCount > 0 && selectedRowsCount < rowCount}
                checked={rowCount > 0 && selectedRowsCount === rowCount}
              />
            </TableCell>
          )}
          {columns.map((item) => (
            <TableCell key={item.field} align="left">
              <TableSortLabel
                active={orderBy === item.field}
                direction={orderBy === item.field ? order : "asc"}
                onClick={createSortHandler(item.field as any)}
              >
                {item.title}
                {orderBy === item.field ? (
                  <span className={styles.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          {asAction && <TableCell key="Actions"></TableCell>}
        </TableRow>
      </TableHead>
    </>
  );
}
