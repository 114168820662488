import React from "react";
import AbitlityContext from "./AbitlityContext";
import { checkAbilities } from "./utils";

interface CanProps {
  abilities: Array<string>;
  children: React.ReactNode;
}

const Can = ({ children, abilities }: CanProps) => {
  const { abilities: userAbilities } = React.useContext(AbitlityContext);
  const ablited = checkAbilities(userAbilities, abilities);

  return <>{ablited ? children : null}</>;
};

Can.defaultProps = {
  abilities: [],
};
export default Can;
