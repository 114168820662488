import React from "react";

import { Router } from "@reach/router";
import { topLevelPathNames } from "constants/routes";
import { useAuth } from "hooks/useAuth";
import { PrivateRoute } from "./PrivateRoutes";

import SigninPage from "pages/Auth/Signin";
import LogoutPage from "pages/Auth/Logout";
import ActivationPage from "pages/Auth/Activating";
import OverviewPage from "pages/Overview";
import ProfilePage from "pages/Profile";
import SheetsPage from "pages/Sheets";
import StudentLifePage from "pages/StudentLife";
import OrientationPage from "pages/Orientation";
import StudiesPage from "pages/Studies";
import SettingsPage from "pages/Settings";
import { Settings1Page } from "pages/Settings";
import MessagesPage from "pages/Messages";
import CoversPage from "pages/Covers";
import JobsPage from "pages/JobOffers";
import TutoringPage from "pages/Tutoring";
import HomePage from "pages/Home";
import AdsPage from "pages/Ads";

export const Pages = () => {
  const [isAuth] = useAuth();
  return (
    <>
      {isAuth ? (
        <PrivateRoutesContainer />
      ) : (
        <AuthentificationRoutesContainer />
      )}
    </>
  );
};

export const AuthentificationRoutesContainer = () => {
  return (
    <Router>
      <SigninPage path="connexion" />
      <SigninPage path="*" />
      <ActivationPage path="inscription" />
    </Router>
  );
};

export const PrivateRoutesContainer = () => {
  return (
    <Router>
      <PrivateRoute as={OverviewPage} path={"/"} />
      <PrivateRoute as={HomePage} path={"/accueil/*"} />
      <PrivateRoute as={AdsPage} path={"/publicite/*"} />
      <PrivateRoute as={ProfilePage} path={"/profil"} />
      <PrivateRoute as={SheetsPage} path={topLevelPathNames.SHEETS} />
      <PrivateRoute
        as={StudentLifePage}
        path={topLevelPathNames.STUDENT_LIFE}
      />
      <PrivateRoute as={OrientationPage} path={topLevelPathNames.ORIENTATION} />
      <PrivateRoute as={SettingsPage} path={topLevelPathNames.SETTINGS} />
      <PrivateRoute as={StudiesPage} path={topLevelPathNames.STUDIES} />
      <PrivateRoute as={MessagesPage} path={topLevelPathNames.MESSAGE} />
      <PrivateRoute as={CoversPage} path={topLevelPathNames.COVER} />
      <PrivateRoute as={JobsPage} path={topLevelPathNames.JOBS} />
      <PrivateRoute as={TutoringPage} path={topLevelPathNames.TUTORING} />
      <PrivateRoute as={Settings1Page} path={topLevelPathNames.SETTINGS_1} />
      <LogoutPage path="/logout" />
    </Router>
  );
};

export default Pages;
