import React from "react";
import { RouteComponentProps } from "@reach/router";
import { TextField, TextFieldGroup } from "ui/Form/TextField";
import Dialog from 'ui/Dialog';
import "styled-components/macro";
import Autocomplete from "ui/Autocomplete";
import axios from "axios";
import { InfoBarContext } from "contexts/InfoBarContext";

export interface EditUserProps extends RouteComponentProps {
  open: boolean;
  current?: any;
  onClose: () => void;
}

const defaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  role: null as string,
};

const rolesDef = ["Admin", "Opérateur", "Manager"];

const roles = {
  Admin: "ADMIN",
  "Opérateur": "OPERATOR",
  Manager: "MANAGER"
}

const rolesRev = {
  ADMIN: "Admin",
  OPERATOR: "Opérateur",
  MANAGER: "Manager"
}


const EditUser: React.FC<EditUserProps> = ({ open, current, onClose }) => {
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [formValues, setFormValues] = React.useState(defaultValues);

  const { setInfo } = React.useContext(InfoBarContext);

  React.useEffect(() => {
    if (current) {
      setFormValues({
        firstname: current.firstname,
        lastname: current.lastname,
        email: current.email,
        role: rolesRev[current.role],
      })
    }
  }, [current]);


  const handleClose = () => {
    onClose();
    setFormValues(defaultValues);
  };

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleSave = () => {
    setFormSubmited(true);

    if (
      !Boolean(formValues.firstname) ||
      !Boolean(formValues.lastname) ||
      !Boolean(formValues.email) ||
      !Boolean(formValues.role)
    )
      return;

    if (!current) {
      axios.post("/api/v1/users", {
        ...formValues,
        role: roles[formValues.role]
      }).then(async res => {
        setInfo({
          type: "success",
          message: "Nouvel utilisateur ajouté avec succès"
        })
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
        .catch(error => {
          if (error.response && error.response.status === 409)
            setInfo({
              type: "error",
              message: "L`adresse email existe déjà."
            })
        })
    } else {
      const formData = new FormData();
      formData.append("firstname", formValues.firstname);
      formData.append("lastname", formValues.lastname);
      formData.append("role", roles[formValues.role]);

      const body = JSON.stringify(Object.fromEntries(formData));

      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      };

      fetch("/api/v1/users/" + current.id, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          setInfo({
            type: "success",
            message: "Utilisateur modifié avec succès"
          })
          setTimeout(() => {
            window.location.reload();
          }, 1500)
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  return (

    <Dialog
      title={Boolean(current) ? "Modifier " : "Nouvel utilisateur"}
      confirmButtonTitle={Boolean(current) ? "Modifier" : "Confirmer"}
      open={open}
      handleConfirm={handleSave}
      handleClose={handleClose}
    >
      <TextFieldGroup>
        <TextField
          label="Nom"
          name="firstname"
          value={formValues.firstname}
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.firstname.length === 0}
          onChange={handleInputChange}
          required
          helperText={
            isFormSubmited && formValues.firstname.length === 0
              ? "Veuillez saisir le nom de l'utilisateur"
              : ""
          }
        />
      </TextFieldGroup>

      <TextFieldGroup>
        <TextField
          label="Prénom"
          name="lastname"
          value={formValues.lastname}
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.lastname.length === 0}
          onChange={handleInputChange}
          required
          helperText={
            isFormSubmited && formValues.lastname.length === 0
              ? "Veuillez saisir le prénom de l'utilisateur"
              : ""
          }
        />
      </TextFieldGroup>

      <TextFieldGroup>
        <TextField
          label="Email"
          name="email"
          value={formValues.email}
          margin="dense"
          disabled={current}
          type="email"
          fullWidth
          error={isFormSubmited && formValues.email.length === 0}
          onChange={handleInputChange}
          required
          helperText={
            isFormSubmited && formValues.email.length === 0
              ? "Veuillez saisir l'email de l'utilisateur"
              : ""
          }
        />
      </TextFieldGroup>

      <TextFieldGroup>
        <Autocomplete
          required
          label="Rôle"
          options={rolesDef}
          getOptionLabel={(option: any) => option}
          onChange={(event: any, newValue: any) => {
            setFormValues({
              ...formValues,
              role: newValue,
            });
          }}
          error={isFormSubmited && !Boolean(formValues.role)}
          helperText={isFormSubmited && !Boolean(formValues.role) && "Veuillez sélectionner le role de l'utilisateur"}
          value={formValues.role}
          getOptionSelected={(option: any, val: any) => option === val}
        />
      </TextFieldGroup>
    </Dialog>
  );
};


export default EditUser;