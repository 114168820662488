import * as React from "react";

import styled from "styled-components/macro";

import { Drawer, DrawerProps, Typography, IconButton, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { APP_COLORS } from "constants/styles";

import "styled-components/macro";

type NoNumbers<T> = T extends string ? never : T;

export const DrawerTitleContainer = styled("div")`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(2)}px;
`;

const DrawerTitle = styled(Typography)`
  font-weight: 400;
  color: ${APP_COLORS.textSecondary};
`;

export const DrawerContent = styled("div")`
  flex: 1 1 auto;
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(2)}px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export interface CustomDialogProps extends DrawerProps {
  title: string;
  isLoading?: boolean;
  customDialogTitle?: React.ReactNode;
  handleCloseDrawer(): void;
  subtitle?: string;
  hasStickyBars?: boolean;
}

export function CustomDrawer({
  title,
  customDialogTitle,
  isLoading = false,
  handleCloseDrawer,
  ...props
}: CustomDialogProps) {
  return (
    <Drawer anchor="right" onClose={handleCloseDrawer} {...props}>
      <DrawerTitleContainer>
        {customDialogTitle || (
          <>
            <DrawerTitle variant="h3">{title}</DrawerTitle>
            <IconButton
              size="small"
              onClick={handleCloseDrawer}
              css={`
                padding: 12px;
              `}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        )}
      </DrawerTitleContainer>

      <Divider />

      <DrawerContent>{!isLoading ? props.children : "loading"}</DrawerContent>
    </Drawer>
  );
}
