import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps as MuiDialogProps,
  Typography,
  ButtonProps,
} from "@material-ui/core";
import MuiDialog from "@material-ui/core/Dialog";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { SlideTransition } from "ui/Transition";

import "styled-components/macro";

import { APP_COLORS } from "constants/styles";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWidthMd: {
      width: 740,
    },
    paperWidthLg: {
      width: 940,
    },
    dialogSpacingContent: {
      textAlign: "center",
      padding: "28px",
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "#a8a4a4",
    },
    paperDialogRoot: {
      position: "relative",
      boxShadow: "0px 15px 20px 3px rgba(0, 0, 0, 0.1)",
    },
    dialogActions: {
      borderTop: "1px solid #d2cdcd",
    },
    dialogActionsContainer: {
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
    },
    dialogActionsRoot: {
      marginLeft: "auto",
      padding: 0,
    },
    dialogActionsConfirmClose: {
      position: "absolute",
      bottom: 0,
      justifyContent: "flex-end",
      backgroundColor: APP_COLORS.primary,
      pointerEvents: "auto",
      height: "16%",
      width: "100%",
      padding: "14px 28px",
      borderRadius: "4px",
      zIndex: theme.zIndex.modal + 1,
      boxSizing: "border-box",
      textTransform: "none",
    },
    primaryAction: {
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
  })
);

export interface ExtendedButtonProps extends ButtonProps {
  "data-testid"?: string;
}

export interface DialogProps extends MuiDialogProps {
  title?: string;
  subtitle?: string;
  message?: string;

  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  onClose?(): void;
  onConfirm?(): void;
  disableConfirmButton?: boolean;
  PrimaryButtonProps?: ExtendedButtonProps;
  SecondaryButtonProps?: ExtendedButtonProps;
  isFormDirty?: boolean;
  hasStickyBars?: boolean;
  height?: string;
}

export default function DeletionDialog<T extends object>({
  title,
  subtitle,
  message,
  confirmButtonTitle = "Confirm",
  cancelButtonTitle = "Cancel",
  disableConfirmButton = false,
  onConfirm,
  onClose,
  PrimaryButtonProps = {},
  SecondaryButtonProps = {},
  isFormDirty = false,
  children,
  hasStickyBars = false,
  maxWidth = "sm",
  height,
  ...props
}: DialogProps) {
  const classes = useStyles();
  const dialogRef = React.useRef(null);

  return (
    <MuiDialog
      fullWidth
      TransitionComponent={SlideTransition}
      onClose={onClose}
      aria-labelledby={title?.toLowerCase().split(" ").join("-")}
      {...props}
      classes={{
        paperWidthMd: classes.paperWidthMd,
        paperWidthLg: classes.paperWidthLg,
        paper: classes.paperDialogRoot,
      }}
    >
      <DialogTitle disableTypography>
        <Typography
          variant="h3"
          css={`
            font-weight: 500;
            color: ${APP_COLORS.textSecondary};
          `}
        >
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent ref={dialogRef} className={classes.dialogSpacingContent}>
        {children}
        <div>{message}</div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={onClose} {...SecondaryButtonProps}>
          Non
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={disableConfirmButton}
          onClick={onConfirm}
          className={classes.primaryAction}
        // {...PrimaryButtonProps}
        >
          OUI
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}
