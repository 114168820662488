import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import { StudentLifeArticle } from '__types__';
import { DataRowsColumns } from './data';
import "styled-components/macro";
import Paper from "ui/Paper";
import { InfoBarContext } from "contexts/InfoBarContext";
import Deletion from "pages/Deletion";
import ActionsGroup from "ui/ActionsGroup";
import axios from "axios";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const actions = [
  { key: "add", label: "Ajouter", abilities: [] },
  { key: "validate", label: "Valider", abilities: ["ADMIN", "MANAGER"] },
  { key: "desactivate", label: "Désactiver", abilities: ["ADMIN", "MANAGER"] },
];

export const Studies: React.FC<DefaultPageProps> = () => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [data, setData] = React.useState<StudentLifeArticle[]>([]);
  const [current, setCurrent] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);

  const [selectedRows, setSelectedRows] = React.useState([]);

  React.useEffect(() => {
    fetch('/api/v1/studies')
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
      .catch(console.log);
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const onAdd = () => {
    navigate(`${window.location.href}/ajouter`);
  }

  const validate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments à valider"
      })

    axios.put('/api/v1/studies/validate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Ecole(s) validé(s) avec succès!"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const desactivate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments à désactiver"
      })

    axios.put('/api/v1/studies/desactivate', {
      data: selectedRows
    })
      .then(res => {
        setInfo({
          type: "success",
          message: "Ecole(s) désactivé(s) avec succès!"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
  }

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "add":
        return onAdd();
      case "validate":
        return validate();
      case "desactivate":
        return desactivate();
    }
  };

  return (
    <>
      <PageHeader title={`Écoles (${data.length})`}>
        <ActionsGroup onClick={handleAction} actions={actions} />
      </PageHeader>

      <Paper>
        <Table
          hasSelection
          rowsIdentifier='id'
          actionAbilities={["ADMIN", "MANAGER"]}
          columns={DataRowsColumns}
          data={data}
          onRowClick={(row) => {
            navigate("/etudes/ecoles/" + row.id)
          }}
          onRowDelete={(row) => {
            setCurrent(row)
            setOpenDelete(true);
          }}
          onRowEdit={(row) => {
            navigate("/etudes/ecoles/editer", { state: { current: row } })
          }}
          onRowChecked={setSelectedRows}
        />
      </Paper>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/studies/"
      />
    </>
  );
};
export default Studies;