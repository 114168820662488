import React from "react";

import { Column } from "ui/Table";
import { Sheet, Sector } from '__types__';
import { formatDate } from "utils";

type S = {
  id: string;
  name: string;
  description?: string;
  presentation?: string;
  curriculum?: string;
  remuneration?: string;
  opportunities?: string;
  createdAt?: string;
  category?: any;
}

export const SectorDataRowsColumns: Column<any>[] = [
  {
    title: "Nom & Prénom",
    field: "name",
    render: ({ name }) => (
      <b
        css={`
          font-size: 13px;
        `}
      >
        {name}
      </b>
    ),
  },
  {
    title: "Email",
    field: "email",
  },
  {
    title: "Objet",
    field: "subject",
  },
  {
    title: "Date reception",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "LLL dd, yyyy · HH:mm")}
      </>
    ),
  },
]
export const SheetDataRowsColumns: Column<S>[] = [
  {
    title: "Nom",
    field: "name",
    render: ({ name }) => (
      <b
        css={`
          font-size: 13px;
        `}
      >
        {name}
      </b>
    ),
  },
  {
    title: "Categorie",
    field: "category",
    render: ({ category }) => (
      <>
        {category?.name}
      </>
    ),
  },
  {
    title: "Date creation",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "LLL dd, yyyy · HH:mm")}
      </>
    ),
  },
]