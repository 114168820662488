import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import { Button, Grid } from "@material-ui/core";
import { TextField, TextFieldGroup } from "ui/Form/TextField";
import axios from "axios";
import Paper from "ui/Paper";
import Can from "shared/@pbac/Can";
import { InfoBarContext } from "contexts/InfoBarContext";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const defaultValues = {
  email: "",
  phonenumber: "",
  address: "",
  about: "",
};

export const Informations: React.FC<DefaultPageProps> = () => {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const { setInfo } = React.useContext(InfoBarContext);

  React.useEffect(() => {
    axios.get('/api/v1/settings/informations/')
      .then(res => {
        if (res.status === 200 && res.data.length > 0)
          setFormValues(res.data[0])
      });
  }, []);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const update = () => {
    axios.put('/api/v1/settings/informations/',
      { ...formValues, }
    ).then(res => {
      setInfo({
        type: "success",
        message: "Informations modifiées avec succès"
      });
    })
  }

  return (
    <>
      <PageHeader title="Informations">
        <Can abilities={["ADMIN"]}>
          <Button
            variant="contained"
            color="primary"
            onClick={update}
          >
            Modifier
          </Button>
        </Can>

      </PageHeader>
      <Paper>

        <Grid container spacing={4} style={{ padding: "15px" }}>
          <Grid item xs={3}>
            <TextFieldGroup>
              <TextField
                label="Email"
                name="email"
                type="email"
                fullWidth
                value={formValues.email}
                onChange={handleInputChange}

              />
            </TextFieldGroup>

          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Téléphone"
              name="phonenumber"
              margin="dense"
              type="text"
              fullWidth
              value={formValues.phonenumber}
              onChange={handleInputChange}

            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Adresse"
              name="address"
              margin="dense"
              type="text"
              fullWidth
              value={formValues.address}
              onChange={handleInputChange}

            />
          </Grid>
          <Grid xs={12}>

          </Grid>
          <Grid item xs={6}>
            <textarea name="about" value={formValues.about} style={{ width: "100%" }} cols={30} rows={15}
              onChange={handleInputChange}

            >

            </textarea>
          </Grid>
        </Grid>
      </Paper>

    </>
  );
};
