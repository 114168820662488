import React from "react";
import { RouteComponentProps } from "@reach/router";

import {
  Grid,
  Box
} from "@material-ui/core";
import { PageHeader } from "ui/Header";

import Paper from "ui/Paper";
import "styled-components/macro";
import { useStyles } from "pages/Tutoring/CardInfo";
import Covers from "pages/Covers";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Sectors: React.FC<DefaultPageProps> = (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetch('/api/v1/sheets/sectors/' + props['id'])
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
      .catch(console.log)
  }, []);

  return (
    <>
      <PageHeader back title={`Secteurs`} />
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box p={2}>
              <div className={classes.titleInfo}>
                Détails
                  <hr className={classes.hr} />
              </div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box p={2}>
              {
                data?.cover_image &&
                <div className={classes.info}>
                  <img src={data?.cover_image} alt="" width="200" />
                </div>
              }
              <div className={classes.info}>
                <span className={classes.nameField}>Nom :</span>
                <span className={classes.contentField}>{data?.name}</span>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box p={2}>
              <Grid item xs={12}>
                <div className={classes.nameField}>
                  Présentation
                </div>
              </Grid>
              <div className={classes.info}>
                <div dangerouslySetInnerHTML={{ __html: data?.presentation }} />
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            {
              data &&
              <Covers expanded title="Banniere" page={`sheet_sector_${data?.id}`} />
            }
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Sectors;
