import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useTheme } from "@material-ui/core/styles";

import AppaBar from "ui/AppBar";

import { Menu, DRAWER_WIDTH } from "ui/Menu";

import { useCurrentRoute } from "constants/routes";

import "styled-components/macro";
// import { useDocumentTitle } from "utils/hooks";

export function MainLayout(props: any) {
  let [isDrawerOpen, setDrawerOpen] = React.useState(true);
  const [isMobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

  const theme = useTheme();
  const { currentRoute } = useCurrentRoute();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleLeftDrawerState = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const toggleMobileDrawerState = () => setMobileDrawerOpen(!isMobileDrawerOpen);

  let noPageTitle = false;

  // If the page has actions buttons then we need to hide the page title and only show inside our children components. We loop over all children that are nested normally inside the Router component from `Reach Router` hence  the `props.children.props.children`
  if (props.children?.props?.children) {
    React.Children.forEach(props.children.props.children, (element) => {
      let elementProps = element?.props as any;
      if (
        !React.isValidElement(element) ||
        elementProps.path !== currentRoute?.relativeUrl
      ) {
        return;
      }

      noPageTitle = elementProps.noPageTitle;
    });
  }

  let currentPageTitle = currentRoute?.label
    ? `${currentRoute?.label} — Paps Ops`
    : "Home — Paps Ops";

  // useDocumentTitle(currentPageTitle);

  return (
    <>
      <main
        css={`
          display: flex;
          color: var(--paps-internal-secondary-textColor);
          max-width: 100%;
          overflow: hidden;

          > div[role="group"] {
            flex-grow: 1;
          }
        `}
        data-cy={props.cypressPageName}
      >
        <AppaBar
          toggleDrawerOpen={
            isSmallScreen ? toggleMobileDrawerState : toggleLeftDrawerState
          }
        />
        <Menu
          isDrawerOpen={isDrawerOpen}
          isMobileDrawerOpen={isMobileDrawerOpen}
          toggleLeftDrawerState={toggleLeftDrawerState}
          toggleMobileDrawerState={toggleMobileDrawerState}
        />
        <div
          css={`
            padding-top: 64px;
            width: 100%;
            @media (min-width: 960px) {
              width: calc(100% - ${DRAWER_WIDTH}px);
            }
          `}
        >
          <div
            css={`
              padding-top: 24px;
              padding-bottom: 24px;
            `}
          >
            <Container maxWidth="lg">
              <LayoutHeader noPageTitle={noPageTitle} currentRoute={currentRoute} />
              {props.children}
            </Container>
          </div>
        </div>
      </main>
    </>
  );
}

function LayoutHeader(props: any) {
  const theme = useTheme();

  return (
    <Grid
      container
      css={`
        margin-bottom: ${!props.noPageTitle ? theme.spacing(3) : 0}px;
      `}
    >
      <Grid item xs={12}>
        <Typography variant="overline" display="block" gutterBottom color="primary" style={{ fontWeight: "bold" }}>
          {props.currentRoute?.fromGroup}
        </Typography>
      </Grid>
    </Grid>
  );
}
