import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import axios from "axios";
import Paper from "ui/Paper";
import Accordion from "@material-ui/core/Accordion";

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from "@material-ui/core";
import EditBlocTeacherHome from "./EditBloc/TeacherHome";
import EditBlocFamilyHome from "./EditBloc/FamilyHome";

import EditCoursePPage from "./EditBloc/CoursePPage";
import EditCourseStagePage from "./EditBloc/CourseStagePage";
import EditCourseOnlinePage from "./EditBloc/CourseOnlinePage";
import Covers from "pages/Covers";
import ArticleList from "./ArticleList";
import RefList from "./RefList";
import SkillsList from "./SkillsList";
import Professors from "./Professors";
import Page from "pages/Page";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      backgroundColor: "#f1f1f1",
      display: "block"
    }
  }),
);


export const Home: React.FC<DefaultPageProps> = () => {
  const classes = useStyles();
  const [openHomeTeacher, setOpenHomeTeacher] = React.useState(false);
  const [openHomeFamily, setOpenHomeFamily] = React.useState(false);

  const [openPageCourse1, setOpenPageCourse1] = React.useState(false);
  const [openPageCourse2, setOpenPageCourse2] = React.useState(false);
  const [openPageCourse3, setOpenPageCourse3] = React.useState(false);

  const [data1, setData1] = React.useState(null);
  const [data2, setData2] = React.useState(null);
  const [data8, setData8] = React.useState(null);
  const [data9, setData9] = React.useState(null);
  const [data10, setData10] = React.useState(null);

  React.useEffect(() => {
    axios('/api/v1/tutoring/page-text/1')
      .then(res => {
        setData1(res.data)
      })

    axios('/api/v1/tutoring/page-text/2')
      .then(res => {
        setData2(res.data)
      })

    axios('/api/v1/tutoring/page-text/8')
      .then(res => {
        setData8(res.data)
      })

    axios('/api/v1/tutoring/page-text/9')
      .then(res => {
        setData9(res.data)
      })

    axios('/api/v1/tutoring/page-text/10')
      .then(res => {
        setData10(res.data)
      })
  }, []);

  return (
    <>
      <PageHeader title="Présentation">
      </PageHeader>

      <Paper style={{ padding: "50px" }}>
        <Covers title="Banniere Page d'accueil" page="tutoring_home" />

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Bloc enseignant page d'accueil</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <div>
              <p>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenHomeTeacher(true)
                  }}
                >
                  Modifier
                </Button>
              </p>
            </div>

            <Typography variant="h3">
              {data1?.name}
            </Typography>
            <Typography>
              {data1?.description}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Bloc famille page d'accueil</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <div>
              <p>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenHomeFamily(true)
                  }}
                >
                  Modifier
                </Button>
              </p>
            </div>

            <Typography variant="h3">
              {data2?.name}
            </Typography>
            <Typography>
              {data2?.description}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Page title="Page d'inscription enseignant" page="teacher_signup" />
        <Page title="Page d'inscription famille" page="family_signup" />
        <Page title="Page faire une demande" page="request" />
        <Page title="Page enseignant" page="teacher_page" />
        <Page title="Page famille" page="family_page" />

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Section</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <ArticleList title="Articles" section="tutoring_section" />
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Référence</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <RefList title="Articles" section="tutoring_section" />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Nos professeurs </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <Professors />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Bloc à propos </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <div>
              <p>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setOpenPageCourse3(true)
                  }}
                >
                  Modifier
                </Button>
              </p>
            </div>
            <Typography variant="h3">
              {data10?.name}
            </Typography>
            <Typography variant="h5">
              {data10?.description}
            </Typography>
            <Typography>
              <div>
                <img src={data10?.cover_image} alt="" />
              </div>
              <div dangerouslySetInnerHTML={{ __html: data10?.presentation }}></div>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Compétence</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <SkillsList title="Articles" section="tutoring_section" />
          </AccordionDetails>
        </Accordion>

      </Paper>

      <EditBlocTeacherHome open={openHomeTeacher} onClose={() => setOpenHomeTeacher(false)} article={data1} />
      <EditBlocFamilyHome open={openHomeFamily} onClose={() => setOpenHomeFamily(false)} article={data2} />

      <EditCoursePPage open={openPageCourse1} onClose={() => setOpenPageCourse1(false)} article={data8} />
      <EditCourseStagePage open={openPageCourse2} onClose={() => setOpenPageCourse2(false)} article={data9} />
      <EditCourseOnlinePage open={openPageCourse3} onClose={() => setOpenPageCourse3(false)} article={data10} />
    </>
  );
};

export default Home;
