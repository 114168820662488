import { Grid } from "@material-ui/core";
import React from "react";
import Dialog from 'ui/Dialog';
import { TextField } from "ui/Form/TextField";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoBarContext } from "contexts/InfoBarContext";
import Autocomplete from "ui/Autocomplete";


export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  current?: any;
}

const schema = yup.object().shape({
  name: yup.string()
    .required("Veuillez saisir le niveau"),
});


export const EditFamily: React.FC<EditFamilyProps> = ({ open, onClose, current }) => {

  const { register, handleSubmit, errors, reset } = useForm({ resolver: yupResolver(schema) });


  const { setInfo } = React.useContext(InfoBarContext);

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = (data: any) => {

    if (current) {
      axios.put("/api/v1/tutoring/levels/" + current.id, {
        ...data,
      }).then(async res => {
        setInfo({
          type: "success",
          message: "Niveau modifier avec succès!"
        })
        reset();
        handleClose();
        window.location.reload();
      })
    } else {
      axios.post("/api/v1/tutoring/levels", {
        ...data,
      }).then(async res => {
        setInfo({
          type: "success",
          message: "Niveau ajouter avec succès!"
        })
        reset();
        handleClose();
        window.location.reload();
      })
    }
  };



  return (

    <Dialog
      title={Boolean(current) ? "Modifier " : "Ajouter"}
      confirmButtonTitle={Boolean(current) ? "Modifier " : "Ajouter"}
      open={open}
      handleConfirm={handleSubmit(onSubmit)}
      handleClose={handleClose}
    >
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <TextField
            required
            name="name"
            fullWidth
            label="Niveau"
            inputRef={register}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            defaultValue={current?.name}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default EditFamily;
