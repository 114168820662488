import React from "react";
import { RouteComponentProps } from "@reach/router";

import {
  DialogContent, Button,
} from "@material-ui/core";

import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from 'ui/Form/TextField';
import Paper from "ui/Paper";
import { Sheet } from '__types__';

import { DataRowsColumns } from './data';
import Can from "shared/@pbac/Can";
import { InfoBarContext } from "contexts/InfoBarContext";
import Deletion from "pages/Deletion";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const defaultValues = {
  name: ''
}
export const Cities: React.FC<DefaultPageProps> = () => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [sectors, setData] = React.useState<Sheet[]>([]);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);


  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );


  React.useEffect(() => {
    fetch('/api/v1/studies/cities')
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
      .catch(console.log)
  }, []);

  const handleClickOpen = () => {
    setCurrent(null)
    setFormValues(defaultValues)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSave = () => {
    setFormSubmited(true);

    if (formValues.name.length === 0) return;

    if (current) {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formValues)
      };

      fetch('/api/v1/studies/cities/' + current.id, requestOptions)
        .then(res => res.json())
        .then((data) => {
          setData(prev => {
            return prev.map(item => {
              if (item.id === data.id) {
                item = data
              }
              return item;
            })
          });
          setFormSubmited(false);
          setFormValues(defaultValues);
          setOpen(false);
          setInfo({
            type: "success",
            message: "Ville modifier avec succès"
          });
          setCurrent(null);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    } else {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formValues)
      };
      fetch('/api/v1/studies/cities', requestOptions)
        .then(res => res.json())
        .then((data) => {
          setFormSubmited(false);
          setFormValues(defaultValues);
          setOpen(false);
          setInfo({
            type: "success",
            message: "Ville ajouter avec succès"
          });
          setData([data, ...sectors]);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  };

  return (
    <>
      <PageHeader title={`Villes (${sectors.length})`}>
        <Can abilities={["ADMIN", "MANAGER"]}>
          <Button
            variant="contained"
            color="primary"
            css={`
              margin-right: 12px;
            `}
            onClick={handleClickOpen}
          >
            Ajouter
          </Button>
        </Can>

      </PageHeader>
      <Paper>
        <Table
          actionAbilities={["ADMIN", "MANAGER"]}
          rowsIdentifier='id'
          columns={DataRowsColumns}
          data={sectors}
          onRowDelete={(row) => {
            setCurrent(row)
            setOpenDelete(true);
          }}
          onRowEdit={(row) => {
            setCurrent(row);
            setFormValues({
              name: row.name,
            });
            setOpen(true);
          }}
        />
      </Paper>


      <Dialog
        title={Boolean(current) ? "Modifier " : "Ajouter"}
        confirmButtonTitle={Boolean(current) ? "Modifier" : "Ajouter"}
        open={open}
        handleConfirm={handleSave}
        handleClose={handleClose}>
        <DialogContent>
          <TextFieldGroup>
            <TextField
              label="Ville"
              name="name"
              value={formValues.name}
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              error={isFormSubmited && formValues.name.length === 0}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && formValues.name.length === 0
                  ? "Veuillez remplir le nom de la ville"
                  : ""
              }
              required

            />
          </TextFieldGroup>
        </DialogContent>
      </Dialog>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/studies/cities/"
      />
    </>
  );
};

export default Cities;
