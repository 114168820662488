import React from "react";
import { RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import axios from "axios";
import Paper from "ui/Paper";
import Accordion from "@material-ui/core/Accordion";

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from "@material-ui/core";
import EditPage from "pages/JobOffers/Edit/EditPage";
import Covers from "pages/Covers";
import Page from "pages/Page";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      backgroundColor: "#f1f1f1",
      display: "block"
    }
  }),
);


export const Home: React.FC<DefaultPageProps> = () => {
  return (
    <>
      <PageHeader title="Présentation">
      </PageHeader>

      <Paper style={{ padding: "50px" }}>
        <Covers title="Banniere" page="studies_home" />

        <Page title="Page d'accueil" page="studies_home" />
      </Paper>
    </>
  );
};

export default Home;
