import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { MainLayout } from "ui/Layout/Main";

import { Sheets } from "./Sheets";
import Sheet from "./Sheet";
import EditSheet from "./Edit/Sheet";
import { Sectors } from "./Sectors";
import Sector from "./Sector";
import Presentation from "./Presentation";

const SheetPage: React.FC<RouteComponentProps> = () => {

  return (
    <MainLayout>
      <Router>
        <Sheets path="/fiches-metiers" noPageTitle />
        <EditSheet path="/fiches-metiers/ajouter" />
        <EditSheet path="/fiches-metiers/editer/:id" />
        <Sheet path="/fiches-metiers/:id" noPageTitle />
        <Sectors path="/secteurs" noPageTitle />
        <Sector path="/secteurs/:id" noPageTitle />
        <Presentation path="/presentation" noPageTitle />
      </Router>
    </MainLayout>
  );
};

export default SheetPage;