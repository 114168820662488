import React from "react";

import { Column } from "ui/Table";
import { formatDate } from "utils";

const PAGE = {
  home: "Accueil",
  orientation_home: "Orientation",
  student_life_home: "Vie étudiante",
  studies_home: "Études",
  joboffer_home: "Stage & emploi",
  tutoring_home: "Soutien scolaire",
  sheet_home: "Fiches métiers"
}

export const AdsDataRowsColumns: Column<any>[] = [
  {
    title: "Page",
    field: "page",
    render: ({ page }) => (
      PAGE[page]
    )
  },
  {
    title: "Date de début",
    field: "ads_start__date",
    render: ({ ads_start_date, type }) => (
      <>
        {type === "ads" ? formatDate(ads_start_date, "dd/LL/yyyy") : "-"}
      </>
    ),
  },
  {
    title: "Date de fin",
    field: "ads_end_date",
    render: ({ ads_end_date, type }) => (
      <>
        {type === "ads" ? formatDate(ads_end_date, "dd/LL/yyyy") : "-"}
      </>
    ),
  },
  {
    title: "Ajouté le",
    field: "createdAt",
    render: ({ createdAt }) => (
      <>
        {formatDate(createdAt, "dd/LL/yyyy")}
      </>
    ),
  },
]