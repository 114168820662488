import { CircularProgress, InputLabel } from "@material-ui/core";
import React from "react";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from "ui/Form/TextField";
import { InfoBarContext } from "contexts/InfoBarContext";
import ImageUploader from 'react-images-upload';
import { useStyles } from "constants/styles";
import Autocomplete from "ui/Autocomplete";
import axios from "axios";
import { compressImage } from "utils";


export interface EditFamilyProps {
  open: boolean;
  onClose: () => void;

  current?: any;
  page: string;
}

const TYPE = {
  image: { value: "image", label: "Image" },
  ads: { value: "ads", label: "Publicité" }
}

const types = [
  {
    value: "image",
    label: "Image",
  },
  {
    value: "ads",
    label: "Publicité",
  },
]

interface IForm {
  name: string;
  action?: string;
  description?: string;
  link?: string;
  type: any;
  ads_end_date?: string;
  ads_start_date?: string;
}

const defaultValues: IForm = {
  name: '',
  action: '',
  description: '',
  link: '',
  type: { value: "image", label: "Image" },
  ads_end_date: null,
  ads_start_date: null,
}

export const EditFamily: React.FC<EditFamilyProps> = ({ open, onClose, current, page }) => {
  const classes = useStyles();

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [picture, setPicture] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (current) {
      setFormValues({
        name: current.name,
        action: current.action,
        link: current.link,
        description: current.description,
        ads_end_date: current.ads_end_date,
        ads_start_date: current.ads_start_date,
        type: TYPE[current.type]
      })
    }
  }, [current])

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  const { setInfo } = React.useContext(InfoBarContext);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleClose = () => {
    setFormValues(defaultValues);
    onClose();
  };

  const onSubmit = async () => {
    setFormSubmited(true);

    if (!Boolean(formValues.name.trim())
      || (formValues.type.value === "ads" && (!Boolean(formValues.ads_end_date) && !Boolean(formValues.ads_end_date)))) return;

    setLoading(true);

    const formData = new FormData();

    formData.append("data", JSON.stringify({ ...formValues, page, type: formValues.type?.value }));

    if (picture) {
      formData.append('cover_image', await compressImage(picture));
    }

    if (current) {
      axios.put('/api/v1/page-covers/' + current.id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          setFormSubmited(false);
          onClose();
          setInfo({
            type: "success",
            message: "Banniere modifier avec succès"
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    } else {
      axios.post('/api/v1/page-covers',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(res => {
          setFormSubmited(false);
          onClose();
          setInfo({
            type: "success",
            message: "Banniere ajouter avec succès"
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  return (

    <Dialog
      title={Boolean(current) ? "Modifier " : "Ajouter"}
      confirmButtonTitle={loading ? <CircularProgress size={30} /> : (Boolean(current) ? "Modifier" : "Ajouter")}
      open={open}
      handleConfirm={onSubmit}
      handleClose={handleClose}
      disableConfirmButton={loading}
    >
      <TextFieldGroup>
        <TextField
          label="Titre"
          name="name"
          value={formValues.name}
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.name.length === 0}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && formValues.name.length === 0
              ? "Veuillez remplir le titre"
              : ""
          }
          required

        />
      </TextFieldGroup>


      <TextFieldGroup>
        <Autocomplete
          label="Type"
          options={types}
          getOptionLabel={(option: any) => option.label}
          onChange={(event: any, newValue: any) => {
            setFormValues({
              ...formValues,
              type: newValue,
            });
          }}
          value={formValues.type}
          getOptionSelected={(option: any, val: any) => option.value === val.value}
        />
      </TextFieldGroup>

      {
        formValues.type?.value === "ads" &&
        <>
          <TextFieldGroup>
            <TextField
              label="Date de debut"
              name="ads_start_date"
              value={formValues.ads_start_date}
              margin="dense"
              type="date"
              fullWidth
              error={isFormSubmited && !Boolean(formValues.ads_start_date)}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && !Boolean(formValues.ads_start_date)
                  ? "Veuillez saisir la date de debut"
                  : ""
              }
              required

            />
          </TextFieldGroup>

          <TextFieldGroup>
            <TextField
              label="Date de fin"
              name="ads_end_date"
              value={formValues.ads_end_date}
              margin="dense"
              type="date"
              fullWidth
              error={isFormSubmited && !Boolean(formValues.ads_end_date)}
              onChange={handleInputChange}
              helperText={
                isFormSubmited && !Boolean(formValues.ads_end_date)
                  ? "Veuillez saisir la date de fin"
                  : ""
              }
              required

            />
          </TextFieldGroup>
        </>
      }


      <TextFieldGroup>
        <InputLabel
          className={classes.label}
        >
          Description
        </InputLabel>
        <br />
        <textarea name="description" value={formValues.description} style={{ width: "100%" }} cols={30} rows={5}
          onChange={handleInputChange}
        >
        </textarea>
      </TextFieldGroup>

      <ImageUploader
        singleImage
        withIcon={true}
        withPreview={true}
        buttonText='Image de couverture'
        onChange={onDrop}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
      />

      {
        !picture && current && current.cover_image &&
        < img width="100%" src={current.cover_image} alt="" />
      }
    </Dialog>
  );
};

export default EditFamily;
