import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useAuth } from "hooks/useAuth";
interface PrivateRouteProps extends RouteComponentProps {
  as: React.FC<any>;
  path: string;
  children?: React.ReactNode;
}
export function PrivateRoute(props: PrivateRouteProps) {
  let { as: Component, ...rest } = props;

  const [isAuth] = useAuth();

  return isAuth ? <Component {...rest} /> : <Redirect to="/connexion" />;
}
