import React from "react";
import {
  InputLabel,
} from "@material-ui/core";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from 'ui/Form/TextField';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useStyles } from 'constants/styles';
import { InfoBarContext } from "contexts/InfoBarContext";
import ImageUploader from 'react-images-upload';

const defaultValues = {
  name: '',
  description: '',
  presentation: ''
}
interface EditArticleProps {
  open: boolean;
  onClose: () => void;
  article?: any;
  page: string;

}
export const EditArticle: React.FC<EditArticleProps> = ({ open, onClose, article, page }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [coverImage, setCoverImage] = React.useState(null);

  const [picture, setPicture] = React.useState(null);

  const { setInfo } = React.useContext(InfoBarContext);


  React.useEffect(() => {
    if (article) {
      setFormValues({
        name: article.name,
        description: article.description,
        presentation: article.presentation,
      });
      setCoverImage(article?.cover_image)
    }
  }, [article]);

  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );

  const handleClose = () => {
    setCurrent(null);
    setFormValues(defaultValues);
    onClose();
  };

  const handleSave = () => {
    setFormSubmited(true);

    const formData = new FormData();
    formData.append('name', formValues.name);
    formData.append('description', formValues.description);
    formData.append('presentation', formValues.presentation);
    formData.append('cover_image', picture);
    formData.append('page', page);

    const requestOptions = {
      method: 'POST',
      body: formData
    };

    fetch('/api/v1/page-text', requestOptions)
      .then(res => res.json())
      .then((res) => {
        setFormSubmited(false);
        setFormValues(defaultValues);
        onClose();
        setInfo({
          type: "success",
          message: "Page modifier avec succès"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(error => {
      });
  };

  function onDrop(picture: any) {
    setPicture(picture[0]);
  }

  return (
    <Dialog
      title={"Editer la page "}
      confirmButtonTitle={"Modifier"}
      open={open}
      handleConfirm={handleSave}
      handleClose={handleClose}>
      <TextFieldGroup>
        <TextField
          label="Titre"
          name="name"
          value={formValues.name}
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.name.length === 0}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && formValues.name.length === 0
              ? "Veuillez saisir le titre"
              : ""
          }
          required

        />
      </TextFieldGroup>

      <TextFieldGroup>
        <InputLabel
          className={classes.label}
        >
          Description
        </InputLabel>
        <br />
        <textarea name="description" value={formValues.description} style={{ width: "100%" }} cols={30} rows={5}
          onChange={handleInputChange}
        >
        </textarea>
      </TextFieldGroup>

      <InputLabel
        className={classes.label}
      >
        Présentation
      </InputLabel>
      <br />
      <CKEditor
        data={formValues.presentation}
        editor={ClassicEditor}
        onChange={(event: any, editor: any) => {
          const data = editor.getData();
          setFormValues({
            ...formValues,
            presentation: data.trim()
          });
        }}
      />

      <ImageUploader
        singleImage
        withIcon={true}
        withPreview={true}
        buttonText='Image de couverture'
        onChange={onDrop}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
      />

      {
        !picture && coverImage &&
        <img width="100%" height="300" src={coverImage} alt="" />
      }
    </Dialog>
  );
};
export default EditArticle;