import React from "react";
import { useAuthToken } from "config/authProvider";
import jwtDecode from "jwt-decode";
import { User } from "__types__";

export const useCurrentUser = () => {
  const [authToken] = useAuthToken();
  const [user, setUser] = React.useState<User>();
  const decoded = jwtDecode(authToken);

  React.useEffect(() => {
    fetch(`/api/v1/users/${decoded["id"]}`)
      .then((res) => res.json())
      .then((data) => {
        setUser(data);
      });
  }, []);

  return [user];
};
