import React from "react";
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Can from "shared/@pbac/Can";
export interface Action {
  key: string;
  label: string;
  abilities?: Array<string>;
}

export interface ActionsGroupProps {
  actions: Array<Action>;
  onClick: (key: string) => void;
}

const ActionsGroup: React.FC<ActionsGroupProps> = ({
  actions,
  onClick,
}): React.ReactElement => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClick = (key: string) => {
    onClick(key);
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        style={{ marginLeft: "1.3rem" }}
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button style={{ width: "120px" }} onClick={handleToggle}>
          Action
        </Button>
        <Button
          style={{ width: "50px" }}
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {actions.map((action) => (
                    <Can abilities={action?.abilities} key={action.key}>
                      <MenuItem style={{ padding: 0 }}>
                        <Button
                          style={{ width: "200px" }}
                          onClick={() => handleClick(action.key)}
                        >
                          {action.label}
                        </Button>
                      </MenuItem>
                    </Can>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ActionsGroup;
