import React from "react";
import useLocation from "./useLocation";

export default function useCurrentRoute() {
  const { location } = useLocation();

  let currentRoute = React.useMemo(() => {
    return location.pathname;
  }, [location.pathname, location.state]);

  return { currentRoute };
}
