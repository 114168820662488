import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { MainLayout } from "ui/Layout/Main";
import Teachers from "./Teachers";
import Teacher from "./Teacher";
import Families from "./Families";
import Family from "./Family";
import Requests from "./Requests";
import Interventions from "./Interventions";
import Intervention from "./Intervention";
import Followups from "./Followups";
import Presentation from "./Presentation";
import { Articles } from "./Articles";
import EditArticle from "pages/EditArticle";

const StudiesPage: React.FC<RouteComponentProps> = () => {
  return (
    <MainLayout>
      <Router>
        <Teachers path="/enseignants" noPageTitle />
        <Teacher path="/enseignants/:code" noPageTitle />
        <Families path="/familles" noPageTitle />
        <Family path="/familles/:id" noPageTitle />
        <Requests path="/demandes" noPageTitle />
        <Requests path="/demandes" noPageTitle />
        <Interventions path="/interventions" noPageTitle />
        <Intervention path="/interventions/:id" noPageTitle />
        <Followups path="/suivis" noPageTitle />
        <Presentation path="/presentation" noPageTitle />
        <Articles path="/articles" noPageTitle />
        <EditArticle path="/articles/ajouter" section="tutoring" />
        <EditArticle path="/articles/editer" section="tutoring" />
        <EditArticle
          path="/presentation/section/ajouter"
          section="tutoring_section"
        />
        <EditArticle
          path="/presentation/section/editer"
          section="tutoring_section"
        />
      </Router>
    </MainLayout>
  );
};

export default StudiesPage;
