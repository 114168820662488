import React from "react";

import { Slide, Grow, GrowProps, SlideProps } from "@material-ui/core";

export const SlideTransition: any = React.forwardRef<unknown, SlideProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

export const GrowTransition: any = React.forwardRef<unknown, GrowProps>(
  function Transition(props, ref) {
    return (
      <Grow ref={ref} style={{ transformOrigin: "center" }} {...props} timeout={70} />
    );
  }
);
