import React from "react";
import { IconButton, TablePagination } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

export interface TableFooterProps {
  page?: number;
  rowsPerPage?: number;
  count?: number;
  handleChangePage?(event: unknown, newPage: number): void;
  handleChangeRowsPerPage?(event: React.ChangeEvent<HTMLInputElement>): void;
  rowsPerPageOptions: number[];
}

export const TableFooter = ({
  rowsPerPage,
  page = 1,
  count = 0,
  handleChangePage = (event) => {},
  handleChangeRowsPerPage = (event) => {},
  rowsPerPageOptions = [5, 10, 25],
}: TableFooterProps) => {
  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      labelDisplayedRows={({ from, to, count }) => (
        <>
          <span
            style={{
              width: "19px",
              height: "19px",
              border: "1px solid #dbdbdb",
              marginRight: "10px",
              textAlign: "center",
              display: "inline-block",
              borderRadius: "2px",
            }}
          >
            {Math.ceil(from / rowsPerPage)}
          </span>
          of
          <span style={{ marginLeft: "10px" }}>{Math.ceil(count / rowsPerPage)}</span>
        </>
      )}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage=""
    />
  );
};

function TablePaginationActions(props: any) {
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChangePage(event, page + 1);
  }

  return (
    <>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        <KeyboardArrowLeft style={{ color: "#000" }} />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        <KeyboardArrowRight style={{ color: "#000" }} />
      </IconButton>
    </>
  );
}
