import React from "react";
import { RouteComponentProps } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { ActivityDataRowsColumns } from "./data";
import { PageHeader } from "ui/Header";

import Table from "ui/Table";
import { Activity } from "__types__";
import Paper from "ui/Paper";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

export const Activities: React.FC<DefaultPageProps> = () => {
  const [activities, setActivities] = React.useState<Activity[]>([]);

  React.useEffect(() => {
    fetch("/api/v1/activities")
      .then((res) => res.json())
      .then((data) => {
        setActivities(data);
      })
      .catch(console.log);
  }, []);

  return (
    <>
      <PageHeader title="Historique">
        <></>
      </PageHeader>
      <Paper>

        <Table
          rowsIdentifier="id"
          columns={ActivityDataRowsColumns}
          data={activities}
          onRowClick={(event) => {
            return;
          }}
        />
      </Paper>
    </>
  );
};
