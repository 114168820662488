import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle, Color } from "@material-ui/lab";
import { SlideTransition } from "ui/Transition";
export interface InfoType {
  type: Color;
  message: string;
}
interface IProps {
  info: InfoType;
  onInfo: () => void;
}

function InfoBar({ info, onInfo }: IProps) {
  React.useEffect(() => {
    let timeoutInfo: any;

    if (info) {
      timeoutInfo = setTimeout(() => onInfo(), 2500);
    }

    return () => {
      clearTimeout(timeoutInfo);
    };
  }, [info, onInfo]);

  return (
    <div style={{ height: "auto" }}>
      {Boolean(info) && (
        <Snackbar open={Boolean(info)} TransitionComponent={SlideTransition}>
          <Alert
            variant="filled"
            severity={info.type}
            css={`
              font-size: 1.03rem;
            `}
          >
            <AlertTitle
              css={`
                font-size: 1.17rem;
              `}
            >
              {info.type.toUpperCase()}
            </AlertTitle>
            {info.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default InfoBar;
