import React from "react";
import {
  CircularProgress,
  FormControlLabel,
  InputLabel, Radio, RadioGroup,
} from "@material-ui/core";
import Dialog from 'ui/Dialog';
import { TextField, TextFieldGroup } from 'ui/Form/TextField';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import ImageUploader from 'react-images-upload';

import { useStyles } from 'constants/styles';
import { InfoBarContext } from "contexts/InfoBarContext";
import axios from "axios";
import { compressImage, formatDate } from "utils";
import "styled-components/macro";

const defaultValues = {
  name: "",
  value: ""
}

interface EditArticleProps {
  open: boolean;
  onClose: () => void;
  current?: any;

  list: Array<any>
}
const EditArticle: React.FC<EditArticleProps> = ({ open, onClose, current, list }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [picture, setPicture] = React.useState(null);

  const [categories, setCategories] = React.useState([]);

  const { setInfo } = React.useContext(InfoBarContext);


  const handleInputChange = React.useCallback(
    (event: any) => {
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value,
      });
    },
    [formValues]
  );


  React.useEffect(() => {
    if (current) {
      setFormValues({
        name: current.name,
        value: current.value,
      });
    } else {
      setFormValues(defaultValues);
    }
  }, [current]);

  const handleClose = () => {
    setFormValues(defaultValues);
    onClose();
  };

  const handleSave = async () => {
    setFormSubmited(true);
    if (formValues.name.trim().length === 0 || formValues.value.trim().length === 0) return;

    setLoading(true);

    if (current) {
      const i = list.findIndex(item => item.ref === current.ref);
      if (i !== -1)
        list[i] = { ...formValues, ref: current.ref }
      axios.put('/api/v1/tutoring/references',
        { list },
      )
        .then(res => {
          setFormSubmited(false);
          onClose();
          setInfo({
            type: "success",
            message: "Élément modifier avec succès"
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  return (
    <Dialog
      title={Boolean(current) ? "Modifier " : "Ajouter"}
      confirmButtonTitle={loading ? <CircularProgress size={30} /> : (Boolean(current) ? "Modifier" : "Ajouter")}
      open={open}
      handleConfirm={handleSave}
      handleClose={handleClose}
      disableConfirmButton={loading}
    >
      <TextFieldGroup>
        <TextField
          label="Référence"
          name="name"
          value={formValues.name}
          autoFocus
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.name.length === 0}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && formValues.name.length === 0
              ? "Veuillez saisir la référencce"
              : ""
          }
          required
        />

      </TextFieldGroup>

      <TextFieldGroup>
        <TextField
          label="Valeur"
          name="value"
          value={formValues.value}
          margin="dense"
          type="text"
          fullWidth
          error={isFormSubmited && formValues.value.length === 0}
          onChange={handleInputChange}
          helperText={
            isFormSubmited && formValues.value.length === 0
              ? "Veuillez saisir la valeur"
              : ""
          }
          required
        />
      </TextFieldGroup>
    </Dialog>
  );
};
export default EditArticle;