import React from "react";
import MuiTable from "@material-ui/core/Table";
import {
  TableBody,
  TableCell,
  TableRow as MuiTableRow,
  TableContainer,
  Card,
  CardHeader,
} from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { TableHeader } from "./Header";
import { TableLoader } from "./Loader";
import { TableFooter } from "./Footer";
import { TableRow } from "./Row";
import { getTableSorting, makeSearch, stablilizeTableSorting } from "utils";
import { EmptyTableContent } from "./styles";
import TableToolbar from "./Toolbar";

export interface Column<RowData> {
  title: React.ReactNode;
  field: string;
  render?(data: RowData): React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
    table: {
      minWidth: "auto",
    },
  })
);

type Order = "asc" | "desc";

export interface TableProps<RowData, RowDataKey extends keyof RowData> {
  actionAbilities?: Array<string>;
  actions?: any;
  filters?: any;
  onRowClick?(data: RowData): void;
  onRowDelete?(data: RowData): void;
  onRowEdit?(data: RowData): void;
  onRowChecked?(data: Array<string>): void;
  rowsIdentifier: RowDataKey;
  data: Array<RowData>;
  columns: Array<Column<RowData>>;
  isLoading?: boolean;
  hasSelection?: boolean;
  hasAllSelection?: boolean;
  orderByIdentifier?: RowDataKey;
  defaultSort?: Order;
  rowsPerPageOptions?: Array<number>;
  searchTerm?: string;
  columnsToSearchIn?: Array<string>;
  autoChecked?: boolean;
  isDisabledChecked?: boolean;
  selectedRows?: string[];
  disabled?: boolean;
  hasToolBar?: boolean;
}

export function Table<RowData, RowDataKey extends keyof RowData>({
  actionAbilities = [],
  columns = [],
  isLoading = false,
  hasSelection = false,
  hasAllSelection = true,
  onRowClick,
  rowsIdentifier,
  onRowDelete,
  onRowEdit,
  data = [],
  onRowChecked,
  orderByIdentifier = rowsIdentifier,
  rowsPerPageOptions,
  defaultSort = "desc",
  // searchTerm,
  columnsToSearchIn,
  autoChecked,
  selectedRows,
  isDisabledChecked,
  actions,
  filters,
  hasToolBar = true
}: TableProps<RowData, RowDataKey>) {
  const searchInputRef: React.MutableRefObject<HTMLInputElement> = React.useRef();

  const styles = useStyles();
  const [selected, setSelected] = React.useState<string[]>(
    selectedRows ? selectedRows : []
  );
  const [order, setOrder] = React.useState<Order>(defaultSort);
  const [orderBy, setOrderBy] = React.useState<keyof RowData>(orderByIdentifier);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState<string>(null);

  React.useEffect(() => {
    if (selectedRows) setSelected(selectedRows);
  }, [selectedRows]);

  const handlerSelectAll = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (hasAllSelection) {
        if (event.target.checked) {
          const newSelecteds = data.map(
            (currentCell: any) => currentCell[rowsIdentifier]
          );
          setSelected(newSelecteds);
          onRowChecked && onRowChecked(newSelecteds);
        } else {
          setSelected([]);
          onRowChecked && onRowChecked([]);
        }
      }
    },
    [data, onRowChecked, rowsIdentifier]
  );

  const handleSearchTerm = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setSearchTerm(event.target.value.toLowerCase()),
    []
  );

  const handleRowSelectClick = React.useCallback(
    (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selected.indexOf(id);

      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      onRowChecked && onRowChecked(newSelected);
      setSelected(newSelected);
    },
    [onRowChecked, selected]
  );

  const totalRowsSelectedCount = React.useMemo(() => selected.length, [selected]);
  const totalDataToShowCount = React.useMemo(() => data?.length || -1, [data]);
  const isRowSelected = React.useCallback(
    (name: any) => {
      return selected.includes(name);
    },

    [selected]
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RowData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let rows: any[] | null = null;
  rows = React.useMemo(() => data, [data]);

  const filterRows = React.useMemo(
    () => (searchTerm ? makeSearch(data, searchTerm, columnsToSearchIn) : []),
    [data, searchTerm]
  );

  const isUserFiltering = Boolean(searchTerm) && rows;

  let dataToShow = isUserFiltering ? filterRows : rows;

  const isDataToShowNotEmpty =
    dataToShow && Array.isArray(dataToShow) && dataToShow?.length !== 0;

  const sortedRows = React.useMemo(() => {
    let result =
      isDataToShowNotEmpty &&
      stablilizeTableSorting(dataToShow, getTableSorting(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

    return result;
  }, [dataToShow, order, orderBy, page, rowsPerPage, isDataToShowNotEmpty]);

  return (
    <Card style={{ minWidth: "100%" }} elevation={0}>
      {
        hasToolBar &&
        <CardHeader
          title={
            <TableToolbar
              filters={filters}
              actions={actions}
              searchInputRef={searchInputRef}
              handleSearchTerm={handleSearchTerm}
            />
          }
        />
      }
      <TableContainer
        className={styles.paper}
        css={`
        overflow-y: hidden;
      `}
      >
        <MuiTable size="medium" aria-label="customized table" className={styles.table}>
          <TableHeader
            columns={columns}
            onRequestSort={handleRequestSort}
            hasSelection={hasSelection}
            asAction={true}
            onAllRowChecked={handlerSelectAll}
            rowCount={totalDataToShowCount}
            selectedRowsCount={totalRowsSelectedCount}
            orderBy={orderBy}
            isDisabledChecked={isDisabledChecked}
            order={order}
          />

          {isLoading ? (
            <TableLoader rowDepth={rowsPerPage} cellDepth={columns.length + 1} />
          ) : (
            <TableBody>
              {isDataToShowNotEmpty &&
                sortedRows?.map((rowContent, index) => (
                  <TableRow
                    key={`${rowContent[rowsIdentifier]}${index}`}
                    actionAbilities={actionAbilities}
                    columns={columns}
                    rowContent={rowContent as any}
                    rowsIdentifier={rowsIdentifier}
                    hasSelection={hasSelection}
                    onRowEdit={onRowEdit}
                    onRowDelete={onRowDelete}
                    onRowClick={onRowClick}
                    onRowChecked={handleRowSelectClick}
                    selectRowsCount={totalRowsSelectedCount}
                    rowsCount={totalDataToShowCount}
                    isRowSelected={isRowSelected}
                    autoChecked={autoChecked}
                    isDisabledChecked={isDisabledChecked}
                  />
                ))}
              {!isDataToShowNotEmpty && searchTerm && (
                <MuiTableRow>
                  <TableCell colSpan={columns.length + 1}>
                    <EmptyTableContent>
                      Aucun resltat pour votre recherche{" "}
                      <i className="highlighted">{searchTerm}</i>
                    </EmptyTableContent>
                  </TableCell>
                </MuiTableRow>
              )}
            </TableBody>
          )}
        </MuiTable>
        {isDataToShowNotEmpty && dataToShow?.length > 10 && (
          <TableFooter
            rowsPerPageOptions={rowsPerPageOptions || [10]}
            handleChangePage={handleChangePage}
            count={data?.length}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        )}
      </TableContainer>
    </Card>

  );
}
